import { Component, OnInit,Input, Pipe } from '@angular/core';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { utilities } from 'src/app/utilities/utilities';
import { ClientRepository } from 'src/app/Repositories/client.repository';

@Component({
  selector: 'app-signature-tab',
  templateUrl: './signature-tab.component.html',
  styleUrls: ['./signature-tab.component.css']
})
export class SignatureTabComponent implements OnInit {
  @Input() userData;
  selectedTemplateName: string;
  selectedTemplatesArray = [];

  constructor(
    private _userRepository: UserRepository,
    private _clientRepository: ClientRepository,
    private util: utilities
  ) { }

  ngOnInit(): void {
  }

  selectSignature(index, sig) {
    this.selectedTemplatesArray[index] = sig.value;
  }

  setSignature(index) {
    // gets sent the sendas array index;
    let userObj = {
      id: this.userData.userData.primaryEmail,
      client: this._clientRepository.getCurrentClientName(),
      signature: this.selectedTemplatesArray[index],
      alias: this.userData.sendAs[index].sendAsEmail,
      user: this.userData.userData
    }

    console.log(userObj, index);
    this._userRepository.setSignature(userObj,index);
  }

  get currentClient() {
    return this._clientRepository.getCurrentClient();
  }

}
