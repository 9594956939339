import { Component,  OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-user-dropdown-searchable',
  templateUrl: './user-dropdown-searchable.component.component.html',
  styleUrls: ['./user-dropdown-searchable.component.component.css']
})
export class UserDropdownSearchableComponentComponent implements OnInit {
  @Input("item") item;
  @Output() afterChange = new EventEmitter();
  selected: string;
  localUserList:  any[];

  constructor(
    private util: utilities,
    private _googleUsersRepo: UserRepository,
  ) { }

  ngOnInit(): void {
    this.localUserList = this._googleUsersRepo.getGoogleNamesForSearchDropDown();
  }

  get usersList() {
    return this._googleUsersRepo.getGoogleNamesForSearchDropDown();
  }

  // triggered by a change to the model, output this change to the parent.
  onChange(ev) {
    console.log(ev);
    console.log("user " + ev.value.name + " chosen in user dropdown component");
    this.afterChange.emit(ev.value.email);

   }

}
