import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { jumpcloudRepository } from 'src/app/Repositories/jumpcloud.repository';
import { group } from 'src/app/models/group.model';

@Component({
  selector: 'app-jumpcloud-group-edit-page',
  templateUrl: './jumpcloud-group-edit-page.component.html',
  styleUrls: ['./jumpcloud-group-edit-page.component.css']
})
export class JumpcloudGroupEditPageComponent implements OnInit {

  groupID: string;
  selectedDirectory: string;
  groups;


  constructor(
    private _route: ActivatedRoute,
    private _jumpcloudRepository: jumpcloudRepository
  ) { }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.groupID = params["groupID"];
      this.groups = this._jumpcloudRepository.getGroups();
    });
  }

  get groupInfo() {
   return this._jumpcloudRepository.getGroupInfo(this.groupID);
    
  }

}
