<p-dialog header="Preferences" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}" [draggable]="false"
    [resizable]="false">

    <form>
        <div><h2>Weather Location</h2></div>
        <div class="form-group">
            <label for="postCode">PostCode if in UK</label>
            <input [(ngModel)]="loggedInUser.postcode" class="form-control" id="postCode" name="postCode"
                aria-describedby="postCode" placeholder="Enter UK Postcode">
        </div>
        <div class="form-group">
            <label for="cityName">City Name if outside UK</label>
            <input [(ngModel)]="loggedInUser.cityName" class="form-control" id="cityName" name="cityName"
                placeholder="City Name">
            <small id="passwordHelp" class="form-text text-muted">Please use your postcode if you are in the UK.</small>
        </div>



    </form>

    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-secondary" (click)="closeDisplay()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)='patchPreferences()'>Update</button>
    </ng-template>
</p-dialog>