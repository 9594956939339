<ul class="list-group smallText">
    <li *ngFor="let group of userData.groups.groups; let i = index" class="list-group-item smallText">
        <div class="row">
            <div class="col-sm-3">{{group.name}}</div>
            <div class="col-sm-4">{{group.email}}</div>
            <div class="col-sm-1"><button class="btn btn-danger btn-sm" (click)="deleteUserFromGroup(i)">Remove</button> </div>
            <div class="col-sm"></div>
        </div>
        
    </li>
</ul>