import { Component, OnInit } from '@angular/core';
import { utilities } from 'src/app/utilities/utilities';
import { adobeReopsitory } from 'src/app/Repositories/adobe.repository';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { FormBuilder, FormGroup, Validators,FormControl  } from '@angular/forms';


@Component({
  selector: 'app-adobe-reassign',
  templateUrl: './adobe-reassign.component.html',
  styleUrls: ['./adobe-reassign.component.css']
})
export class AdobeReassignComponent implements OnInit {
  _removeLicenseChecked;
  _assignLicenseChecked;
  private userToREmoveLicenseFrom: string;
  private userToAssignLicenseTo: string;
  private _listOfSelectedUsersLicenses = [];
  registerForm: FormGroup;
  submitted = false;
  isDisabled;
  removeLicenseUserSelector = "removeLicenseUserSelector";
  assignLicenseUserSelector = "assignLicenseUserSelector";

  constructor(
    private util: utilities,
    private _clientRepository: ClientRepository,
    private _repository: adobeReopsitory,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = new FormGroup({
      userLicenseSelector: new FormControl(''),
      assignLicenseSelector: new FormControl(''),
      removeLicenseUserSelector: new FormControl(''),
      assignLicenseUserSelector: new FormControl(''),
      removeLicense: new FormControl(''),
      asignLicense: new FormControl('')
    });
    
   }

  ngOnInit(): void {
    this.util.displayMessageToUser("Assign / Reassign Adobe Licenses");
    this._repository.getAdobeUsersFromServer(this._clientRepository.getCurrentClientName());
    
  }

  ngAfterViewInit(){
    document.getElementById("userLicenseSelector").setAttribute("disabled","disabled");
    document.getElementById("removeLicenseUserSelector").setAttribute("disabled","disabled");
    document.getElementById("assignLicenseSelector").setAttribute("disabled","disabled");
    document.getElementById("assignLicenseUserSelector").setAttribute("disabled","disabled"); 
  }

  get adobeUserList() {
    return this._repository.getAdobeUsers();
  }
  get adobeUsersWithLicensesList() {
    return this._repository.getAdobeUsersWithLicensesList();
  }

  getUsersCurrentAdodbeLicences($event) {
    this.util.debug("getUsersCurrentAdodbeLicences ( " + $event.value + ")");
    this.userToREmoveLicenseFrom = $event.value;
    // //need to populate the licenses the user has:
    this._listOfSelectedUsersLicenses =  this._repository.getAdobeUsersLicences(this.userToREmoveLicenseFrom );
    // console.log(this._listOfSelectedUsersLicenses);
  }

  setLicensetoRemove(selected) {
    this.util.debug(selected.value);
  }

  setUserToAssignTo($event) {
    this.userToAssignLicenseTo = $event.value;
  }

 

  process() {
    console.log("do it");
    if (this._removeLicenseChecked) {
      console.log("remove ");
      // setup remove parameters and call relevant function
    }
    if (this._assignLicenseChecked) {
      console.log("assign ");
      // setup assign parameters and call relevant function
    }
  }

  assignCheckboxClicked(e) {
    if (e.target.checked) {
      document.getElementById("assignLicenseSelector").removeAttribute("disabled");
      document.getElementById("assignLicenseUserSelector").removeAttribute("disabled");
    } else {
      document.getElementById("assignLicenseSelector").setAttribute("disabled","disabled");
      document.getElementById("assignLicenseUserSelector").setAttribute("disabled","disabled");
    }
  }


  removeCheckboxClicked(e) {
    if (e.target.checked) {
      document.getElementById("userLicenseSelector").removeAttribute("disabled");
      document.getElementById("removeLicenseUserSelector").removeAttribute("disabled");
    } else {
      document.getElementById("userLicenseSelector").setAttribute("disabled","disabled");
      document.getElementById("removeLicenseUserSelector").setAttribute("disabled","disabled");
    }
  }

  get listOfSelectedUsersLicenses() {
    return this._listOfSelectedUsersLicenses;
  }

  get adobelicenses() {
    return this._repository.getAdobeLicensesList();
  }

  get adobeProducts() {
    return this._repository.getAdobeProducts();
  }

  onSubmit(){
    console.warn(this.registerForm.value);
    console.log(this.userToAssignLicenseTo);
    console.log(this.userToREmoveLicenseFrom);

    if (this.registerForm.value.removeLicense) {
      // call the assign license function

      this._repository.postAdobeAction(
        this._clientRepository.getCurrentClientName(),
        this.userToREmoveLicenseFrom,
        this.registerForm.value.userLicenseSelector,
        "remove")
        
    }

    if (this.registerForm.value.asignLicense) {
      // call the remove license function
      this._repository.postAdobeAction(
        this._clientRepository.getCurrentClientName(),
        this.userToAssignLicenseTo,
        this.registerForm.value.assignLicenseSelector,
        "add")
    }
   
  }
}
