import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global_constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { utilities } from '../utilities/utilities';
import { ClientRepository } from '../Repositories/client.repository';
import { OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleUserService implements OnInit {
  private user$: BehaviorSubject<any> = new BehaviorSubject(null);
  currentClientData;
  currentUserData;


  constructor(
    private http: HttpClient,
    private util: utilities,
    private _clientRepository: ClientRepository,
    @Optional() @SkipSelf() sharedService?: GoogleUserService
  ) {

    if (sharedService) {
      throw new Error('UsersService is already loaded');
    }
    this.util.debug('UsersService created');

  }
  ngOnInit(): void {
  }

  // consumers subscribe to this to get the data for all of the clients.
  getGoogleUser(email) {
    this.getUser(email);
    return this.user$.asObservable();
  }


  //  var url = configConstants.apiBaseUrl + "/users?id=" + email + "&client="+noitmanage.clientName + "&domain=" + noitmanage.orginformation.primary_domain;

  getUser(_searchTerm: string) {
    this.util.debug("googleuser:getUser called");

    let clientData = this._clientRepository.getCurrentClient();
    if (this._clientRepository.getCurrentClientName != undefined && this._clientRepository.getCurrentClientName != null) {
      var url = GlobalConstants.apiURL + "/users?id=" + _searchTerm;
      url += "&client=" + clientData.client;
      url += "&domain=" + clientData.primary_domain;

      this.http.get(url
      ).subscribe(data => {
        this.user$.next(data);
      })
    }
  }

}




