import { Component, Input, OnInit } from '@angular/core';
import { utilities } from 'src/app/utilities/utilities';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { googleInfoRepository } from 'src/app/Repositories/googleInfo.repository';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { userAddress } from 'src/app/models/googleUser.model';


@Component({
  selector: 'app-groups-tab',
  templateUrl: './groups-tab.component.html',
  styleUrls: ['./groups-tab.component.css']
})
export class GroupsTabComponent implements OnInit {
  @Input() userData;
  selectedGroup;

  constructor(
    private _clientRepository: ClientRepository,
    private _googleInfo: googleInfoRepository,
    private _userRepository: UserRepository,
    private util: utilities) {}

  ngOnInit(): void {
    this._googleInfo.getFromServer(this._clientRepository.getCurrentClientName());

  }

  get groupsList() {
    return this._googleInfo.getGroups();
  }

  groupSelected($event) {
    this.util.debug( "group selected: "+ $event.value);
    this.selectedGroup = $event.value;
  }

  addUserToGroup() {
    this._userRepository.addUserToGoogleGroup(this.selectedGroup, this.userData.userData.primaryEmail);
  }

  removeUserFromgroup(groupIndex){
    this._userRepository.removeUserFromGoogleGroup(groupIndex);
  }

}
