import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { utilities } from 'src/app/utilities/utilities';
//import { GoogleUserService } from 'src/app/services/google-user.service';
import { UserPageUserComponent } from 'src/app/components/user-info-tabs/user-info-tab/user-info.tab';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { UserInfo } from 'src/app/models/fullUserInfo.model';


@Component({
  selector: 'app-user',
  templateUrl: './user-edit.page.html',
  styleUrls: ['./user-edit.page.css']
})
export class UserComponent implements OnInit {
  // subscription!: Subscription; //add an ! to tell the compiler we will definitly be assigning this beffore using it.
  // user_subscription!:  Subscription;
  primaryEmail: string = "";
  jumpCloudID: string = "";
  //userData;

  constructor(
    private _route: ActivatedRoute,
    private util: utilities,
    //private _googelUser: GoogleUserService
    private _userRespository: UserRepository,
    private _clientRetpository: ClientRepository

    ) { }

  ngOnInit(): void {
    // get the email address, and call then call the get the google user from the server function.
    // we will need the jumpcloud ID here, as that needs to be passed back to
    // the update user lambda function.
    this._route.params.subscribe(params => {
      this.primaryEmail = params["primaryEmail"];
      this._userRespository.getGoogleUserFromServer( this.primaryEmail, this._clientRetpository.getCurrentClient() );
    });
    this.util.displayMessageToUser("");
  }

    get clientName () {
      return this._clientRetpository.getCurrentClientName();
    }

    get userData() {
      return this._userRespository.getGoogleUser();
    }

    get jumpCloudId() {
      if (this._clientRetpository.getUseJumpCloud()) {
        if( this._userRespository.getJumpCloudID(this.userData.userData.primaryEmail) ) {
          return this._userRespository.getJumpCloudID(this.userData.userData.primaryEmail); 
        } else {
          return "not found in jumpcloud!";
        }

      } else {
        return "not using it";
      }
    }
}



