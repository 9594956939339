import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
  selector: 'app-group-selector',
  templateUrl: './group-selector.component.html',
  styleUrls: ['./group-selector.component.css']
})
export class GroupSelectorComponent implements OnInit {
  @Input() groupsList;
  @Output() selectedGroup: EventEmitter<any> = new EventEmitter();
  

  constructor(
    
      ) {   }

  ngOnInit(): void {
  }

  emitGroup(t){
    this.selectedGroup.emit(t);
  }
}
