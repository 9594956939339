import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';


@Component({
  selector: 'app-auth-nav',
  templateUrl: './auth-nav.component.html',
  styleUrls: ['./auth-nav.component.css']
})
export class AuthNavComponent implements OnInit {
  displayPrefsModal: boolean;

  constructor(public auth: AuthService) {}
  userEmailFudgeLink: string;

  ngOnInit(): void {
  }

  preferences(email) {
    this.userEmailFudgeLink = email;
    this.displayPrefsModal = true;
  }

  resetPrefsModal(event) {
    this.displayPrefsModal = false;
  }


}
