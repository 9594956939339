<div class="spacer"></div>

<h2 class="blueText">Locations</h2>

<div class="newFIlterContainer">
    <section class="newFilterForm list-group">
        <div class="row form-group">
            <label for="userOrgName" class="col-sm-2 col-form-label  form-control-sm">Organisation / Department:
            </label>
            <div class="col-sm-3">
                <select id="userOrgName" class="form-select form-select-sm" [(ngModel)]="selectedOrganisation"
                    (change)="getLocations(($event).target)" name="userOrgName" aria-label="userOrgName">
                    
                    <option disabled selected value="">Select Organisation</option>
                    <option *ngFor="let org of currentClient.organisations | keyvalue" value='{{org.key}}'>{{org.key}}
                    </option>
                </select>
            </div>
            <div class="col-sm-7"></div>
        </div>

    </section>

</div>

<div class="newFIlterContainer">
    <section class="newFilterForm list-group">
        <div class="oc-formInputDiv">
            <label class="oc-label" for="locationName">Location Name: </label>
            <input class="oc-input" type='text' id='locationName' name="locationName"
                [(ngModel)]="currentLocationName">
        </div>
        <div class="oc-formInputDiv">
            <label class="oc-label" for="locationsourceIsStructured">Single Line address?: </label>
            <input class="oc-checkbox" type='checkbox' id='locationsourceIsStructured' name="locationsourceIsStructured"
                [(ngModel)]="currentLocation.sourceIsStructured">
        </div>

         <div  *ngIf="currentLocation.sourceIsStructured">   
        <div class="oc-formInputDiv">
            <label class="oc-label" for="locationformatted">Formatted Addres: </label>
            <input class="oc-input" type='text' id='locationformatted' name="locationformatted"
                [(ngModel)]="currentLocation.formatted">
        </div>
    </div>
    <div  *ngIf="!currentLocation.sourceIsStructured">
        <div class="oc-formInputDiv">
            <label class="oc-label" for="streetAddress">Street Address: </label>
            <input class="oc-input" type='text' id='streetAddress' name="streetAddress"
                [(ngModel)]="currentLocation.streetAddress">
        </div>
        <div class="oc-formInputDiv">
            <label class="oc-label" for="locationextendedAddress">Extended Address: </label>
            <input class="oc-input" type='text' id='locationextendedAddress' name="locationextendedAddress"
                [(ngModel)]="currentLocation.extendedAddress">
        </div>
        <div class="oc-formInputDiv">
            <label class="oc-label" for="locality">Locality: </label>
            <input class="oc-input" type='text' id='locality' name="locality"
                [(ngModel)]="currentLocation.locality">
        </div>
        <div class="oc-formInputDiv">
            <label class="oc-label" for="region">Region: </label>
            <input class="oc-input" type='text' id='region' name="region"
                [(ngModel)]="currentLocation.region">
        </div>
        <div class="oc-formInputDiv">
            <label class="oc-label" for="postalCode">Postcode: </label>
            <input class="oc-input" type='text' id='postalCode' name="postalCode"
                [(ngModel)]="currentLocation.postalCode">
        </div>
        <div class="oc-formInputDiv">
            <label class="oc-label" for="country">Country: </label>
            <input class="oc-input" type='text' id='country' name="country"
                [(ngModel)]="currentLocation.country">
        </div>

        </div>

        <div class="oc-formInputDiv">
            <label class="oc-label" for="locationtelephone">Telephone: </label>
            <input class="oc-input" type='text' id='locationtelephone' name="locationtelephone"
                [(ngModel)]="currentLocation.telephone">
        </div>
        <div class="oc-formInputDiv">
            <label class="oc-label" for="locationwebsite">Website: </label>
            <input class="oc-input" type='text' id='locationwebsite' name="locationwebsite"
                [(ngModel)]="currentLocation.website">
        </div>
    </section>
    <div class="buttonRow">
        <button class="button-blue" (click)="addLocation()"> {{addButtonText}} </button>
    </div>
</div>

<div class="container-fluid" *ngIf="locationsList else noforward">
    <div class="row  smallText">
        <p>Locations</p>
    </div>
    <ul class="list-group">
        
        <li class="list-group-item" *ngFor="let location of locationsList;  " >
            
            <div class="container">
            <div class="form-group  row">
                <div class="col-sm-4">
                    <input readonly value="{{location.name}}" name="locationName" class="form form-control form-control-sm addressReadOnlyInput"> 
                </div>

               <ul class="list-group">
                <li class="row">
                    <input readonly value="{{location.telephone}}" name="locationWebsite" class="form form-control form-control-sm addressReadOnlyInput"> 
                </li>
                    <li class="row">
                        <input readonly value="{{location.website}}" name="locationWebsite" class="form form-control form-control-sm addressReadOnlyInput"> 
                    </li>
               </ul>
             
                
                <div class="col-sm-1">

                    <button class="btn btn-sm btn-danger" (click)="editLocation( location.name )">Edit</button>

                </div>

                <div class="col-sm-1">

                    <button class="btn btn-sm btn-danger" (click)="deleteLocation( location.name )">Delete</button>

                </div>
            </div>
        </div>
        </li>
    </ul>
</div>


<ng-template #noforward>
<div class="row  smallText">
    <p>No Locations exist for this Organisation.</p>
</div>
</ng-template>