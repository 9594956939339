<div class="spacer"></div>

<div *ngIf="userData.userData; else loading">
    <div class="container-fluid">
        <form>
            <div class="form-group  row mb-1">
                <label for="enableAutoReply" class="col-sm-2 col-form-label  form-control-sm">Enable Vacation</label>
                <div class="col-sm-10">
                    <p-inputSwitch id="enableAutoReply" name="enableAutoReply"
                    class="p-inputswitch-sm"
                    [(ngModel)]="userData.vacation.enableAutoReply"></p-inputSwitch>
                </div>
             
            </div>
            <div class="form-group  row mb-1">
                <label for="responseSubject" class="col-sm-2 col-form-label  form-control-sm">Subject: </label>
                <div class="col-sm-10">
                    <input [(ngModel)]="userData.vacation.responseSubject"
                    class="form form-control form-control-sm" type='text' id='responseSubject' name="responseSubject"
                    >
                </div>
            </div>
            <div class="form-group  row mb-1">
                <label for="restrictToContacts" class="col-sm-2 col-form-label  form-control-sm">Restrict to Contacts</label>
                <div class="col-sm-10">
                    <p-inputSwitch id="restrictToContacts" name="restrictToContacts"
                    [(ngModel)]="userData.vacation.restrictToContacts"></p-inputSwitch>
                </div>
            </div>
            <div class="form-group  row mb-1">
                <label for="restrictToDomain" class="col-sm-2 col-form-label  form-control-sm">Restrict to Domain</label>
                <div class="col-sm-10">
                    <p-inputSwitch id="restrictToDomain" name="restrictToDomain"
                    [(ngModel)]="userData.vacation.restrictToDomain"></p-inputSwitch>
                </div>
            </div>
    
            <div class="row mb-1">
                <label for="startTime" class="col-sm-2 col-form-label form-control-sm">Start Date</label>
                <div class="col-sm-4">
                    <input id="startTime" name="startTime" class="form-control" type="date" class="form-control form-control-sm" 
                    [ngModel]="userData.vacation.startTime | convertUnixToDate" (change)="setStartTime($event)"
                    />
                </div>
                <label for="endTime" class="col-sm-2 col-form-label  form-control-sm">End Date</label>
                <div class="col-sm-4">
                    <input id="endTime" name="endTime" class="form-control" type="date" class="form-control form-control-sm" 
                    [ngModel]="userData.vacation.endTime | convertUnixToDate" (change)="setEndTime($event)"/>
                </div>
            </div>
            <div class="form-group row mb-1">
                <label for="vacationMessage" class="col-sm-2 col-form-label  form-control-sm">Vacation Message</label>
            </div><div class="form-group row mb-1">
                <div class="col-sm ">
                    <p-editor [(ngModel)]="userData.vacation.responseBodyHtml" 
                    [style]="{'height':'250px'}" id="vacationMessage" name="vacationMessage"></p-editor>
                </div>
            </div>
            <div class="form-group  row mb-1">
                <div class="col-sm-1"> 
                    <button class="btn btn-sm  btn-danger" [routerLink]="'/usersList'">Cancel</button>
                    </div>
                <div class="col-sm-1"> 
            <button class="btn btn-sm btn-success" (click)="updateVacationMessage()">Update</button>
            </div>
     
        </div>
       
        </form>
</div>
</div>



<!-- display loading  -->
<ng-template #loading let-user>Getting Info... </ng-template>
