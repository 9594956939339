import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-directory-selector',
  templateUrl: './directory-selector.component.html',
  styleUrls: ['./directory-selector.component.css']
})



export class DirectorySelectorComponent implements OnInit {

  @Output() selectedDirectory = new EventEmitter<string>();

  constructor(
    private _clientRepository: ClientRepository,
    private util: utilities
    ) { }
  

  ngOnInit(): void {
  }

  get useJumpCloud() {
    return this._clientRepository.getUseJumpCloud();
  }

  onDirectoryChange(value: string){
    this.util.debug("directory changed to: " + value   )
    this.selectedDirectory.emit(value);
  }
}
