<div class="spacer"></div>
<div *ngIf="userData.userData; else loading">
    <div *ngIf="userData.labels.length == 1 && userData.labels[0].name=='Insufficient Permission'; else displayTab">
        <p class="text-danger">Service account does not have the right scopes to acccess labels<br>
        Please add the following scope in Domain Wide Delegation</p>
        <p>https://www.googleapis.com/auth/gmail.labels</p>
    </div>
    <ng-template #displayTab>
        <div class="container-fluid">
            <div class="form-group row">
                <div class="col-sm-5 smallText">
                    <p>Add a new mail label for {{ userData.userData.name.fullName }} </p>
                </div>

            </div>
            <ul class="list-group">
                <li class="list-group-item">
                    <div class="form-group  row mb-1">
                        <label for="newLabelName" class="col-sm-3 col-form-label  form-control-sm">Label Name ( incluide
                            /
                            for sub labels): </label>
                        <div class="col-sm-5">

                            <input [(ngModel)]="newLabelName" class="form form-control form-control-sm" type='text'
                                id='newLabelName' name="newLabelName">
                        </div>

                        <div class="col-sm-1">
                            <button class="btn btn-success btn-sm" (click)="addNewLabel()">{{addLabelButtonText}}</button>
                        </div>
                        <ng-container *ngIf="editMode">
                        <div class="col-sm-1">
                            <button class="btn btn-danger btn-sm" (click)="deleteLabel()" id="deleteMailLableButton">Delete</button>
                        </div>
                        <div class="col-sm-1">
                            <button class="btn btn-primary btn-sm" (click)="cancelEdit()"  id="cancelEditMailLableButton">Cancel</button>
                        </div>
                    </ng-container>
                    </div>
                </li>
            </ul>
        </div>


<div classs="spacer"></div>
<p-divider></p-divider>
<div class="container-fluid">
    <div class="row  smallText">
        <p>{{userData.userData.name.fullName}} has the following labels</p>
    </div>

    <p-tree [value]="treeMap" selectionMode="single" [(selection)]="selectedLabel" (onNodeSelect)="nodeSelect($event)"
        scrollHeight="600px" [style]="{ 'font-size': '14px'}">
        <ng-template let-node pTemplate="default">
            <div class="labelRow"> 
                <div class="lableName">{{node.label}} </div> 
            
            
        </div>
        </ng-template></p-tree>


    
</div>
</ng-template>
</div>

<!-- <p-tree [value]="files" selectionMode="single" [(selection)]="selectedFiles" (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)"></p-tree> -->

<!-- <pre>{{treeMap | json}}</pre> -->

<ng-template #loading let-user>Getting Info... </ng-template>