<div class="container"  *ngIf="loading">
  <app-my-loader></app-my-loader>
</div>


<div id="app" class="d-flex flex-column h-100">
  <div class="container" *ngIf="auth.isLoading$ | async; else loaded">
    <app-my-loader></app-my-loader>
  </div>
  
  <ng-template #loaded>
    <app-navbar></app-navbar>

    <div class="container flex-grow-1" *ngIf="auth.isAuthenticated$ | async">
      <div class="mt-5">
       
        <router-outlet></router-outlet>
        
       
      </div>
    </div>
    <div *ngIf="auth.isAuthenticated$ | async">
    <app-footer></app-footer>
  </div>
  </ng-template>
</div>