
 <label for="search-bar-label" class="">Directory: </label> 

    <select (change)="onDirectoryChange(selGroupDir.value)" #selGroupDir  class="directory-select" id='selGroupDir'>
        <option selected>Google</option>
        <option *ngIf="useJumpCloud">JumpCloud</option>
    </select>



   






