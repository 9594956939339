import { Component, OnInit } from '@angular/core';
import { utilities } from 'src/app/utilities/utilities';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { googleInfoRepository } from 'src/app/Repositories/googleInfo.repository';
import { adobeReopsitory } from 'src/app/Repositories/adobe.repository';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-client-switch',
  templateUrl: './nav-client-switch.component.html',
  styleUrls: ['./nav-client-switch.component.css']
})
export class NavClientSwitchComponent implements OnInit {

  constructor(
    private _clientRepository: ClientRepository,
    private _userRepository: UserRepository,
    //private _adobeRepository: adobeReopsitory,
    private _googleGroupsRepo: googleInfoRepository,
    private util: utilities,
    private _router: Router
     ) {}
   
clients;
currentClient;
private initialSelectMade: boolean = false;

  ngOnInit() {
    
  }

  get clientList() {
    return this._clientRepository.getClients();
  }
 
  onChange(value: string){
    this.util.debug("new client selected: " + value);
    // set the new selected client name in the client data repository.
    this._clientRepository.setCurrentClient(value); 
    this._userRepository.setUseJC(this._clientRepository.getUseJumpCloud());
    this._clientRepository.loadGoogleOrgUnits(value);
    this._googleGroupsRepo.setClient(this._clientRepository.getCurrentClient());
    //this._adobeRepository.setClientName(value);
    
    

    // change back to the home page:
    this._router.navigateByUrl("/");
  }
}
