import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global_constants';
import { Observable, BehaviorSubject, catchError, observable } from 'rxjs';
import { utilities } from '../utilities/utilities';
import { UserInfo } from '../models/fullUserInfo.model';





import { OnInit } from '@angular/core';
import { UserRepository } from '../Repositories/user.repository';
import { TitleStrategy } from '@angular/router';
import { Client } from '../models/client.model';
import { sendAsEmail } from '../Repositories/userSendAs.model';
import { userMailFilter } from '../models/user.mail.filter.model';
import { mailForwarderMap } from '../models/userForwarding.model';

@Injectable()
export class GoogleUserDataService {
    currentClientData;
    currentUserData;

    constructor(
        private http: HttpClient,
        private util: utilities,
   ) {
        this.util.debug('GoogleUserDataService created');
    }
    ngOnInit(): void {
    }

    // need to implement this.
    getUser(_searchTerm: string, client): Observable<UserInfo>{
        this.util.debug("GoogleUserDataService:getUser called");
        //let client = this._clientRepository.getCurrentClient();
        // if (client != undefined && client != null) {
            let url = GlobalConstants.apiURL + "/users?id=" + _searchTerm;
            url += "&client=" + client.client;
            url += "&domain=" + client.primary_domain;
            return this.http.get<UserInfo>(url);
          
    }

    getAllUsers(caller: string, clientName: string):Observable<any> {
        this.util.debug("GoogleUserDataService.getAllUsers called by " + caller + " client name: " + clientName)
        let url=GlobalConstants.apiURL + "/users/google?client="  
        + clientName
        + '&usejc=false&search=""&skip=""';
        url = this.util.addLoggedInUserToGetPath(url);
        return this.http.get<any>(url);
    }


  patchUser(userObject, client, useJC) {
    this.util.debug( "patch user " + userObject.primaryEmail);
    var body = {
      'id': userObject.primaryEmail,
      "user": userObject,
      "client": client,
      "usejc": useJC,
      "bulkAction": false
    };
    let url = GlobalConstants.apiURL + "users";
    return this.http.patch<any>(url,body, {responseType: 'json'});
  }

  addAlias(emailAddress: string, alias: string, client: string) {
    let url = GlobalConstants.apiURL + "/users/aliases";
    let body ={
      client: client,
      id: emailAddress,
      alias: alias
    }
    return this.http.post<any>(url,body, {responseType: 'json'});
  }

  deleteAlias(emailAddress: string, alias: string, client: string) {
    let url = GlobalConstants.apiURL + "/users/aliases";
    // we 'should' send the delete params in the URL as in a get request,
    // but the lambda function is set to look for a body, so we send a body
    // can change the lambda function later once the old version of ourcloud is retired.
    const body = {
      client: client,
      id: emailAddress,
      alias: alias
    }
    return this.http.delete<any>(url,  {responseType: 'json', body: body} );
  }

  addSendAs(emailAddress: string, sendAs: sendAsEmail, client: string) {
    let url = GlobalConstants.apiURL + "send-as";
    const body = {
      client: client,
      id: emailAddress,
      sig: sendAs
    }
    return this.http.post<any>(url,body, {responseType: 'json'});

  }

  deleteSendAs(emailAddress: string, sendAs: sendAsEmail, client: string) {
    let url = GlobalConstants.apiURL + "send-as";
    const body = {
      client: client,
      id: emailAddress,
      sig: sendAs
    }
    return this.http.delete<any>(url,  {responseType: 'json', body: body} );
  }

  resetUserPassword(email: string, password: string, client: string, resetInGoogle: boolean, resetInJC: boolean, jcid?: string) {
    let sendObj = {
      user: {
        email: email,
        jcid: "",
        password: password
      },
      client: client,
      usejc: false,
      useGoogle: true
    }

    if (resetInJC && jcid != "") {
      sendObj.usejc = true;
      sendObj.user.jcid = jcid
    }

    let url= GlobalConstants.apiURL + "users/reset-password";
    return this.http.post<any>(url,sendObj, {responseType: 'json'});
  }

  addUser(userObj) {
    let url= GlobalConstants.apiURL + "users";
    return this.http.post<any>(url,userObj, {responseType: 'json'});

  }

  addUserToGroup(sendObj) {
    let url= GlobalConstants.apiURL + "groups/members";
    return this.http.post<any>(url,sendObj, {responseType: 'json'});
  }

  removeUserFromGroup(sendObj) {
    let url= GlobalConstants.apiURL + "groups/members";
    return this.http.delete<any>(url, {responseType: 'json', body: sendObj});
  }

  addDelegate(sendObj) {
    let url= GlobalConstants.apiURL + "delegates";
    return this.http.post<any>(url,sendObj, {responseType: 'json'});
  }

  removeDelegate(userEmail, delegateEmail, client) {
    
    let url = GlobalConstants.apiURL + "delegates?id=" + userEmail 
      + "&client=" + client
      + "&delegateEmail="+delegateEmail;
 
    return this.http.delete<any>(url, {responseType: 'json'});
  }

    setSignature(sendObj) {
      let url= GlobalConstants.apiURL + "signatures";
      return this.http.patch<any>(url,sendObj, {responseType: 'json'});
    }

    patchVacation(sendObj) {
      let url= GlobalConstants.apiURL + "vacation";
      return this.http.patch<any>(url,sendObj, {responseType: 'json'});
    }

    addEmailForwarder(sendObj) {
      let url= GlobalConstants.apiURL + "users/forwarding";
      return this.http.put<any>(url,sendObj, {responseType: 'json'});
    }

    updateAutoForwarder(sendObj) {
      let url= GlobalConstants.apiURL + "users/autoforwarder";
      return this.http.put<any>(url,sendObj, {responseType: 'json'});
    }

    deleteEmailForwarder(sendObj) {
      console.log(sendObj);
      let url= GlobalConstants.apiURL + "users/forwarding";
      return this.http.delete<any>(url, {responseType: 'json', body: sendObj});
    }
  
    getGoogleGroupMembers(client,groupEmail) {
    let url= GlobalConstants.apiURL + "groups/members";
    url += "?client=" + client + "&groupEmail="+ groupEmail;
    return this.http.get<any>(url);
    }

    addMailFilter(mailFilter: userMailFilter, id, clientName) {
      // const returnData = new Observable((observer) => {
      //   const d = new Date();
      //   mailFilter.id = d.getTime().toString();
      //   observer.next(mailFilter);
      //   observer.complete();
      // });
      // return(returnData);
      let data = {
        id: id,
        client: clientName,
        data: mailFilter
      }


      let url= GlobalConstants.apiURL + "users/mailfilter";
       return this.http.post<any>(url,data, {responseType: 'json'});
    }

    patchMailFilter(mailFilter: userMailFilter, id, clientName) {
      // const returnData = new Observable((observer) => {
      //   observer.next(mailFilter);
      //   observer.complete();
      // });
      // return(returnData);
      let data = {
        id: id,
        client: clientName,
        data: mailFilter
      }

      let url= GlobalConstants.apiURL + "users/mailfilter";
      return this.http.patch<any>(url,data, {responseType: 'json'});
    }

    deleteMailFilter(mailFilter: userMailFilter, id, clientName) {
      // const returnData = new Observable((observer) => {
      //   observer.next(mailFilter);
      //   observer.complete();
      // });
      // return(returnData);
      let data = {
        id: id,
        client: clientName,
        data: mailFilter
      }

      let url= GlobalConstants.apiURL + "users/mailfilter";
      return this.http.delete<any>(url, {responseType: 'json', body: data});
    }

    addMaillabel(label, id, clientName) {
      // const returnData = new Observable((observer) => {
      //   const d = new Date();
      //   label.id = d.getTime().toString();
      //   observer.next(label);
      //   observer.complete();
      // });
      // return(returnData);
      let data = {
        id: id,
        client: clientName,
        data: label
      }

      let url= GlobalConstants.apiURL + "users/labels";
      return this.http.post<any>(url,data, {responseType: 'json'});
    }

    patchMailLabel(label, id, clientName) {
      let data = {
        id: id,
        client: clientName,
        data: label
      }
      let url= GlobalConstants.apiURL + "users/labels";
      return this.http.patch<any>(url,data, {responseType: 'json'});

    }

    deleteMailLabel(label, id, clientName) {
      let data = {
        id: id,
        client: clientName,
        data: label
      }
      let url= GlobalConstants.apiURL + "users/labels";
      return this.http.delete<any>(url, {responseType: 'json', body: data});
    }

    patchUserStatus(cl, user, useJC = false) {
      let data = {
          usejc : useJC,
          client :  cl,
          user : user
      }

      let url= GlobalConstants.apiURL + "users/status";
      return this.http.patch<any>(url,data, {responseType: 'json'});
  }
    
 }
  