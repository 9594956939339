import { Component, OnInit } from '@angular/core';
import { clientOrgLocation } from 'src/app/models/client.model';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {
  selectedOrganisation = "";
  currentLocation: clientOrgLocation = new clientOrgLocation();
  currentLocationName: string;
  addButtonText: string;
  locationsList = [];
  tLocation:clientOrgLocation = new clientOrgLocation();
  lLocation:clientOrgLocation = new clientOrgLocation();
  
  constructor(
    private _clientRepository: ClientRepository,
    private util: utilities
  ) { }

  ngOnInit(): void {
    this.currentLocation.sourceIsStructured = true;
    this.addButtonText = "Add Location";
  }

  get currentClient() {
    return this._clientRepository.getCurrentClient();
  }

  addLocation() {

  }

  getLocations(event) {
    this.locationsList = [];
    console.log(event.value);
    this.selectedOrganisation = event.value;
    console.log(this.currentClient.organisations[this.selectedOrganisation]);

    // we need to massage these into an array to make displayinng them easier.
    
    for (const [key, value] of Object.entries(this.currentClient.organisations[this.selectedOrganisation].locations)) {
      console.log(`Key: ${key}, Value: ${value}`);
      this.tLocation = new clientOrgLocation();
      this.tLocation.name = key;
      for (const [akey, avalue] of Object.entries(value)) {
        this.tLocation[akey] = avalue;
      }
      
      this.locationsList.push(this.tLocation);
  }
    console.log(this.locationsList);

   // this.locationsList = this.currentClient.organisations[this.selectedOrganisation].locations;
  }
 
  editLocation(location) {

  }

  deleteLocation(location) {

  }
}
