<p-dropdown 
    [options]="usersList"
    [(ngModel)]="selected"
    optionLabel="name"
    [filter]="true"
    filterBy="name" 
    [showClear]="true"
    (onChange)="onChange($event)"
    placeholder="Select a user"
   
    styleClass='dropdownClass'>

    >
        <ng-template pTemplate="selectedItem" let-selectedOption>
            <div class="flex align-items-center gap-2">
                <div>{{ selectedOption.name }}</div>
            </div>
        </ng-template>
        <ng-template let-user pTemplate="item">
            <div class="flex align-items-center gap-2">
               
                <div>{{ user.name }}  |  {{ user.email }}</div>
            </div>
        </ng-template>
</p-dropdown>