
    <search-dropdown 
      [items]="usersList" 
      [size]="'small'" 
      (ngModelChange)="onChange($event)"
      [(ngModel)]="item">
      
    </search-dropdown>

    
