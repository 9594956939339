import { Component, Inject, OnInit } from '@angular/core';
import { faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@auth0/auth0-angular';
import { ocUserRepository } from 'src/app/Repositories/ocUser.repository';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavBarComponent implements OnInit {
  gotUserProfile:boolean = false;


  constructor(
    public auth: AuthService,
    private userRepository: ocUserRepository
 
  ) {}

  ngOnInit() {
    // we subscribe to the user in the auth service here, so when the user has logged in it triggers
    // the get user function so we can get user prefs and admin status from dynamo db.
    this.auth.user$.subscribe(
      (profile) => {
        
        if ( profile && !this.gotUserProfile) {
          console.log(JSON.stringify(profile, null, 2));
          this.userRepository.getuser(profile.email);
          this.gotUserProfile = true;
        
        }
    });
  }

}