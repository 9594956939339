import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, map} from 'rxjs'
import { LoadingService } from '../services/loading.service';
import { utilities } from '../utilities/utilities';
import { GlobalConstants } from '../common/global_constants';


/**
 * This class is for intercepting http requests. When a request starts, we set the loadingSub property
 * in the LoadingService to true. Once the request completes and we have a response, set the loadingSub
 * property to false. If an error occurs while servicing the request, set the loadingSub property to false.
 * @class {HttpRequestInterceptor}
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private _loading: LoadingService,
    private util: utilities,
    
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._loading.setLoading(true, request.url);
    //console.log("interceptor: " + request.url);
    
  
    //console.log (request.headers)
    return next.handle(request)
      .pipe(
        catchError((error : HttpErrorResponse ) => {
        this._loading.setLoading(false, request.url);
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
          // client side error
          errorMessage = "Error: " + error.error.message;
        } else {
          // server side error
          errorMessage = "Error code: " + error.status + ",  Error Messaage: " + error.message;
        }
        this.util.displayError(error);

        return errorMessage;
      }))
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this._loading.setLoading(false, request.url);
        }
        return evt;
      }));
  }

 
}