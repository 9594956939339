import { Injectable } from '@angular/core';
import { GlobalConstants } from '../common/global_constants';

@Injectable({
  providedIn: 'root'
})

export class utilities {
  constructor() { }
  
  
  debug(message, level=0) {
    if (GlobalConstants.debugMode) {
      switch (level) {
        case 0:
          console.info(message);
          break;
        case 1:
          console.error(message);
          break;
      }
    }
  }

  logError(error) {
    console.error(error);
  }

  displayError(error) {
    this.logError(error);
    this.displayMessageToUser(error);
    this.copyToClipBoard(error);
  }

  checkPassword(str)
  {
    // at least one number, one lowercase and one uppercase letter
    // at least six characters
    
    var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    return re.test(str);
  }

  generatePassWord(plength){

    var keylistalpha="abcdefghijklmnopqrstuvwxyz";
    var keylistupper="ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var keylistint="123456789";
    //var keylistspec="!@";
    var temp='';
    var len = plength/2;
    var len = len - 1;
    var lenspec = plength-len-len;

    for (let i=0;i<len;i++)
        temp+=keylistalpha.charAt(Math.floor(Math.random()*keylistalpha.length));
        
    for (let i=0;i<len;i++)
        temp+=keylistupper.charAt(Math.floor(Math.random()*keylistalpha.length));

    //for (i=0;i<lenspec;i++)
    //    temp+=keylistspec.charAt(Math.floor(Math.random()*keylistspec.length));

    for (let i=0;i<len;i++)
        temp+=keylistint.charAt(Math.floor(Math.random()*keylistint.length));

        temp=temp.split('').sort(function(){return 0.5-Math.random()}).join('');

    return temp;
  }
 
  displayMessageToUser(message: string, type?: string, useTimeOut?: boolean) {
    // this will display a message in the footer... eventually!
    let timeout = 3000;
    let footer = document.getElementById('footerMessageSpan');
    footer.innerHTML = message;
    //if (footer.classList.contains("dangerText")) {footer.classList.remove("dangerText") }
    footer.setAttribute("class", "")
    switch (type) {
      case 'danger': 
          footer.classList.add("dangerText");
          timeout = 3000;
          break;
        case 'error': 
          footer.classList.add("dangerText");
          timeout = 5000;
          this.copyToClipBoard(message);
          footer.innerHTML = "COPIRD TO CLIPBOARD " + message;
          break;
        case "notimeout":
          footer.classList.add("normalText");
          timeout = 0;
          break;
      default:
        footer.classList.add("normalText");
    }
    footer.classList.add("dangerText")
    if (useTimeOut ) {
      setTimeout(this.clearFooter, timeout);
    }
  }

  clearFooter() {
    let footer = document.getElementById('footerMessageSpan');
    footer.innerHTML = "";
  }

  async copyToClipBoard( textToCopy ) {
    try {
      const copied = await navigator.clipboard.writeText(textToCopy );
    }
    catch(err) {
      this.debug("could not copy to the clipboard: " + textToCopy);
      this.displayMessageToUser("Could not copy the user data to the clipboard:" );
    }
  }

  convertTime12to24(time12h)  {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return `${hours}:${minutes}`;
  }

  convertMsToTime(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
  
    seconds = seconds % 60;
    minutes = minutes % 60;
  
    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    hours = hours % 24;
  
    return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}:${this.padTo2Digits(
      seconds,
    )}`;
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  hasOneHourPassed(date) {
    const HOUR = 1000 * 60 * 60;
    let oneHourAgo = Date.now() - HOUR;
    return date > oneHourAgo;
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

addLoggedInUserToGetPath(path: string) {
  return path + "&loggedinuser=David";
}

addLoggedInUserToPostBody(body) {

}

addLoggedInUserToPatchBody(body) {
  
}

}