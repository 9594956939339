import { Component, OnInit, Input } from '@angular/core';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { utilities } from 'src/app/utilities/utilities';
import { forwardwingAddress } from 'src/app/models/userForwarding.model';
import { TitleStrategy } from '@angular/router';

@Component({
  selector: 'app-forwarding-tab',
  templateUrl: './forwarding-tab.component.html',
  styleUrls: ['./forwarding-tab.component.css']
})
export class ForwardingTabComponent implements OnInit {
  @Input() userData;
  @Input() clientName;
  newForwrdingAddress: string;
  disposition: string;
  autoForwardEmail;

  constructor(
    private _userRepository: UserRepository,
    private util: utilities
  ) { }

  ngOnInit(): void {
  }

  updateAutoForwarder() {
    let sendObj = {
      id: this.userData.userData.primaryEmail,
      client: this.clientName,
      autofowardingObj: {
          enabled: true,
          emailAddress: this.autoForwardEmail,
          disposition: this.disposition
        }
    }
    this._userRepository.updateAutoForwarder(sendObj);
  }

  removeAutoForwarder() {
    let sendObj = {
      id: this.userData.userData.primaryEmail,
      client: this.clientName,
      autofowardingObj: {
          enabled: false
        }
    }
    this._userRepository.updateAutoForwarder(sendObj);
  }

  addForwardingAddress() {
    let sendObj = {
      id: this.userData.userData.primaryEmail,
      client: this.clientName,
      forwardingAddress: {
          forwardingEmail: this.newForwrdingAddress,
        }
    }
    this._userRepository.addEmailForwarder(sendObj);
  }

  

  deleteForwardAddress(index) {
    let sendObj = {
      id: this.userData.userData.primaryEmail,
      client: this.clientName,
      forwardTo: this.userData.forwarding.forwardingAddresses[index].forwardingEmail
    }
    this._userRepository.deleteEmailForwarder(sendObj,index);
  }

  get forwardingMap() {
    return this._userRepository.getMailForwardingMap();
  }

  getFilterInfo(id) {
    
  }
}
