<div class="spacer"></div>

<div *ngIf="clientName == null">
  No client selected!
</div>

<div class="container searchContainer" *ngIf='googleGroupsList != null'>
    <div class="row justify-content-md-center ">
      <div class="col" >
        <app-directory-selector 
        (selectedDirectory)="onDirectoryChange($event)">
        </app-directory-selector> 
      </div>


  <div class="spacer"></div>

<div *ngIf='googleGroupsList != null  '>
    <ul *ngIf='selectedDirectory === "Google"'>
        <app-groups-table [groupsList]="googleGroupsList" [clientName]="clientName" [selectedDirectory]="selectedDirectory"></app-groups-table>
    </ul>
</div>

<div *ngIf='jumpcloudGroupsList != null'> 
    <ul *ngIf='selectedDirectory === "JumpCloud"'>
        <app-groups-table [groupsList]="jumpcloudGroupsList" [clientName]="clientName" [selectedDirectory]="selectedDirectory"></app-groups-table>
        
    </ul>
</div>

</div>