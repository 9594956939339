import { User } from "./googleUser.model";
import { autoForwader, UserForwarding } from "./userForwarding.model";
import { UserGroups } from "./userGroups.model";
import { UserVacation } from "./userVacation.model";
import { sendAsEmail } from "../Repositories/userSendAs.model";
import { group } from "./group.model";
import { userMailFilter } from "./user.mail.filter.model";
import { filter } from "rxjs";
import { userMailLabel } from "./mailLabel.model";

export class UserInfo{
    userData: User;
    groups: groupsObject;
    delegates: {
        delegates: delegate[];
    };
    sendAs: sendAsEmail[];
    vacation: UserVacation;
    forwarding: UserForwarding;
    autoForwarding: autoForwader;
    filters: {
        filter: userMailFilter[];
    }
    labels: userMailLabel[];
    
}


export class groupsObject {
        kind: string;
        groups: group[]; 

}

export class delegate {
    delegateEmail: string;
    verificationStatus: string;
}

