import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { googleInfoRepository } from 'src/app/Repositories/googleInfo.repository';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { utilities } from 'src/app/utilities/utilities';
import { listUser } from 'src/app/models/googleUser.model';

@Component({
  selector: 'app-delegate-user',
  templateUrl: './delegate-user.component.html',
  styleUrls: ['./delegate-user.component.css']
})
export class DelegateUserComponent implements OnInit {
  @Input() displayModal: boolean;
  @Input() userData: listUser;
  @Input() seldirectoryName: string;
  @Output() resetDisplay: EventEmitter<any> = new EventEmitter();

  selDelegate;
  item;

  constructor(
    private _googleInfoRepository: googleInfoRepository,
    private _clientrepository: ClientRepository,
    private _userRepository: UserRepository,
    private util: utilities
  ) { }

  ngOnInit(): void {
    if(this.seldirectoryName == "Google") {  
      this._googleInfoRepository.getFromServer(this._clientrepository.getCurrentClientName());
    }
    console.log(this.userData);
  }
  
  get usersList() {
    return this._userRepository.getGoogleNamesForSearchDropDown();
  }

  addDelegate() {
    console.log("allow " + this.selDelegate + " to access "+ this.userData.primaryEmail);
    this._userRepository.addDelegate(this.userData.primaryEmail, this.selDelegate, true);
    this.closeDisplay();
  }

  delegateSelected(seleced) {
    // the dropdown returns the full name, so we conver to email.
    // this.selDelegate =this._userRepository.getEmailFromFullName(seleced);
    // this.util.debug(this.selDelegate);
    this.selDelegate = seleced;
  }

  onChange(ev){
    console.log("user " + ev + " chosen in user dropdown component");
  }

  closeDisplay() {
    // tells the parent to close this display ... very clever ;)
    this.resetDisplay.emit(false);
  }
}
