<form me-auto>
<app-auth-button *ngIf="(auth.isAuthenticated$ | async) === false"></app-auth-button>
<ul class="navbar-nav me-2 "*ngIf="(auth.isAuthenticated$ | async)"  >
<li class="nav-item dropdown me-auto " *ngIf="auth.user$ | async as user">
    
    <a class="nav-link dropdown-toggle hidden-arrow" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="{{user.picture}}" class="rounded-circle" height="25" alt="" referrerpolicy="no-referrer" />
        {{user.name}}
    </a>
    <ul class="dropdown-menu">
        <li>  <a class="dropdown-item" href="#" (click)="preferences(user['primaryEmail'])" >Preferences</a></li>  
        <li><app-logout-button></app-logout-button></li>    
    </ul>
</li>
</ul>
</form>

<app-user-preferences  [displayModal]='displayPrefsModal' [email]='userEmailFudgeLink' (resetDisplay)="resetPrefsModal($event)"></app-user-preferences>
