import { Input, Output, EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { GoogleUserDataService } from 'src/app/dataservices/google-user-dataservice';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { utilities } from 'src/app/utilities/utilities';
import Modal from "bootstrap/js/src/modal"

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  @Input() resetPasswordEmail: string;
  @Input() displayModal: boolean;
  @Output() resetDisplay: EventEmitter<any> = new EventEmitter();

  displayMe: boolean
  passwordResetModal: Modal;
  resetPasswordPassword: string;


  constructor(
    private util: utilities,
    private _googleDataService: GoogleUserDataService,
    private _userRepo: UserRepository
  ) { }

  ngOnInit(): void {
    // this.passwordResetModal = new Modal(document.getElementById("passwordResetModalCenterME"));
    this.resetPasswordPassword = this.util.generatePassWord(8);   
  }


  resetPassword() {
    // this calls the reset function.
    this.util.debug("reset password for " + this.resetPasswordEmail + " to " + this.resetPasswordPassword);
    let userInfoObj = this._userRepo.findUserInDirectories(this.resetPasswordEmail);
    // this.passwordResetModal.hide();
    this.closeDisplay();
     this._googleDataService.resetUserPassword(
      this.resetPasswordEmail,
      this.resetPasswordPassword,
      userInfoObj.client,
      userInfoObj.existsInGoogle,
      userInfoObj.existsInJC,
      userInfoObj.id
      ).subscribe( data => {
        this.util.debug("reset Password returned");
        this.util.debug(data);
        
        if(data.statusCode == 200) {
          this.util.displayMessageToUser( "Password reset for " + this.resetPasswordEmail,'',true);
          this.util.copyToClipBoard(this.resetPasswordPassword);
          
        }
      })
  } 

  closeDisplay() {
    // tells the parent to close this display ... very clever ;)
    this.resetDisplay.emit(false);
  }

}
