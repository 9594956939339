import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global_constants';
import { Observable, BehaviorSubject, catchError } from 'rxjs';
import { utilities } from '../utilities/utilities';
import { ocUser } from '../models/ocUser.model';


@Injectable()
 export class ocUserDataService {
 path = GlobalConstants.apiURL + "ourcloud/users";

    constructor(
        private http: HttpClient,
        private util: utilities
    ) {
    }

    getUser(email: string):Observable<ocUser> {
        return this.http.get<ocUser>(this.path + "?user=" + email);
    }

    crateUser(user: ocUser):Observable<ocUser> {
        return this.http.post<any>(this.path, user, {responseType: 'json'});
    }

    patchUser(userData: ocUser):Observable<any> {
        // put the user prefs object into the format the function expects.
        var sendData  = { user: userData};
        return this.http.patch<any>(this.path, sendData, {responseType: 'json'});
    }

}


