<div class="spacer"></div>

<div *ngIf="userData.userData; else loading">
    
<div class="container-fluid">
    
    <div class="form-group row">
        <div class="col-sm-5 smallText">
            <p>Add a forwarding address for  {{ userData.userData.name.fullName }} </p>
        </div>
        
    </div>
    <ul class="list-group">
        <li class="list-group-item">
    <div class="form-group  row mb-1" >
        <label for="forwardingEmail" class="col-sm-2 col-form-label  form-control-sm">Email Address: </label>
        <div class="col-sm-5">
            
            <input [(ngModel)]="newForwrdingAddress"
            class="form form-control form-control-sm" type='text' id='forwardingEmail' name="forwardingEmail"
            >
        </div>
    </div>
    
        <div class="col-sm">
        
        </div>
        <div class="col-sm-1">
        <button class="btn btn-primary btn-sm" (click)="addForwardingAddress()" >Add</button>
        </div>
        <div class="col-sm">
            
            </div>
        </li>
        </ul>
</div>


<div class="spacer"></div>

<div class="container-fluid" *ngIf="userData.forwarding.forwardingAddresses else noforward">
    <div class="row  smallText">
        <p>{{userData.userData.name.fullName}} has the following forwarding addresses</p>
    </div>
    <ul class="list-group">
        <li class="list-group-item" *ngFor="let address of userData.forwarding.forwardingAddresses; let i=index">
            <div class="form-group  row">
                <div class="col-sm-4">
                    <input readonly value="{{address.forwardingEmail}}"
                        class="form form-control form-control-sm"  >
                </div>
                <div class="col-sm-4">
                    Status: {{address.verificationStatus}}
                </div>

                <div class="col-sm-1">
                    
                        <button class="btn btn-sm btn-danger" (click)="deleteForwardAddress( i )">Delete</button>
                    
                </div>
            </div>
        </li>
    </ul>
</div>

<div class="spacer"></div>

<!-- ======================= -->
<div class="container-fluid">

    <div class="form-group row">
        <div class="col-sm-5 smallText">
            <p>Set an Auto Forwarder for  {{ userData.userData.name.fullName }} </p>
        </div>

    </div>
    <ul class="list-group">
        <li class="list-group-item">
            <div class="form-group row">
            <label for="autoForwardEmail" class="col-sm-2 col-form-label  form-control-sm">Email Address: </label>
            <div class="col-sm-3">
                
                <select id='autoForwardEmail' name="autoForwardEmail" [(ngModel)]="autoForwardEmail" 
            class="form form-select form-select-sm">
                <option *ngFor="let address of userData.forwarding.forwardingAddresses; let i=index">
                    {{address.forwardingEmail}}
                </option>
            </select>
            </div> 
            </div>
            <div class="form-group  row mb-1">
        <label for="disposition" class="col-sm-2 col-form-label  form-control-sm">Action?: </label>
        <div class="col-sm-3">
            <select id='disposition' name="disposition" [(ngModel)]="disposition" 
            class="form form-select form-select-sm">
                <option value="leaveInInbox">Leave in Inbox</option>
                <option value="archive">Archive</option>
                <option value="trash">Trash</option>
                <option value="markRead">Leave in Inbox and Mark as Read</option>
              </select>
           
        </div>
    </div>
    <div class="col-sm-1">
        <button class="btn btn-primary btn-sm" (click)="updateAutoForwarder()" >Set</button>
        </div>
        <div class="col-sm">
            
            </div>
            
            <ng-container *ngIf="userData.autoForwarding.enabled">
            

                <p>Current autoforward</p>
                
                <div class="form-group row" >
                    <div class="col-sm-3">{{userData.autoForwarding.emailAddress}}</div>
                    <div class="col-sm-3"> Action{{userData.autoForwarding.disposition}}</div>
                    <div class="col-sm-1"><button class="btn btn-primary btn-sm" (click)="removeAutoForwarder()" >Remove</button></div>
            </div>
        </ng-container>
        </li>
    </ul>

</div>
        
<!-- ======================= -->


<div class="spacer"></div>

<p-table #dt2 [value]="forwardingMap" rowGroupMode="subheader" 
groupRowsBy="emailAddress" sortField="emailAddress" sortMode="emailAddress"  
[tableStyle]="{'min-width': '75rem', 'font-size':'12px' } "
styleClass="p-datatable-sm p-datatable-striped" 
[scrollable]="true" scrollHeight="500px">


    <ng-template pTemplate="header">
        <tr>
            <th>Email</th>
            <th>Auto</th>
            <th>Filter</th>
            <th>Filter Name</th>
            <th>Filter Info</th>
           
            
        </tr>
    </ng-template>
    <ng-template pTemplate="groupheader" let-forwarder>
        <tr pRowGroupHeader>
        
        <td colspan="1">
                
                <span class="font-bold ml-2">{{forwarder.emailAddress}}</span>
            </td>
            <td>
                {{forwarder.autoFwd}}
            </td>
           <td>
            {{forwarder.usedByFilter}}
           </td>
           <td></td>
           <td></td>
           
            
           
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-forwarder>
        <ng-container *ngIf="forwarder.filters">
            <ng-container *ngFor="let tg of forwarder.filters">
                <tr class="row-highlight">
            
                    <td colspan="3">
                    </td>
                   
                    <td>
                        {{tg.queryName}}
                    </td>
                    <td>
                        <button class="btn btn-primary btn-sm" (click)="getFilterInfo(tg.id)" >Info</button>
                    </td>
                    <td colspan="1">
                    </td>
                </tr>
            </ng-container>
        </ng-container>
        
    </ng-template>
</p-table>


<ng-template #noforward>
    <div  class="row  smallText">
        <p>{{userData.userData.name.fullName}} does not have any forwarding addresses.</p>
    </div>
</ng-template>
</div>

<!-- display loading  -->
<ng-template #loading let-user>Getting Info... </ng-template>