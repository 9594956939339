<div class="spacer"></div>



<div class="container searchContainer">
    <div class="row ">
        <span class="section-header">Action</span>
    </div>
    <div class="localHeader">

        <div class="form-group row rowPad ">
            <div class='col-sm-2 smallText'>Please select action:</div>
            <div class="col-sm-4 smallText">
                <select id="bulkActionSelector" class="form-select form-select-sm smallText"
                    (change)="actionSelected(($event).target)" name="bulkActionSelector"
                    aria-label="bulkActionSelector">
                    <option value="address">Update Address</option>
                    <option value="signature">Update Signature</option>
                    <option value="removePermissions">Remove Fle Permissions</option>
                </select>
            </div>

        </div>

        <div class="form-group row  rowPad">
            <div class='col-sm-2 smallText'>Please select CSV file:</div>
            <div class="col-sm-4 smallText">
                <input type="file" #csvReader name="Upload CSV" id="txtFileUpload" (change)="uploadListener($event)"
                    accept=".csv" />

            </div>
            <div class="col-sm-6 smallText">
                <div *ngIf="selectedAction==0 || selectedAction==1 ">
                    ( CSV Format "name, email, Employee Type, Job Title, Organisation, Jumpcloud ID" )
                </div>

                <div *ngIf="selectedAction==2 ">
                    ( CSV Format "Google Audit Export" )
                </div>
            </div>
        </div>
    </div>

    <div class="row ">
        <span class="section-header">Options</span>
    </div>
    <div class="localHeader">

        <div *ngIf="selectedAction==0">

            <div class="form-group  row rowPad">
                <label for="newAddress" class="col-sm-2 col-form-label  form-control-sm">Address: </label>
                <div class="col-sm-10">

                    <input [(ngModel)]="newAddress" class="form form-control form-control-sm" type='text'
                        id='newAddress' name="newAddress">
                </div>
            </div>
            <div class="form-group  row rowPad">
                <label for="newTelephone" class="col-sm-2 col-form-label  form-control-sm">Telephone: </label>
                <div class="col-sm-10">

                    <input [(ngModel)]="newTelephone" class="form form-control form-control-sm" type='text'
                        id='newTelephone' name="newTelephone">
                </div>
            </div>
            <div class="form-group  row rowPad">
                <label for="newWebsite" class="col-sm-2 col-form-label  form-control-sm">Website: </label>
                <div class="col-sm-10">

                    <input [(ngModel)]="newWebsite" class="form form-control form-control-sm" type='text'
                        id='newWebsite' name="newWebsite">
                </div>
            </div>
        </div>


        <div *ngIf="selectedAction==1">
            <div class="form-group row rowPad">
                <div class='col-sm-3 smallText'>Signature Template to use:</div>
                <div class="col-sm-4">
                    <select id="signatureTemplateSelect" class="form-select form-select-sm smallText">
                        <option disabled selected value="">Select Template</option>
                        <option *ngFor="let org of currentClient.organisations | keyvalue" [value]='org.key'>{{org.key}}
                        </option>
                    </select>
                </div>

            </div>
        </div>


        <div *ngIf="selectedAction==2">
            <div class="form-group  row rowPad">
                <label for="permissionEmailAddress" class="col-sm-2 col-form-label  form-control-sm">Email Address to
                    remove: </label>
                <div class="col-sm-10">

                    <input [(ngModel)]="permissionEmailAddress" class="form form-control form-control-sm" type='text'
                        id='permissionEmailAddress' name="permissionEmailAddress">
                </div>
            </div>

        </div>

    </div>
    <div class="row rowpad">
        
        <div class="col-sm-2"><button class="btn btn-sm btn-primary " (click)="process()" >Process</button>
        </div>
        <div class="col-sm-10">
            x to proces: x processed , and list any errors
        </div>
    </div>

</div>


<div>
    <table class="minimalistBlack" *ngIf="records.length > 0 && selectedAction==2">
        <thead>
            <tr>
                <th>Document ID </th>
                <th>Title</th>
                <th>Processed</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let record of records; let i = index;">
                <td> <span>{{record.documentID}}</span> </td>
                <td> <span>{{record.title}}</span> </td>
                <td> 
                    <span *ngIf="record.processed"><img src="assets/checked.png"></span> 
                    <span *ngIf="!record.processed"><img src="assets/b_drop.png"></span> 
                </td>
            </tr>
        </tbody>
    </table>
</div>