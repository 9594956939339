export class groupMember {
    email: string;
    etag: string;
    id: string;
    kind: string;
    role: string;
    status: string;
    type: string;
    indirect: boolean;
    name: string;
    label: string;
    iconURL: string;
    children: groupMember[];
    icon: string;
}

export class groupMembers {
    etag: string;
    kind: string;
    members: groupMember[];
}

