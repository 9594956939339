import { Component, OnInit, Input } from '@angular/core';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { utilities } from 'src/app/utilities/utilities';


@Component({
  selector: 'app-vacation-tab',
  templateUrl: './vacation-tab.component.html',
  styleUrls: ['./vacation-tab.component.css']
})
export class VacationTabComponent implements OnInit {
  @Input() userData;

  constructor(
    private _userRepository: UserRepository,
    private _clientRepository: ClientRepository,
    private util: utilities
  ) { }

  ngOnInit(): void {
    
  }

  startDatePicked($event) {
    //convert to unix time;
  }

  endDatePicked($event) {
    //convert to unix time;
  }

  display() {
    console.log("Vacation info");
    console.log(this.userData.vacation);
  }

  setStartTime($event) {
    console.log($event.target.value);
    //convert to unix 
    this.userData.vacation.startTime = Date.parse($event.target.value).toString();
   
  }

  setEndTime($event) {
    console.log($event.target.value);
    //convert to unix 
    this.userData.vacation.endTime = Date.parse($event.target.value).toString();
   
  }

  updateVacationMessage() {
    let sendObj = {
      id: this.userData.userData.primaryEmail,
      client: this._clientRepository.getCurrentClientName(),
      vacationObj: this.userData.vacation
    }
    this.util.debug(sendObj);
    this._userRepository.patchVacation(sendObj);
  }

}
