import { OnInit, Injectable } from "@angular/core";
import { UserInfo } from "../models/fullUserInfo.model";
import { forwardwingAddress } from "../models/userForwarding.model";
import { UserVacation } from "../models/userVacation.model";
import { usersName } from "../models/googleUser.model";
import { User } from "../models/googleUser.model";
import { Observable, from } from "rxjs";

@Injectable()

export class staticUserData implements OnInit {
    private userData: UserInfo = new UserInfo();

    constructor() {

    }

    ngOnInit() {
        this.userData.userData = new User();
        this.userData.userData.name = new usersName();
        this.userData.userData.name.fullName = "Test User";
        this.userData.vacation = new UserVacation();
        this.userData.vacation.enableAutoReply = true;
        this.userData.vacation.startTime = "1671062400000";
        this.userData.vacation.endTime = "1671321600000";
        // make sure we have an entry in html?
        if (!('responseBodyHtml' in this.userData.vacation) || this.userData.vacation.responseBodyHtml == '') {
            this.userData.vacation.responseBodyHtml = this.userData.vacation.responseBodyPlainText;
        }
        this.userData.vacation.responseBodyHtml = '<div dir="ltr">Thanks for your email, unfortunately I am out of the office until the 28th August and will only be checking emails <b>periodically. </b><div><br></div><div>For anything urgent please email <a href="mailto:i@no-it.co.uk" target="_blank">i@no-it.co.uk</a> and one of my colleagues will get back to you.<br></div></div>';
        this.userData.vacation.responseSubject = "Away from the office";

        this.userData.forwarding = {
            forwardingAddresses: []
        }
        let fAddress = new forwardwingAddress;
        fAddress.forwardingEmail = "forward@banana.com";
        fAddress.verificationStatus = "acccepted";

        this.userData.forwarding.forwardingAddresses.push(fAddress);

        let bAddress = new forwardwingAddress;
        bAddress.forwardingEmail = "forward@apple.com";
        bAddress.verificationStatus = "acccepted";

        this.userData.forwarding.forwardingAddresses.push(bAddress);
    }

    getUserInfo(): UserInfo {
        return (this.userData);
    }

}