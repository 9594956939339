import { Component, OnInit, Input } from '@angular/core';
import { CustomFilter } from 'src/app/models/customFilter.model';
import { userMailFilter } from 'src/app/models/user.mail.filter.model';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { GoogleUserDataService } from 'src/app/dataservices/google-user-dataservice';
import { utilities } from 'src/app/utilities/utilities';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { filter } from 'rxjs';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit {
  @Input() userData;
  @Input() clientName;
  customFilter: CustomFilter;
  mailFilter: userMailFilter;
  editmode: boolean;
  buttonText: string;

  constructor(
    private _userRepository: UserRepository,
    private _googleUserDataService: GoogleUserDataService,
    private util: utilities
    ) { 
    this.customFilter = new CustomFilter();
  }

  ngOnInit(): void {
    this.editmode = false;
    this.buttonText = "Create Filter";
  }

  get filters() {
    if (this.userData.filters) {
      return this.userData.filters.filter;
    }
  }

  get labelsList() {
    return this._userRepository.getLabelsList(); 
  }

  get labelNameLookupMap() {
    return this._userRepository.getLabelNameLookupMap();
  }

  editFilter(filterObj) {
    /* put the info in the box at the top and change button to update
    mark an edit mode as update?
    */
    // makes usre the form is reset.
    this.clearFormObjects();

    

    console.log(filterObj)
    this.editmode = true; // must clear this to false after we have finished editing!
    this.customFilter.id = filterObj.id;

  
    
    if("from" in filterObj.criteria) {
      this.customFilter.from = filterObj.criteria.from;
    }
    if("to" in filterObj.criteria) {
      this.customFilter.to = filterObj.criteria.to;
    }
    if("query" in filterObj.criteria) {
      this.customFilter.query = filterObj.criteria.query;
    }
    if("negatedQuery" in filterObj.criteria) {
      this.customFilter.negatedQuery = filterObj.criteria.negatedQuery;
    }

    if("subject" in filterObj.criteria) {
      this.customFilter.subject = filterObj.criteria.subject;
    }

    if("forward" in filterObj.action) {
      this.customFilter.forward = filterObj.action.forward;
    }

    // need to get these form the remove lables array:
    if ("removeLabelIds" in filterObj.action) {
      if (filterObj.action.removeLabelIds.indexOf("SPAM") > -1) {
        this.customFilter.NeverSendToSpam = true;
      } 
      if (filterObj.action.removeLabelIds.indexOf("INBOX") > -1) {
        this.customFilter.SkipInbox = true;
      } 
    }

    // need to set the dropdown !
    if("addLabelIds" in filterObj.action) {
      this.customFilter.addLabelId = filterObj.action.addLabelIds;
    }
    
    this.buttonText = "Update Filter";
  }

  deleteFilter(filterObj) {
    this._googleUserDataService.deleteMailFilter(filterObj, this.userData.userData.primaryEmail, this.clientName).subscribe( data => {
      
      this.util.debug("userRepository: deleteMailFilter");
      this.util.debug(data);

      // check we have had a sucess response here, then remove the filter:
      if (data.statusCode == 200) {
        this.userData.filters.filter = this.userData.filters.filter.filter(function(el) { return el.id != filterObj.id; }); 
        this.util.displayMessageToUser("The Filter Has Been Deleted","danger");
      }
    })
  }

  get labels() {
    return this.userData.labels;
  }

  labelSelected(e) {
    console.log("You selected: " + e);
    this.customFilter.addLabelId = e;
  }

  addFilter() {
    // build a new mail filter object to pass to the store function.
    this.mailFilter = new userMailFilter() ;
    this.mailFilter.action = {};
    this.mailFilter.criteria = {};

    let removeLabelIds = [];
    
    if (this.customFilter.NeverSendToSpam ){
      removeLabelIds.push("SPAM");
    }
    if (this.customFilter.SkipInbox) {
      removeLabelIds.push("INBOX");
    }

    if (removeLabelIds.length > 0) {
      this.mailFilter.action.removeLabelIds = removeLabelIds;

    }

    if (this.customFilter.addLabelId != "" && this.customFilter.addLabelId != undefined) {
      this.mailFilter.action.addLabelIds = [];
      this.mailFilter.action.addLabelIds.push(this.customFilter.addLabelId);
    }

    if (this.customFilter.from != "" && this.customFilter.from != undefined) {
      this.mailFilter.criteria.from = this.customFilter.from;
    }

    if (this.customFilter.to != "" && this.customFilter.to != undefined) {
      this.mailFilter.criteria.to = this.customFilter.to;
    }

    if (this.customFilter.query != "" && this.customFilter.query != undefined) {
      this.mailFilter.criteria.query = this.customFilter.query;
    }

    if (this.customFilter.negatedQuery != "" && this.customFilter.negatedQuery != undefined) {
      this.mailFilter.criteria.negatedQuery = this.customFilter.negatedQuery;
    }

    if (this.customFilter.subject != "" && this.customFilter.subject != undefined) {
      this.mailFilter.criteria.subject = this.customFilter.subject;
    }

    if (this.customFilter.forward != "" && this.customFilter.forward != undefined) {
      this.mailFilter.action.forward = this.customFilter.forward;
    }  

    console.log(this.mailFilter);

    // are we in edit mode???
    if (this.editmode) {
      // If we are updating the filter, we actually need to delete it and then create a new one as Google doesn't have an update filter function.
      this.mailFilter.id = this.customFilter.id;
      this.deleteFilter(this.mailFilter);
    } 
      //add 
    this._googleUserDataService.addMailFilter(this.mailFilter, this.userData.userData.primaryEmail, this.clientName).subscribe(data => {
      this.util.debug("userRepository: addMailFilter");
      this.util.debug(data);
      // need a test in here to make sure it worked !
      if (data.statusCode == 200) {
        // add the new filter to the array so it displays.
        let newFilter = JSON.parse(data.body);
        this.userData.filters.filter.push(newFilter);

        //display a message to the user:
        this.util.displayMessageToUser("New filter added");

        // clear the form objects
        this.clearFormObjects();
      }
    })
  


  }

  clearFormObjects() {
    this.customFilter.id = "";
    this.customFilter.NeverSendToSpam = false;
    this.customFilter.SkipInbox = false;
    this.customFilter.addLabelId = "";
    this.customFilter.forward = "";
    this.customFilter.from = "";
    this.customFilter.to = "";
    this.customFilter.query = "";
    this.customFilter.negatedQuery = "";
    this.customFilter.subject = "";
    this.buttonText = "Create Filter";
  }

}
