import { Component, Input, OnInit } from '@angular/core';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { sendAsEmail } from 'src/app/Repositories/userSendAs.model';
import { utilities } from 'src/app/utilities/utilities';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { GoogleUserDataService } from 'src/app/dataservices/google-user-dataservice';

@Component({
  selector: 'aliases-tab',
  templateUrl: './aliases.tab.html',
  styleUrls: ['./aliases.tab.css']
})
export class AliasesComponent implements OnInit {
  @Input() userData;
  newAliases = [];
  newSendAsArray = [];


  constructor(
    private _userRespository: UserRepository,
    private _clientRepository: ClientRepository,
    private _userDataService: GoogleUserDataService,
    private util: utilities) {}


  ngOnInit(): void {
  }

  // get userData() {
  //   return this._userRespository.getGoogleUser();
  // }

  checkSendAs(emailAddress) {
    return this.userData.sendAs.find(sa => sa.sendAsEmail === emailAddress);
  }

  updateReplyTo(i) {
    console.log ("update reply to");
    this.newSendAsArray[i].replyToAddress = this.newSendAsArray[i].sendAsEmail;
  }

  addSendAs() {
    let ns = new sendAsEmail();
    ns.displayName = this.userData.userData.name.fullName;
    ns.treatAsAlias = true;
   
    this.newSendAsArray.push(ns);
  }

  deleteSendAs(i) {
    this.util.debug("delete sendas"+ this.userData.sendAs[i].sendAsEmail)
    
    // tell the server to delete... 
    this._userDataService.deleteSendAs(
      this.userData.userData.primaryEmail,
      this.userData.sendAs[i],
      this._clientRepository.getCurrentClientName()
      ).subscribe(data => {
        // do the save stuff... if all ok
        this.util.displayMessageToUser("Send As email address " + this.userData.sendAs[i].sendAsEmail+ " has been deleted");
        this.userData.sendAs.splice(i,1);

      })
  }

  saveSendAs(i) {
    // need some validation here...
    this.util.debug("Save send as ");
    this.util.debug(this.newSendAsArray[i]);

    this._userDataService.addSendAs(
      this.userData.userData.primaryEmail,
      this.newSendAsArray[i],
      this._clientRepository.getCurrentClientName()
      ).subscribe(data => {
        // do the save stuff... if all ok
      this.util.displayMessageToUser("Send as email address " + this.newSendAsArray[i].sendAsEmail + " has been saved");
      this.userData.sendAs.push(this.newSendAsArray[i]);
      this.newSendAsArray.splice(i,1);
      })
  }

  

  addAlias() {
    let newAlias = "";
    this.newAliases.push(newAlias);


  }

  saveAlias(i) {
    if( this.newAliases[i] != "") {
      // save it !
      this.util.debug("save Alias: " + this.newAliases[i]);
      // here we save it, and if successfull remove it from the newALiases array and add it to the userModel.
      
      this._userDataService.addAlias(this.userData.userData.primaryEmail, 
        this.newAliases[i], 
        this._clientRepository.getCurrentClientName()
        ).subscribe(
        data => {
           // .... data to save it
           this.util.displayMessageToUser("Alias " + this.newAliases[i] + " has been added");
          this.userData.userData.aliases.push(this.newAliases[i]);
          this.newAliases.splice(i,1);
        }
      )
     
    }    
   
  }

   
  deleteAlias(i) {
    this.util.debug("delete alias"+ this.userData.userData.aliases[i]);
    // go off and delete it,... when we get back we remove it:
    this._userDataService.deleteAlias( 
      this.userData.userData.primaryEmail,
      this.userData.userData.aliases[i],
      this._clientRepository.getCurrentClientName()
    ).subscribe ( data => { 
      // delete it from the model.
      this.util.displayMessageToUser("Alias " + this.userData.userData.aliases[i] + " has been deleted", "danger");
      this.userData.userData.aliases.splice(i,1);
    })
    
  }

  // this is used to solve the problem of the input losing focus when a key is pressed.
  // this happens when a dynamic input uses an array that is an array of primatives, ie strings, not objects.
  // https://stackoverflow.com/questions/42322968/angular2-dynamic-input-field-lose-focus-when-input-changes
  trackByFn(index: any, item: any) {
    return index;
 }
 
}
