<!-- Navbar -->
<nav class="navbar navbar-expand-lg bg-light fixed-top">
  <!-- Container wrapper -->
  <div class="container-fluid">
    <!-- Toggle button -->
    
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Navbar brand -->
      
    <a class="navbar-brand  mt-2 mt-lg-0" routerLink="">Our Cloud</a>
    
    <!-- Collapsible wrapper -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="auth.isAuthenticated$ | async">
      
      <!-- Left links -->
      <app-main-nav></app-main-nav>
      
      <!-- /Left links -->

      
      <div class="mx-auto order-0">
      <app-nav-client-switch></app-nav-client-switch>
    </div>
 
  </div>
    <!-- /Collapsible wrapper -->

       <!-- Right elements -->
       <app-auth-nav></app-auth-nav>     
   
       <!-- /Right elements -->
  

  </div>
  <!-- Container wrapper -->
</nav>
<!-- Navbar -->





