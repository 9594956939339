<div class="spacer"></div>

<div *ngIf="clientName == null">
  No client selected!
</div>

<div class="container searchContainer" *ngIf='newUsersList != null'>
    <div class="row justify-content-md-center ">
      <div class="col" >
        <app-directory-selector 
        
        (selectedDirectory)="onDirectoryChange($event)">
        </app-directory-selector> 
      </div>
  </div>


<div class="spacer"></div>

<div class="container">



  <p-contextMenu #cm [model]="menuitems"></p-contextMenu>

<div class="card" id="userTable">
  <p-table #dt1 [value]="newUsersList" [columns]="cols" 
  styleClass="p-datatable-sm p-datatable-striped" 
  [tableStyle]="{'min-width': '60rem', 'font-size':'12px'}"
  [globalFilterFields]="['fullName','primaryEmail.name','orgDescription','orgTitle', 'orgName', 'creationTime']" 
  [contextMenu]="cm"
  dataKey="user"
  [(contextMenuSelection)]="selectedUser"
  [scrollable]="true" scrollHeight="500px" 
  *ngIf="visible" > 
  <!-- *ngIf="visible" is a hack to enable redrawing the table after data has changed -->
    
  <ng-template pTemplate="caption">
    <div class="row">
      <div class="col-sm-8">
        <span class="p-input-icon-right">

          <input #searchInput pInputText type="text" (input)="dt1.filterGlobal(searchInput.value, 'contains')"
            placeholder="Search keyword" />
          <i class="pi pi-search"></i>
        </span>
      </div>
      <div class="col-sm-4">
        <button type="button" pButton pRipple icon="pi pi-file" 
          (click)="dt1.exportCSV()" class="button-blue"
          pTooltip="CSV" tooltip Position="bottom" style="float:right"></button>
          <button type="button" pButton pRipple icon="pi pi-file-pdf" 
          (click)="exportPdf()" style="float:right" class="button-orange" 
          pTooltip="PDF" tooltipPosition="bottom"></button>
      </div>
    </div>
  </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="fullName" style="width:20%">Name <p-sortIcon field="fullName"></p-sortIcon>
        </th>
        <th pSortableColumn="primaryEmail" style="width:20%">Email <p-sortIcon field="primaryEmail"></p-sortIcon>
        </th>
        <th pSortableColumn="orgDescription" style="width:20%"> Employee Type <p-sortIcon field="orgDescription">
          </p-sortIcon>
        </th>
        <th pSortableColumn="orgTitle" style="width:20%">Title <p-sortIcon field="orgTitle"></p-sortIcon>
        </th>
        <th pSortableColumn="orgName" style="width:20%">Organisation <p-sortIcon field="orgName"></p-sortIcon>
        </th>
        <th pSortableColumn="creationTime" style="width:20%">Creation <p-sortIcon field="creationTime"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
     
 
    <ng-template pTemplate="body" let-user>
      <tr 
      [style.background-color]="user.suspended ? 'pink' : ''"
        [routerLink]="['user', user.primaryEmail]"
        (contextmenu)="onLinkRightClicked(user, $event)" [pContextMenuRow]="user">
        <td><img class="rounded-circle" height="25" width="25" referrerpolicy="no-referrer"
            src={{user.thumbnailPhotoUrl}} onerror="this.src = 'assets/default_user.png'" /> {{user.fullName}}</td>
        <td>{{user.primaryEmail}}</td>
        <td>{{user.orgDescription}}</td>
        <td>{{user.orgTitle}}</td>
        <td>{{user.orgName}}</td>
        <td> {{user.creationTime}}</td>
      </tr>
    </ng-template>

  </p-table>


</div>

<app-reset-password [displayModal]='displayPasswordResetModal' [resetPasswordEmail]="selectedUserEmail" 
(resetDisplay)="resetPasswordModal($event)"></app-reset-password>

<app-suspend-acitvate-user 
[displayModal]="displaySuspendUserModal" [userFullName]="selectedUserFullName" 
[userEmail]="selectedUserEmail" [suspendMode]="suspendMode"
(resetDisplay)="resetSuspendUserModal($event)"></app-suspend-acitvate-user>

<app-delegate-user
  [displayModal]="displayAddDelegateModal" 
  [userData]="selectedUserData"
  [seldirectoryName]="seldirectoryName" 
  (resetDisplay)="resetAddDelegateModal($event)" >
  >
  </app-delegate-user>


<app-add-user-to-group 
[displayModal]="displayAddToGroupModal" 
[userData]="selectedUserData"
[seldirectoryName]="seldirectoryName"
(resetDisplay)="resetAddToGroupModal($event)" >
</app-add-user-to-group>

 

</div>

<!-- 
<div class="container">

    <div *ngIf='googleUsersList != null && selectedDirectory === "Google"' >
        <app-user-table [newUsersList]="googleUsersList"  ></app-user-table>
      
    </div>

    <div *ngIf='jumpcloudUsersList != null && selectedDirectory === "JumpCloud"'>
        <app-user-table [newUsersList]="newJumpCloudUsersList"></app-user-table>
    </div>

</div> -->

