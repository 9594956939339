import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'user-dropdown-component',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.css']
})
export class UserDropdownComponent {
  @Input("item") item;
  @Output() afterChange = new EventEmitter();
  //usersList = [];
  //item;

  constructor(private util: utilities,
    private _googleUsersRepo: UserRepository,
    ) { }

  get usersList() {
    return this._googleUsersRepo.getGoogleNamesForSearchDropDown();
  }

  // triggered by a change to the model, output this change to the parent.
  onChange(ev) {
    console.log("user " + ev + " chosen in user dropdown component");
    this.afterChange.emit(ev);

   }

}
