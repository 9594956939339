<div class="spacer"></div>

<span class="section-header">Create New User</span>

<form #form="ngForm" (ngSubmit)="submitForm(form)" (reset)="form.resetForm()">
    <div class="form-group  row">
        <label for="createFormfirstName" class="col-sm-2 col-form-label form-control-sm">First Name: </label>
        <div class="col-sm-4">
            <input type='text' id='createFormfirstName' name='createFormfirstName' class="form-control form-control-sm "
                [(ngModel)]="newUser.firstName" (keyup)="createUserName()" required #createFormfirstName="ngModel"
                ngModel
                [ngClass]="createFormfirstName.invalid && (createFormfirstName.dirty || createFormfirstName.touched) ? 'is-invalid' : 'is-valid-not'">
        </div>

    </div>

    <div class="form-group  row">
        <label for="createFormlastName" class="col-sm-2 col-form-label  form-control-sm">Last Name: </label>
        <div class="col-sm-4">
            <input type='text' id='createFormlastName' name='createFormlastName' class="form-control  form-control-sm "
                [(ngModel)]="newUser.lastName" (keyup)="createUserName()" required #createFormlastName="ngModel" ngModel
                [ngClass]="createFormlastName.invalid && (createFormlastName.dirty || createFormlastName.touched) ? 'is-invalid' : 'is-valid-not'">
        </div>
    </div>

    <div class="form-group  row">
        <label for="createFormUserName" class="col-sm-2 col-form-label  form-control-sm">Username: </label>
        <div class="col-sm-4">
            <input type='text' id='createFormUserName' name='createFormUserName' class="form-control form-control-sm "
                [(ngModel)]="newUser.userName" required #createFormUserName="ngModel" ngModel
                [ngClass]="createFormUserName.invalid && (createFormUserName.dirty || createFormUserName.touched) ? 'is-invalid' : 'is-valid-not'">
        </div>
    </div>

    <div class="form-group  row">
        <label for="domain" class="col-sm-2 col-form-label  form-control-sm">Domain: </label>
        <div class="col-sm-4">
           

            <select id="domain" 
                class="form-select form-select-sm" 
                #domainList 
                (change)="selectDomain(domainList.value)"
                name="domain"
                aria-label="domain">
                <option disabled selected value="" >pick domain</option>
                <option *ngFor="let domain of domains;  "value='{{domain.domainName}}'>{{domain.domainName}} </option>
            </select>
        </div>
    </div>



    <div class="form-group  row">
        <label for="description" class="col-sm-2 col-form-label  form-control-sm">Description: </label>
        <div class="col-sm-4">
            <input type='text' id='description' name='description' class="form-control  form-control-sm "
                [(ngModel)]="newUser.description">
        </div>
    </div>

    <ng-container *ngIf="manageAdobe">
        <div class="form-group  row">
            <label for="createAdobeUser" class="col-sm-2 col-form-label  form-control-sm">Create Adobe User?: </label>
            <div class="col-sm-1">
                <input type='checkbox' id='createAdobeUser' name='createAdobeUser' [(ngModel)]="newUser.assignAdobe"
                    class="form-check-input ">
            </div>
        </div>
        <div class="form-group row ">
            <label for="assignAdobeLicense" class="col-sm-2 col-form-label  form-control-sm">Assign License?: </label>
            <div class="col-sm-1">
                <input type='checkbox' id='assignAdobeLicense' name='assignAdobeLicense'
                    [(ngModel)]="newUser.assignLicense" class="form-check-input ">
            </div>
        </div>
        <div class="form-group row ">
            <label for="adobeLicenseSelect" class="col-sm-2 col-form-label  form-control-sm">License Type: </label>
            <div class="col-sm-5">

                <select id="adobeLicenseSelect" class="form-select form-select-sm" [(ngModel)]="newUser.licenseType"
                    [disabled]="!newUser.assignLicense" name="adobeLicenseSelect" aria-label="adobeLicenseSelect">
                    <option value="" disabled selected data-default>select license type</option>

                    <option *ngFor="let adobeLicence of adobeLicenses" value='{{adobeLicence}}'>
                        {{adobeLicence}}
                    </option>

                </select>
            </div>
        </div>
    </ng-container>

    <div *ngIf="
    (createFormfirstName.invalid && (createFormfirstName.dirty || createFormfirstName.touched)) 
    " class="alert alert-danger">
        <div *ngIf="createFormfirstName.errors['required']">
            First Name is required.
        </div>

    </div>

    <div *ngIf="

(createFormlastName.invalid && (createFormlastName.dirty || createFormlastName.touched)) 

" class="alert alert-danger">

        <div *ngIf="createFormlastName.errors['required']">
            Last Name is required.
        </div>

    </div>

    <div *ngIf="(createFormUserName.invalid && (createFormUserName.dirty || createFormUserName.touched))"
        class="alert alert-danger">

        <div *ngIf="createFormUserName.errors['required']">
            Username is required.
        </div>
    </div>


    <button type="submit" class="btn btn-success btn-sm" [disabled]="!form.valid" >Create</button>

    <button type="reset" class="btn btn-secondary btn-sm m-1">Cancel</button>
</form>

<div *ngIf="displayCredentials">
    <table>
        <tr>
            <td>Name:</td>
            <td>{{newUser.firstName}} {{newUser.lastName}}</td>
        </tr>
        <tr>
            <td>User Name:</td>
            <td>{{newUser.userName}}</td>
        </tr>
        <tr>
            <td>Email</td>
            <td>{{newUser.email}}</td>
        </tr>
        <tr>
            <td>Password</td>
            <td>{{newUser.password}}</td>
        </tr>

    </table>
</div>