export class UserVacation {
    enableAutoReply : boolean;
    responseSubject : string;
    restrictToContacts : boolean;
    restrictToDomain : boolean;
    responseBodyPlainText: string;
    responseBodyHtml: string;
    startTime: string;
    endTime: string;
}
