<div class="newFIlterContainer">
<section class="newFilterForm list-group">
    
    <article class="filterCriteria">
        <span class="smallText blueText">Criteria</span>
        <div class="oc-formInputDiv">
            <label class="oc-label" for="filterEmailFrom">Email From Address: </label>
            <input class="oc-input" type='text' id='filterEmailFrom' name="filterEmailFrom" [(ngModel)]="customFilter.from">          
        </div>
        <div class="oc-formInputDiv">
            <label class="oc-label"  for="filterEmailTo">Email To Address: </label>
            <input class="oc-input" type='text' id='filterEmailTo' name="filterEmailTo" [(ngModel)]="customFilter.to">          
        </div>
        <div class="oc-formInputDiv">
            <label class="oc-label"  for="filterQuery">Contains the words: </label>
            <input class="oc-input" type='text' id='filterQuery' name="filterQuery" [(ngModel)]="customFilter.query">          
        </div>
        <div class="oc-formInputDiv">
            <label class="oc-label"  for="filterNegatedQuery">Doesn't Have: </label>
            <input class="oc-input" type='text' id='filterNegatedQuery' name="filterNegatedQuery" [(ngModel)]="customFilter.negatedQuery">          
        </div>
        <div class="oc-formInputDiv">
            <label class="oc-label"  for="filterSubject">Subject: </label>
            <input class="oc-input" type='text' id='filterSubject' name="filterSubject" [(ngModel)]="customFilter.subject">          
        </div>
    </article>
    <article class="filterAction">
        <span class="smallText  blueText">Action</span>
        <div class="oc-formInputDiv">
            <label class="oc-label" for="labelSelectDropdown">Add Label</label>
            <!-- <select class="oc-select" id="labelSelectDropdown" (change)="labelSelected(labelselect.value)" #labelselect> -->
                <select class="oc-input" id="labelSelectDropdown" [(ngModel)]="customFilter.addLabelId" #labelselect> 
                <option value="">...</option>
                <option *ngFor="let label of labelsList" value="{{label.id}}">
                    {{label.name}}
                </option>
            </select>
        </div>
       
            <div class="oc-formInputDiv">
                <label class="oc-label"  for="filterForwardTo">Forward To: </label>
                <input class="oc-input" type='text' id='filterForwardTo' name="filterForwardTo" [(ngModel)]="customFilter.forward">          
            </div>
            <div class="oc-formInputDiv">
                <label class="oc-label"  for="filterSkipInbox">Skip the Inbox: </label>
                <input class="oc-checkbox" type='checkbox' id='filterSkipInbox' name="filterSkipInbox" [(ngModel)]="customFilter.SkipInbox">          
               
            </div>
            <div class="oc-formInputDiv">
                <label class="oc-label"  for="filterNeverSendToSpam">Never Send to Spam: </label>
                <input class="oc-checkbox" type='checkbox' id='filterNeverSendToSpam' name="filterNeverSendToSpam" [(ngModel)]="customFilter.NeverSendToSpam">               
            </div>
    </article>
    
</section>
<div class="buttonRow">
    <button class="button-blue" (click)="addFilter()">{{buttonText}}</button>
</div>
</div>

<app-filters-list-display [filtersList]="filters" [labelLookUpList]="labelNameLookupMap"  (deleteFilterId)="deleteFilter($event)" (editFilterId)="editFilter($event)"></app-filters-list-display>

