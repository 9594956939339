<p>The following filters are applied to all incoming mail:</p>
<div class="container-fluid">
    <ul class="list-group"> 
    <li *ngFor="let filter of filtersList" class="list-group-item">
    
    <div class="form-group row smallText">
        <div class="col-sm">
            <span class="localHeader">Matches</span>
            <ul class="list-group">
               <li *ngFor="let c of filter.criteria | keyvalue" class="list-group-item"> 
                    {{c.key}} : {{c.value}}
               </li> 
            </ul>
        </div>
        <div class="col-sm">
            <span class="localHeader">Action</span>
            <ul class="list-group">
               <li *ngFor="let a of filter.action | keyvalue" class="list-group-item">
                     {{a.key}} : 
                     <!-- If we are adding a label, look up its name rather than showing it's id -->
                     <ng-container *ngIf="a.key=='addLabelIds'; else showValue ">
                        {{labelName(a.value)}}
                    </ng-container>
                    <ng-template #showValue>{{a.value}}</ng-template>
                    
               </li> 
            </ul>
        </div>
        <div class="col-sm-1">
            <button class="btn btn-sm btn-primary" (click)="editFilter(filter)">Edit</button>
        </div>
        <div class="col-sm-1">
            <button class="btn btn-sm btn-danger" (click)="deleteFilter(filter)">Delete</button>
        </div>
        
    </div>
</li>
</ul>
</div>