export class UserForwarding {
    forwardingAddresses: forwardwingAddress[]
}

export class forwardwingAddress {
    forwardingEmail: string;
    verificationStatus: string;
}

export class autoForwader {
    enabled: boolean;
    disposition: string;
    emailAddress: string;
}


export class mailForwarderMap {
    emailAddress: string;
    autoFwd: boolean;
    usedByFilter: boolean;
    filters: mailForwarderMapFilter[];

    constructor() {
        this.autoFwd = false;
        this.usedByFilter = false;
        this.filters = [];
    }
}


export class mailForwarderMapFilter {
    filterId: string;
    queryName: string;
}