import { Injectable, Optional, SkipSelf} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global_constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { utilities } from '../utilities/utilities';
import { ClientRepository } from '../Repositories/client.repository';


@Injectable({
  providedIn: 'root'
})
export class GoogleGroupsServiceService {
  private all$: BehaviorSubject<any> = new BehaviorSubject(null);
  private currentlySelectedName$: BehaviorSubject<any> = new BehaviorSubject(null);
  private currentlySelectedData: BehaviorSubject<any> = new BehaviorSubject(null);
  currentClientData;

  constructor(
    private http: HttpClient,
    private util: utilities,
    private _clientRepository: ClientRepository,
    @Optional() @SkipSelf() sharedService?: GoogleGroupsServiceService
  ) {

    if (sharedService) {
      throw new Error('GoogleGroupsServiceService is already loaded');
    }
    this.util.debug('GoogleGroupsServiceService created');
    this.getAll();
  }


  // consumers subscribe to this to get the data for all of the clients.
  getAllData() {
    return this.all$.asObservable();
  }


  // get the data from the server. Initally called by the constructor. Should be called later 
  // if any client info is updated ( signatures, new client , orgs etc ).
  getAll(source: string = "Class Constructor") {
    
        this.currentClientData = this._clientRepository.getCurrentClient();
        if (this.currentClientData != undefined && this.currentClientData != "") {
          this.http.get(GlobalConstants.apiURL + "groups?client="
            + this.currentClientData.client
            + "&domain=" + this.currentClientData.primary_domain
          ).subscribe(data => this.all$.next(data));
        }
      
    
    this.util.debug("GoogleGroupsServiceService.getAll called by " + source);

  }


}

