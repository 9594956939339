<p-dialog header="{{modeText}} User" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">

    Are you sure you want to <b>{{modeText}}</b> {{userFullName}}?
    
    <ng-template pTemplate="footer">
      <button type="button" class="btn btn-secondary" (click)="closeDisplay()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)='performAction()'>OK</button>
      </ng-template>
</p-dialog>
