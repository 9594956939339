export class CustomFilter {
    id: string;
    from: string;
    to: string;
    query: string;
    negatedQuery: string;
    subject: string;
    addLabelId: string;
    forward: string;
    SkipInbox: boolean;
    NeverSendToSpam: boolean;
}