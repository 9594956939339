import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { userMailFilter } from 'src/app/models/user.mail.filter.model';
import { UserRepository } from 'src/app/Repositories/user.repository';


@Component({
  selector: 'app-filters-list-display',
  templateUrl: './filters-list-display.component.html',
  styleUrls: ['./filters-list-display.component.css']
})
export class FiltersListDisplayComponent implements OnInit {
  @Input() filtersList: userMailFilter[];
  @Input() labelLookUpList: any[];
  @Output() editFilterId = new EventEmitter<string>();
  @Output() deleteFilterId = new EventEmitter<string>();

  constructor(
    private _userRepository: UserRepository
  ) { }

  ngOnInit(): void {
  }

  editFilter(id) {
    this.editFilterId.emit(id);
  }

  deleteFilter(id) {
    this.deleteFilterId.emit(id);
  }

  labelName(labelid) {
    /* Look up the label Name with the Id for a nice human friendly display */
    return this._userRepository.getLabelNameFromId(labelid);
  }
}
