import { Component, OnInit } from '@angular/core';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-signatures',
  templateUrl: './signatures.component.html',
  styleUrls: ['./signatures.component.css']
})
export class SignaturesComponent implements OnInit {
  templateText = "";
  selectedOrganisation = "";

  constructor(
    private _clientRepository: ClientRepository,
    private util: utilities
  ) { }

  ngOnInit(): void {
  }

  get currentClient() {
    return this._clientRepository.getCurrentClient();
  }


  getSignature(event) {
    console.log(event.value);
    this.selectedOrganisation = event.value;
    this.templateText = this.currentClient.organisations[this.selectedOrganisation].signature;
  }
  
  saveSignature() {
    this._clientRepository.patchSignature(this.selectedOrganisation,this.templateText);
  }

}
