import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global_constants';
import { Observable, BehaviorSubject, catchError, observable } from 'rxjs';
import { utilities } from '../utilities/utilities';
import { fileCSVRecord } from '../models/filecsv';
import { emailAddress } from '../models/googleUser.model';

@Injectable({
    providedIn: "root"
})

export class googleDriveDataService {

    
    constructor(
        private http: HttpClient,
        private util: utilities,
   ) {
        this.util.debug('googleDriveDataService created');
    }

    ngOnInit(): void {
    }


    removePermission(client: string, record: fileCSVRecord, userEmail:string ):Observable<any>  {
        let sendObj = {
            client: client,
            fileID: record.documentID,
            user: userEmail
          }
      
          let url= GlobalConstants.apiURL + "drive/permissions";
          return this.http.delete<any>(url, {responseType: 'json', body: sendObj});
    }
}