import { Component, OnInit,OnChanges, SimpleChanges, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { utilities } from 'src/app/utilities/utilities';
import { googleInfoRepository } from 'src/app/Repositories/googleInfo.repository';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { GoogleUserDataService } from 'src/app/dataservices/google-user-dataservice';
import { group} from 'src/app/models/group.model';
import { groupMember, groupMembers } from 'src/app/models/groupMembers.model';
import { ClientRepository } from 'src/app/Repositories/client.repository';

import { TreeNode } from 'primeng/api';
import { googleGroupsDataService } from 'src/app/dataservices/google-info.dataservice';


@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.css']
})
export class GroupEditComponent implements OnInit {
  groupID: string;
  selectedDirectory: string;
  newAliases: string[] = [];  
  clientName: string = "";
  currentGroup: group = new group;
  exportColumns;
  // columns for tree view:
  cols: any[];

  // data for tree view
  files: TreeNode[];

  constructor(
    private _route: ActivatedRoute,
    private _googleInfoRepository: googleInfoRepository,
    private googleUserDataSource: GoogleUserDataService,
    private _googleGroupsDataService: googleGroupsDataService,
    private _clientRepository: ClientRepository,
    private util: utilities,
    private _userRepository: UserRepository
  ) { }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
     // console.log(params);
      this.groupID = params["groupID"];
      this._googleInfoRepository.getGroupMembersByID(this.groupID);
      console.log(this.groupID);
      this.clientName = this._clientRepository.getCurrentClientName();
      this._userRepository.getGoogleListFromServer(this.clientName);
      this.currentGroup = this._googleInfoRepository.getGroups().find(group => group.email == this.groupID);
    });
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'email', header: 'Email' },
      { field: 'role', header: 'Role' },
      { field: 'type', header: 'Type' },
    ];
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }


  inputChanged(inputName) {
    console.log(this.currentGroup);
  }

  get groupMembers() {
    return this._googleInfoRepository.getGroupMembers();
  }

  get membersTreeMap() {
    return this._googleInfoRepository.getGroupMembersTreeMap();
  }
  

  get groupInfo() {
    return this._googleInfoRepository.getGroupAliasesFromID(this.groupID);
  }

  fullNameFromEmail(userEmail) {
    if (this._userRepository.getUserByEmail(userEmail)) {
      return this._userRepository.getUserByEmail(userEmail).name.fullName;
    } 
    return "";
  }

  getUserThumbnailURL(userEmail) {
    if (this._userRepository.getUserByEmail(userEmail)) {
      return this._userRepository.getUserByEmail(userEmail).thumbnailPhotoUrl;
    //let user = this._userRepository.getUserByEmail(userEmail);
    //if ("thumbnailPhotoUrl" in user) { return user.thumbnailPhotoUrl }
    }
    return "";
  }

  getGroupNameFromEmail(emailAddress) {
    let groupList = this._googleInfoRepository.getGroups();
    let fg = groupList.find(group => group.email == emailAddress);
    if ("name" in fg) {
      return fg.name;
    } 
    return "";
  }

  deleteMember( email) {
    let sendObj = {
      group: this.groupID,
      email: email,
      client: this.clientName,
    }
    console.log(sendObj);
    this.googleUserDataSource.removeUserFromGroup(sendObj).subscribe( data => 
      {
          this.util.displayMessageToUser(email + " was removed from " + sendObj.group,"",true);   
          this._googleInfoRepository.getGroupMembersByID(this.groupID);
        })

 
  }

  deleteAlias( aliasIndex) {
 

    if( this.groupInfo[aliasIndex] != "") {
      // delete it !
      this.util.debug("delete Alias: " + this.groupInfo[aliasIndex]);
      // here we delete it, and if successfull remove it from the newALiases array and add it to the userModel.
      
      this._googleGroupsDataService.deleteAlias( this._clientRepository.getCurrentClientName(),
        this.currentGroup.email,
        this.groupInfo[aliasIndex]
        ).subscribe(
        data => {
           // .... data to save it
           this.util.displayMessageToUser("Alias " + this.groupInfo[aliasIndex] + " has been removed");
         
          this.groupInfo.splice(aliasIndex,1);
        }
      )

  }

}

  trackByFn(){

  }


  

  saveAlias(aliasIndex) {
  

   if( this.newAliases[aliasIndex] != "") {
    // save it !
    this.util.debug("save Alias: " + this.newAliases[aliasIndex]);
    // here we save it, and if successfull remove it from the newALiases array and add it to the userModel.
    
    this._googleGroupsDataService.addAlias( this._clientRepository.getCurrentClientName(),
      this.currentGroup.email,
      this.newAliases[aliasIndex]
      ).subscribe(
      data => {
         // .... data to save it
         this.util.displayMessageToUser("Alias " + this.newAliases[aliasIndex] + " has been added");
        this.groupInfo.push(this.newAliases[aliasIndex]);
        this.newAliases.splice(aliasIndex,1);
      }
    )
   
  }   



  }

  addAlias() {
    let newAlias = "";
    this.newAliases.push(newAlias);

  }

  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l');
            (doc as any).autoTable(this.exportColumns, this.groupMembers);
          
            doc.save('groupMembers.pdf');
        })
    }) 
  } 
}