import { Component, OnInit } from '@angular/core';
import { utilities } from 'src/app/utilities/utilities';
import { NavClientSwitchComponent } from 'src/app/components/nav-client-switch/nav-client-switch.component';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { googleInfoRepository } from 'src/app/Repositories/googleInfo.repository';
import { filtersStaticData } from 'src/app/dataservices/filiters.static';
import { staticUserData } from 'src/app/dataservices/userData.static';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  selectedGroup;
  localStartDate;

  constructor(
    private util: utilities,
    private clientSelectorComponent: NavClientSwitchComponent,
    private _clientRepository: ClientRepository,
    private _userRepository: UserRepository,
    private _googleInfo: googleInfoRepository,
    private _filters: filtersStaticData,
    private _staticUser: staticUserData
    ) {}
 
 


 ngOnInit() { 
    
  }

  get groupsList() {
    return this._googleInfo.getGroups();
  }

  get clientName() {
    return this._clientRepository.getCurrentClientName();
  }

  showGroup($event) {
    this.selectedGroup = $event;
  }

  get userData() {
    return this._staticUser.getUserInfo()
  }
   
  get filters() {
    return this._filters.getFilters();
  }
  

  // get googleUsers() {
  //   return this._userRepository.getGoogleUsersListEmails();
  // }

  deleteFilter(filterObj) {
    console.log(filterObj);
  }

  editFilter(filterObj) {
    console.log(filterObj);
  }

  get labels() {
    return this._staticUser.getUserInfo().labels;
  }

}
