import { Injectable } from "@angular/core";
import { delegate, UserInfo } from "../models/fullUserInfo.model";
import { GoogleUserDataService } from "../dataservices/google-user-dataservice";
import { User, userAddress, userOrganizations, usersObjectForSearchDropdown } from "../models/googleUser.model";
import { JumpcloudDataService } from "../dataservices/jumpcloud-dataservice";
import { utilities } from "../utilities/utilities";
import { sendAsEmail } from "./userSendAs.model";
import { userPhone, userWebsite } from "../models/googleUser.model";
import { listUser } from 'src/app/models/googleUser.model';
import { googleInfoRepository } from "./googleInfo.repository";
import { group } from "../models/group.model";
import { forwardwingAddress, mailForwarderMapFilter } from "../models/userForwarding.model";
import { mailForwarderMap } from "../models/userForwarding.model";
import { groupMember } from "../models/groupMembers.model";
import { userMailLabel } from "../models/mailLabel.model";
import { userMailFilter } from "../models/user.mail.filter.model";



@Injectable()

export class UserRepository {
    private googleUsersList: User[] = [];
    private googleUsersListEmails: string[] = [];
    private googleUsersListNames: string[] = [];
    private jumpcloudUsersList: User[] = [];
    private jumpcloudUsersListEmails: string[] = [];
    private jumpcloudUsersListNames: string[] = [];
    private jumpCloudEmailIdMap: string[] = [];
    private selectedGoogleUser: User;
    private _googleUsersListForTable: listUser[] = [];
    private _googleNamesForUserSearchDropDown: usersObjectForSearchDropdown[] = [];
    private _googleGroupMembers: groupMember[] = [];

    private _client;
    private _useJC;
    private _mailForwardingMap: mailForwarderMap[] = [];
    labelTreeMap;
    labelslist: any[] = [];
    labelNameLookupMap: any[] = [];

    private activeUsers: number = 0; 
    private suspendUsers: number = 0;

    // set to public so we can use them as a datamodel in forms.
    // then they are auto synced with any changes.
    // this way the user object is already built and ready to send to patch user.
    public googleUser = new UserInfo();
    public jumpcloudUser: UserInfo;

    constructor (
        private googleUserDataSource: GoogleUserDataService,
        private jumpcloudUserDataSource: JumpcloudDataService,
        private _googleInforepository: googleInfoRepository,
       
        private util: utilities) {
       
    }

    getGoogleListFromServer(client) {
        this.util.debug("user.repository getGoogleListFromServer called");
        this.googleUsersListEmails = [];
        this.googleUsersListNames= [];
        this.googleUsersList = [];
        this._googleNamesForUserSearchDropDown = [];

        this. googleUserDataSource.getAllUsers('UserRepository', client).subscribe( data => {
            this.googleUsersList = data.body.users;
            console.log(data.body.users);
            // a for loop with cached length is 3 x faster than map or similar.
            let leng = data.body.users.length;
            for (let i = 0; i < leng ; i++) {
                this.googleUsersListNames.push(data.body.users[i].name.fullName);
                this.googleUsersListEmails.push(data.body.users[i].primaryEmail);
                let nameItem = new usersObjectForSearchDropdown();
                nameItem.name = data.body.users[i].name.fullName;
                nameItem.email = data.body.users[i].primaryEmail;
                this._googleNamesForUserSearchDropDown.push(nameItem);
                
            }
            
            this._googleUsersListForTable = this.convertUserListYoTableFormat ( this.googleUsersList );
           
          
    });
    }

    getJumpcloudListFromServer(client) {
        this.client = client;
        this.jumpcloudUsersListEmails = [];
        this.jumpcloudUsersListNames= [];
        this.jumpcloudUsersList = [];
        this._googleNamesForUserSearchDropDown = [];
        this.jumpCloudEmailIdMap = [];

        this. jumpcloudUserDataSource.getAllUsers('UserRepository', client).subscribe( data => {
            this.jumpcloudUsersList = data.body.users;
            console.log ("JUMPCLOUD SUSERS");
            console.log(data.body.users);
            let leng = data.body.users.length;
            for (let i = 0; i < leng; i++) {
                this.jumpcloudUsersListNames.push(data.body.users[i].name.fullName);
                this.jumpcloudUsersListEmails.push(data.body.users[i].primaryEmail);
                 // create a map of emails to usersIDS !
                this.jumpCloudEmailIdMap[data.body.users[i].primaryEmail] = data.body.users[i].id;
            }
           
            console.log(this.jumpcloudUsersList);
            // console.log(this.jumpcloudUsersListNames);
    });
    }

    getJumpCloudID ( emailAddress ) {
        // only return data if we are using jumpcloud!
        if (this.jumpCloudEmailIdMap[emailAddress]) {
            return this.jumpCloudEmailIdMap[emailAddress];
        }
        return null;
    }
    getGoogleNamesForSearchDropDown() {
        //console.log(this._googleNamesForUserSearchDropDown);
        return this._googleNamesForUserSearchDropDown;
    }

    getEmailFromFullName(fullName) {
        let foundUser =  this.googleUsersList.find( user => user.name.fullName == fullName)
        return foundUser.primaryEmail;
    }

    getGoogleUserList() {
        //this.util.debug("user.repository getGoogleUserList called");
        return this.googleUsersList;
    }

    getGoogleUsersListTableFormat() {
        return this._googleUsersListForTable;
    }

    getGoogleUsersListEmails() {
        return this.googleUsersListEmails;
    }

    getGoogleUsersListNames() {
        return this.googleUsersListNames;
    }

    getJumpcloudUserList() {
        return this.jumpcloudUsersList;
    }

    getJumpcloudUsersListEmails() {
        return this.jumpcloudUsersListEmails;
    }

    getJumpcloudUsersListNames() {
        return this.jumpcloudUsersListNames;
    }

    getGoogleUserFromServer(userEmail, client) {
        this._client = client.client;
        this.googleUser = new UserInfo;
        this.googleUserDataSource.getUser(userEmail, client).subscribe( data => { 
                this.googleUser = data;
                this.util.debug(data);
                // check to see if gender exists, if it doesn't then add it to prevent run time errors when we check for it later.
                // need to cast to as any, otherwise the compiler sees it as a userinfo object which already has
                // gender. What it doesn't know is the object returned might not have it !
                if (!('gender' in this.googleUser.userData as any)) {      
                    this.util.debug("adding gender");
                    this.googleUser.userData.gender = {
                        type: "",
                        addressMeAs: ""
                    };
                } else {
                    
                }
                //userData.userData.organizations[0]
                if (!('organizations' in this.googleUser.userData as any) ) {
                    this.util.debug("adding an organisation to google user object");
                    const org = new userOrganizations;
                    this.googleUser.userData.organizations = [];
                    this.googleUser.userData.organizations.push(org);
                }


                // check for address and phones:
                if(!('addresses' in this.googleUser.userData  as any)) {
                    const address = new userAddress()
                    address.formatted = "";
                    address.type = 'work';
                    
                    let addresses:userAddress[] = [];
                    addresses.push(address);
                    this.googleUser.userData.addresses = addresses;

                }
                let work = new userPhone();
                work.type = 'work';
                work.value = "";
                let mobile = new userPhone();
                mobile.type = 'mobile';
                mobile.value = "";

                if(!('phones' in this.googleUser.userData  as any) ) {
                    console.log("no phones");
                    this.googleUser.userData.phones = [];
                   
                    this.googleUser.userData.phones.push(work);
                    this.googleUser.userData.phones.push(mobile);
                } else {
                    let num = this.googleUser.userData.phones.length;
                    let gotMobile = false;
                    let gotWork = false;
                    for (let p = 0; p < num; p++) {
                        if (this.googleUser.userData.phones[p].type == 'mobile' ) { gotMobile = true}
                        if (this.googleUser.userData.phones[p].type == 'work' ) { gotWork = true}
                    }
                    if (!gotMobile) { this.googleUser.userData.phones.push(mobile) }
                    if (!gotWork) { this.googleUser.userData.phones.push(work) }

                }

                if(!('websites' in this.googleUser.userData  as any)) {
                    const website = new userWebsite();
                    website.type = 'work';
                    website.value= "";
                    this.googleUser.userData.websites  = [];
                    this.googleUser.userData.websites.push(website);
                  
                }


                // if no delagates are found, the lambda function returns a string
                // so we need to check for that and convert to an array .
                
                if (typeof(this.googleUser.delegates) == "string") {
                    console.log ("delgates is a string !");
                    let d = {
                        delegates: []
                    }
                    this.googleUser.delegates = d;
                }
               this.buildMailForwardingMap()
               
                
                this.buildLabelsListForDisplay();

               
                console.log(this.googleUser.userData);
             });

        
     }

   

     buildMailForwardingMap() {
        this._mailForwardingMap = [];
        // debugger;
        let nFwd;
        // create map of email forwarders from autoforward, filters etc!
        if (this.googleUser.forwarding.forwardingAddresses) {
            let numForwardAddresses = this.googleUser.forwarding.forwardingAddresses.length;
            for ( let i=0; i < numForwardAddresses; i ++) {
                nFwd = new mailForwarderMap();
                nFwd.emailAddress = this.googleUser.forwarding.forwardingAddresses[i].forwardingEmail;
                if ('emailAddress' in this.googleUser.autoForwarding) {
                    if (this.googleUser.autoForwarding.emailAddress == nFwd.emailAddress) {
                        nFwd.autoFwd = true;
                    }
                }
                if (this.googleUser.filters.filter.length > 0) {
                    let numFilters=this.googleUser.filters.filter.length;
                    for ( let j=0; j < numFilters; j ++ ) {
                        
                        if ('forward' in this.googleUser.filters.filter[j].action) {
                            if ( this.googleUser.filters.filter[j].action.forward == nFwd.emailAddress ) {
                                let nFilt = new mailForwarderMapFilter();
                                console.log("filter number: "  + j)
                                console.log(this.googleUser.filters.filter[j]);
                                nFwd.usedByFilter = true;
                                nFilt.filterId = this.googleUser.filters.filter[j].id;
                                nFilt.queryName = this.googleUser.filters.filter[j].criteria.query;
                                nFwd.filters.push(nFilt);
                            }

                        }
                    }
                }
                this._mailForwardingMap.push(nFwd);
            }
        }
        console.log(this._mailForwardingMap);



     }

     buildLabelsListForDisplay() {
        /// create mapped heirarchy for label tree display 
        let result = [];
        let level = { result };
        this.labelslist = [];
        this.labelNameLookupMap = []

        this.googleUser.labels.forEach(label => {

            
            if (label.type != 'system') {
                this.labelslist.push(label);
                // add the label name to lookup array that has the id as key. This is for human friendly display later.
                this.labelNameLookupMap[label.id] = label.name;
                label.name.split('/').reduce((r, name, i, a) => {
                    //console.log(name);
                    if (!r[name]) { // if the name doesn't exist in the array
                        r[name] = { result: [] }; // create an array called result:

                        r.result.push({ label: name, data: label, children: r[name].result })
                        //let nObj = {}
                        //r.result.push({ label, children: r[name].result })
                    }

                    return r[name];
                }, level)
            }
        })
        console.log(result);
        this.labelTreeMap =  result;

        // sort labels into alphabetaical list for the drop down.
        //this.labelslist.sort();
        this.labelslist.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        this.util.debug("AFTER BUILD LABELS LIST");
        this.util.debug(this.labelslist);
        this.util.debug(this.labelNameLookupMap);

     }

     getLabelNameLookupMap() {
        return this.labelNameLookupMap;
     }

     getLabelNameFromId(labelId) {
        /*  Look up the label Name with the Id for a nice human friendly display 
            But return the Id if we can't find it in the list, ie a system label */
        if (this.labelNameLookupMap[labelId])  {
            return this.labelNameLookupMap[labelId];
        } else {
            return labelId;
        }
     }

     convertUserListYoTableFormat (list) {
        let newList = [];
        let num= list.length;
        
        for (let i=0; i< num; i++) {
          let user = new listUser;
          user.primaryEmail = list[i].primaryEmail;
          user.thumbnailPhotoUrl = list[i].thumbnailPhotoUrl;
          user.fullName = list[i].name.fullName;
          if (list[i].organizations) { 
            user.orgDescription = list[i].organizations[0].description;
            user.orgName = list[i].organizations[0].name;
            user.orgTitle = list[i].organizations[0].title;
          }
          user.suspended = list[i].suspended;
          //console.log(list[i])
          if ("creationTime" in  list[i]) {
            user.creationTime = list[i].creationTime 
            } else { 
                user.creationTime = "";
            }
              newList.push(user);
        }
        
    
        return newList;
      }

     getMappedAliases() {
        let num_aliases = this.googleUser.userData.aliases.length;
        let retArray: sendAsEmail[] = [];
        for (let i=0; i < num_aliases; i++) {
            let thisSendAs = new sendAsEmail();
            
        }
     }

     getGoogleUser() {
        return this.googleUser;
     }


     patchGoogleUser() {
        this.util.debug(this.googleUser.userData);
        this.googleUserDataSource.patchUser(
            this.googleUser.userData,
            this._client,
            false
            ).subscribe( data => { 
            this.util.debug({data})

        });
        
        
     }

     get useJC() {
        return this._useJC;
     }

     set useJC(useJC) {
        this._useJC = useJC;
     }

     setUseJC(useJC) {
        this._useJC = useJC;
     }

     get client() {
        return this._client;
     }

     set client(clientName) {
        this._client = clientName;
     }

    addUser() {

     }

     addAlias(emailAddress: string, alias:string, client:string ) {
        this.googleUserDataSource.addAlias(emailAddress, alias, client).subscribe( data=> {
            return data;
        })
        
     }

     getUserByEmail(emailAddress) {
        return this.googleUsersList.find(user => user.primaryEmail == emailAddress);
     }

     findUserInDirectories(useremail) {
        // if userJC is enabled this will search the Jumpcloud userslist for the user and return their jumpcloud ID.
        // this is needed for the reset password and update user functions if Jumpcloud is being used.
    let retObj = {
        existsInJC: false,
        existsInGoogle: false,
        id: "",
        client: this._client

    }
    // check google?
    if (this.googleUsersList.find(user => user.primaryEmail == useremail)) { retObj.existsInGoogle = true};

    if (this._useJC) {
        // we are using jumpcloud, so let's try and find the user !
        // array.find(function(currentValue, index, arr),thisValue)
        let foundUser = this.jumpcloudUsersList.find(user => user.primaryEmail == useremail);
        if( foundUser) {
            // ok we got one ;)
            retObj.existsInJC=true;
            retObj.id= foundUser.id;

        }
    }
    this.util.debug(retObj);
    this.util.debug("client " + this._client);
    return retObj;
     }

     addUserToGoogleGroup(groupEmail, primaryEmail = "") {
       
        if (primaryEmail == "") {
            this.util.debug("userRepsoitory.addUserToGoogleGroup() primary email is empty",1);
            return;
        }
        let sendObj = {
            group: groupEmail,
            email: primaryEmail,
            client: this._client,
        }

          this.googleUserDataSource.addUserToGroup(sendObj).subscribe(data => 
            {
                this.util.displayMessageToUser(primaryEmail + " was added to " + sendObj.group,"",true);
                // add group to users group list.
               

                let groupsList = this._googleInforepository.getGroups();
                let addedGroup: group = groupsList.find(g => g.email == sendObj.group as any);
                
                // check to see if we have any groups yet, if not create an ampty array.
                if(!("groups" in this.googleUser.groups as any)) {
                  let gr: group[] = [];
                  this.googleUser.groups.groups = gr;
      
               }
                this.googleUser.groups.groups.push(addedGroup);
      
            })
     }

     removeUserFromGoogleGroup(index) {
        this.util.debug("remove " + this.googleUser.userData.primaryEmail + " from " + this.googleUser.groups.groups[index]);
       
        let sendObj = {
            group: this.googleUser.groups.groups[index].email,
            email: this.googleUser.userData.primaryEmail,
            client: this._client,
        }
        this.googleUserDataSource.removeUserFromGroup(sendObj).subscribe( data => 
            {
                this.util.displayMessageToUser(this.googleUser.userData.name.fullName + " was removed from " + sendObj.group,"",true);
                this.googleUser.groups.groups.splice(index, 1);
            })

     }

  
      

     addDelegate(userEmail, delegateEmail, skipAddToUser = false) {
        // the skipAddToUser is so that we don't try to add the delegate to the current Google User if this
        // function is called from the the right click menu on the google list.


        let sendObj = {
            delegateObj : {
            delegateEmail : delegateEmail,
            verificationStatus : 'accepted'
        },
        id : userEmail,
        client : this._client
        }
        let newDelgate = new delegate();
        newDelgate.delegateEmail = delegateEmail;
        newDelgate.verificationStatus = 'accepted';

        this.googleUserDataSource.addDelegate(sendObj).subscribe(
            data => {
                this.util.displayMessageToUser(delegateEmail + " was given access to " + userEmail,"",true);
                if (!skipAddToUser) {
                    if(!("delegates" in this.googleUser.delegates as any)) {
                        let d: delegate[] = [];
                        this.googleUser.delegates.delegates = d;
                    }
                    this.googleUser.delegates.delegates.push(newDelgate);
                }
            }
        )
     }

     removeDelegate(userEmail, delegateIndexl) {
        this.googleUserDataSource.removeDelegate(userEmail, 
            this.googleUser.delegates.delegates[delegateIndexl].delegateEmail, 
            this._client).subscribe (
            data => {
                this.util.displayMessageToUser(this.googleUser.delegates.delegates[delegateIndexl].delegateEmail + " was removed from " + userEmail,"",true);
                this.googleUser.delegates.delegates.splice(delegateIndexl, 1);
            }
        )
     }

     setSignature(sendOj, index) {
        this.googleUserDataSource.setSignature(sendOj).subscribe ( data =>
            {// change the signature for the selected user
            this.googleUser.sendAs[index].signature = data.signature;
        });
     }

     patchVacation(sendOj) {
        this.googleUserDataSource.patchVacation(sendOj).subscribe (data =>
            {
                // this.util.debug("returned from patch vacation:");
                // this.util.debug(data);
                this.util.displayMessageToUser("Vacation settings updated","");
                this.googleUser.vacation = data;
            })
     }

     addEmailForwarder(sendObj) {
        this.googleUserDataSource.addEmailForwarder(sendObj).subscribe( data => 
            {
                this.util.displayMessageToUser("Forwarder added","");
                this.util.debug(data);
                // add to local user object so the display updates.
                let newFwd = JSON.parse(data.body);

                // let newFwd = new forwardwingAddress();
                // newFwd.forwardingEmail = sendObj.forwardingAddress.forwardingEmail;
                // //newFwd.verificationStatus = "accepted";
                this.googleUser.forwarding.forwardingAddresses.push(newFwd);
                this.buildMailForwardingMap()
                this.util.debug(this.googleUser.forwarding.forwardingAddresses);
            });
     }

     updateAutoForwarder(sendObj) {
        // THIS NEEDS TO BE REDONE !
        
        
        this.googleUserDataSource.updateAutoForwarder(sendObj).subscribe( data => 
            {
               
                this.util.displayMessageToUser("Auto Forwarder Set","");
                this.util.debug(data);
                // add to local user object so the display updates.
                this.googleUser.autoForwarding = sendObj.autofowardingObj;
                this.buildMailForwardingMap()
            });
     }

     deleteEmailForwarder(sendObj, index) {
        this.googleUserDataSource.deleteEmailForwarder(sendObj).subscribe( data => 
            {
                this.util.displayMessageToUser("Forwarder removed","");
                // remove from local user object so the display updates.
                this.googleUser.forwarding.forwardingAddresses.splice(index,1);
                this.buildMailForwardingMap()
                this.util.debug(this.googleUser.forwarding.forwardingAddresses);
            });
     }
   
    getGoogleGroupMembersFromServer(client, groupEmail) {
        this._googleGroupMembers = [];
        this.googleUserDataSource.getGoogleGroupMembers(client,groupEmail).subscribe( data =>
            {
                this.util.debug(data);
                this._googleGroupMembers = data.body.members;
            });
    }

    getGoogleGroupMembers() {
        return this._googleGroupMembers;
    }


    getMailForwardingMap() {
        return this._mailForwardingMap;
    }

    getLabelTreeMap() {
        return this.labelTreeMap;
    }

    getLabelsList() {
        return this.labelslist;
    }

 

    emptyUserList() {
        this.googleUsersList = [];
    }


}


    



