export class userMailFilter {
    id?: string;
    action: {
        removeLabelIds?: string[];
        forward?:string;
        addLabelIds?: string[]
    };
    criteria: {
        from?: string;
        to?: string;
        subject?: string;
        query?: string;
        negatedQuery?: string;
        hasAttachment?: boolean;
        excludeChats?: boolean;
    };
}


