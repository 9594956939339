import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { UserlistComponent } from './pages/user-list.page/user-list.page';
import { GroupsComponent } from './pages/groups-list.page/groups.component';
import { UserComponent } from './pages/user-edit.page/user-edit.page';
import { UserCreatePageComponent } from './pages/user-create.page/user-create.page';
import { GroupEditComponent } from './pages/group-edit/group-edit.component';
import { AdobeReassignComponent } from './pages/adobe-reassign/adobe-reassign.component';

import { JumpcloudGroupEditPageComponent } from './pages/jumpcloud-group-edit-page/jumpcloud-group-edit-page.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { BulkActionsComponent } from './components/bulk-actions/bulk-actions.component';
import { SignaturesComponent } from './pages/tools/signatures/signatures.component';
import { OrganisationsComponent } from './pages/tools/organisations/organisations.component';
import { LocationsComponent } from './pages/tools/locations/locations.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  // {
  //   path: '',
  //   component: BulkActionsComponent,
  //   pathMatch: 'full'
  // },
  {
    path: 'usersList',
    component: UserlistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'usersList/user/:primaryEmail', //set to userslist/user as we are calling the route from within the userslist page
    component: UserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'userCreate',
    component: UserCreatePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'groupsList',
    component: GroupsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'groupsList/googlegroupedit/:groupID', 
    component: GroupEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'groupsList/jumpcloudgroupedit/:groupID', 
    component: JumpcloudGroupEditPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reassignAdobeLicense',
    component: AdobeReassignComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bulkActions',
    component: BulkActionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tools/signatureTemplates',
    component: SignaturesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tools/orgnaisations',
    component: OrganisationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tools/locations',
    component: LocationsComponent,
    canActivate: [AuthGuard]
  },
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
