<p-dialog header="Add {{userData.fullName}} to a group" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <div>
        <div class="container-fluid">
            <div class="form-group row">
                <div class="col-sm">
                <app-group-selector [groupsList]="groupsList" (selectedGroup)="groupSelected($event)"></app-group-selector>
                </div>
            </div>
            
        </div>
    </div>
        <ng-template pTemplate="footer">
            <button type="button" class="btn btn-secondary" (click)="closeDisplay()">Cancel</button>
            <button type="button" class="btn btn-primary" (click)='addUserToGroup()'>Add</button>
        </ng-template>
</p-dialog>
