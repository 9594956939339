<p-dialog 
    header="Add a delegate to {{userData.fullName}}" 
    [(visible)]="displayModal" 
    [modal]="true" 
    [style]="{width: '50vw', height: '60vh'} " 
    [draggable]="false" 
    [resizable]="false">
    <div>
        <div class="container-fluid">
            <div class="form-group row">
                <div class="col-sm">
      
                  <!-- <user-dropdown-component [item]="item" (afterChange)="delegateSelected($event)"></user-dropdown-component> -->
                    <app-user-dropdown-searchable  [item]="item" (afterChange)="delegateSelected($event)"></app-user-dropdown-searchable>
                </div>
            </div>
            
        </div>
    </div>
        <ng-template pTemplate="footer">
            <button type="button" class="btn btn-secondary" (click)="closeDisplay()">Cancel</button>
            <button class="btn btn-primary btn-sm" (click)="addDelegate()" >Grant</button>
        </ng-template>
</p-dialog>


