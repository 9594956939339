

    <div class="spacer"></div>

<ng-template #loading let-user>Getting Info... </ng-template>

  <div *ngIf="userData.userData; else loading"> 
        <img  class="rounded-circle" height="50" width="50" referrerpolicy="no-referrer" 
        src={{userData.userData.thumbnailPhotoUrl}} onerror="this.src = 'assets/default_user.png'"  />
        <br>
     
    
    <form #form="ngForm" class="form-inline" (ngSubmit)="submitForm(form)"> 
    <input type='text' name='email' id='email' value={{userData.userData.primaryEmail}} hidden>
    <input type='text' name='jc_user_id' id='jc_user_id' value='' hidden>
    <div id='userinfo'>
       
            <div class="form-group  row">
                <label for="primaryEmail" class="col-sm-2 col-form-label  form-control-sm">Primary Email: </label>
                <div class="col-sm-10">
                <input type='text' id='primaryEmail'
                    value={{userData.userData.primaryEmail}} class="form-control-plaintext  form-control-sm ">
                </div>
            </div>
            <div class="form-group  row">
                <label for="lastLogin" class="col-sm-2 col-form-label  form-control-sm">Last Login Time: </label>
                <div class="col-sm-10">
                <input type='text' id='lastLogin'
                    value="{{userData.userData.lastLoginTime | date:'full'}}" class="form-control-plaintext  form-control-sm ">
                </div>
            </div>
            <div class="form-group  row">
                <label for="twofactor" class="col-sm-2 col-form-label  form-control-sm">Two Factor Enabled: </label>
                <div class="col-sm-10">
                <input type='text' id='twofactor'
                    value={{userData.userData.isEnrolledIn2Sv}} class="form-control-plaintext form-control-sm ">
                </div>
            </div>

        <div class="form-group  row">
            <label for="givenName" class="col-sm-2 col-form-label  form-control-sm">Given Name: </label>
            <div class="col-sm-10">
            <input type='text' id='givenName' name="givenName" required [(ngModel)]="userData.userData.name.givenName" 
                 class="form-control form-control-sm ">
            </div>
        </div>
        <div class="form-group  row">
            <label for="familyName" class="col-sm-2 col-form-label  form-control-sm">Family Name: </label>
            <div class="col-sm-10">
            <input [(ngModel)]="userData.userData.name.familyName" required type='text' id='familyName' name="familyName"
                class="form-control form-control-sm ">
            </div>
        </div>
        <div class="form-group  row">
            <label for="userGender" class="col-sm-2 col-form-label  form-control-sm">Gender: </label>
            <div class="col-sm-4">
                <select id="userGender" required class="form-select form-select-sm"[(ngModel)]="userData.userData.gender.type" name="userGender" aria-label="Gender">
                <option value='unknown' selected="selected">Rather Not Say</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Custom</option>
            </select>
            </div>
            <label for="userGenderCustom" class="col-sm-2 col-form-label  form-control-sm">Custom: </label>
            <div class="col-sm-4">
                <input [(ngModel)]="userData.userData.gender.customGender"
                disabled="true"
                class="form form-control form-control-sm" type='text' id='userGenderCustom' name="userGenderCustom"
                >
            </div>
        </div>
        
        <div class="form-group  row">
            <label for="addressMeAs" class="col-sm-2 col-form-label  form-control-sm">Addres me as: </label>
            <div class="col-sm-10">
                <input [(ngModel)]="userData.userData.gender.addressMeAs"
                class="form form-control form-control-sm" type='text' id='addressMeAs' name="addressMeAs"
                >
            </div>
        </div>

        <span class="section-header">Organisation</span>
        <div class="form-group  row">
            <label for="userTitle" class="col-sm-2 col-form-label  form-control-sm">Title: </label>
            <div class="col-sm-10">
                <input [(ngModel)]="userData.userData.organizations[0].title" required
                class="form form-control form-control-sm" type='text' id='userTitle' name="userTitle"
                >
            </div>
        </div>
        <div class="form-group  row">
            <label for="userEmployeeType" class="col-sm-2 col-form-label  form-control-sm">Employee Type: </label>
            <div class="col-sm-10">
                <input [(ngModel)]="userData.userData.organizations[0].description"
                class="form form-control form-control-sm" type='text' id='userEmployeeType' name="userEmployeeType"
                >
            </div>
        </div>
        <div class="form-group  row">
            <label for="userDepartment" class="col-sm-2 col-form-label  form-control-sm">Department: </label>
            <div class="col-sm-10">
                <input [(ngModel)]="userData.userData.organizations[0].department"
                class="form form-control form-control-sm" type='text' id='userDepartment' name="userDepartment"
                >
            </div>
        </div>
        <div class="form-group  row">
            <label for="userGoogleOrgUnit" class="col-sm-2 col-form-label  form-control-sm">Google Org Unit: </label>
            <div class="col-sm-10">
                <select id="userGoogleOrgUnit" class="form-select form-select-sm"  required
                [(ngModel)]="userData.userData.orgUnitPath" 
                name="userGoogleOrgUnit" aria-label="Gender">
                <!-- this should autofill from the client org units. -->
               
                <option *ngFor="let org of googleOrgs.organizationUnits" [ngValue]='org.orgUnitPath' >
                    <ng-container *ngIf="org.description != '' ;else showOrgName"> {{org.name}} : {{org.description}}</ng-container>
                    <ng-template #showOrgName>{{org.name}}</ng-template>
                </option>
                
            </select>
            </div>
        </div>
        <span class="section-header">Address</span>
       
        <div class="form-group  row auto-fill">
            <div class="col-sm-2 col-form-label "></div>
            
            <div class="col-sm-2 col-form-label  ">AUTO FILL</div>
            <label for="userOrgName" class="col-sm-1 col-form-label  form-control-sm">Org Name: </label>
            <div class="col-sm-3">
                <select id="userOrgName" class="form-select form-select-sm" 
                [(ngModel)]="userData.userData.organizations[0].name"
                (change)="autoFillLocationSelect(($event).target)" 
                name="userOrgName" aria-label="userOrgName">
                <!-- Need to put org names from client object here -->
                <option disabled selected value="">Select Organisation</option>
                <option *ngFor="let org of currentClient.organisations | keyvalue" value='{{org.key}}'>{{org.key}} </option>
             </select>
            </div>
    
            <label for="userLocationName" class="col-sm-1 col-form-label  form-control-sm">Location: </label>
            <div class="col-sm-3">
                <select id="userLocationName" class="form-select form-select-sm" (change)="autoFillAddress(($event).target)" name="userLocationName" aria-label="userLocationName">
                <!-- this should autofill when an organisation is selected -->
                <option disabled selected value="">Select Autofill</option>
                <option *ngFor="let loc of orglocations | keyvalue" [value]='loc.key'>{{loc.key}}</option>
            </select>
            </div>
        
        </div>
        
        <div class="form-group  row">
            <label for="userAddress" class="col-sm-2 col-form-label  form-control-sm">Address: </label>
            <div class="col-sm-10">
                
                <input [(ngModel)]="userData.userData.addresses[0].formatted"
                class="form form-control form-control-sm" type='text' id='userAddress' name="userAddress"
                >
            </div>
        </div>

        <div class="form-group  row">
            <label for="userStreetAddress" class="col-sm-2 col-form-label  form-control-sm">Street Address: </label>
            <div class="col-sm-10">
                
                <input [(ngModel)]="userData.userData.addresses[0].streetAddress"
                class="form form-control form-control-sm" type='text' id='userStreetAddress' name="userStreetAddress"
                >
            </div>
        </div>

        <div class="form-group  row">
            <label for="userExtendedAddress" class="col-sm-2 col-form-label  form-control-sm">Extended Address: </label>
            <div class="col-sm-10">
                
                <input [(ngModel)]="userData.userData.addresses[0].extendedAddress"
                class="form form-control form-control-sm" type='text' id='userExtendedAddress' name="userExtendedAddress"
                >
            </div>
        </div>

        <div class="form-group  row">
            <label for="userLocality" class="col-sm-2 col-form-label  form-control-sm">Locality: </label>
            <div class="col-sm-10">
                
                <input [(ngModel)]="userData.userData.addresses[0].locality"
                class="form form-control form-control-sm" type='text' id='userLocality' name="userLocality"
                >
            </div>
        </div>

        <div class="form-group  row">
            <label for="userRegion" class="col-sm-2 col-form-label  form-control-sm">Region: </label>
            <div class="col-sm-10">
                
                <input [(ngModel)]="userData.userData.addresses[0].region"
                class="form form-control form-control-sm" type='text' id='userRegion' name="userRegion"
                >
            </div>
        </div>
        
        <div class="form-group  row">
            <label for="userPostalCode" class="col-sm-2 col-form-label  form-control-sm">Postcode: </label>
            <div class="col-sm-10">
                
                <input [(ngModel)]="userData.userData.addresses[0].postalCode"
                class="form form-control form-control-sm" type='text' id='userPostalCode' name="userPostalCode"
                >
            </div>
        </div>

         
        <div class="form-group  row">
            <label for="userCountry" class="col-sm-2 col-form-label  form-control-sm">Country: </label>
            <div class="col-sm-10">
                
                <input [(ngModel)]="userData.userData.addresses[0].country"
                class="form form-control form-control-sm" type='text' id='userCountry' name="userCountry"
                >
            </div>
        </div>


        <ng-container *ngFor="let phone of userData.userData.phones ">
        <div class="form-group  row" *ngIf="phone.type == 'work' ">
            <label for="userWorkPhone" class="col-sm-2 col-form-label  form-control-sm">Work Phone: </label>
            <div class="col-sm-10">
                
                <input [(ngModel)]="phone.value"
                class="form form-control form-control-sm" type='text' id='userWorkPhone' name="userWorkPhone"
                >
            </div>
        </div>
        <div class="form-group  row" *ngIf="phone.type == 'mobile' ">
            <label for="userMobilePhone" class="col-sm-2 col-form-label  form-control-sm">Mobile Phone: </label>
            <div class="col-sm-10">
                
                <input [(ngModel)]="phone.value"
                class="form form-control form-control-sm" type='text' id='userMobilePhone' name="userMobilePhone"
                >
            </div>
        </div>
    </ng-container>
    <div class="form-group  row">
        <label for="userWebsite" class="col-sm-2 col-form-label  form-control-sm">Website: </label>
        <div class="col-sm-10">
            
            <input [(ngModel)]="userData.userData.websites[0].value"
            class="form form-control form-control-sm" type='text' id='userWebsite' name="userWebsite"
            >
        </div>
    </div> 
    <div class="spacer"></div>
    <div class="form-group  row">
        <div class="col-sm-1"> 
            <button class="btn btn-sm  btn-danger" [routerLink]="'/usersList'">Cancel</button>
            </div>
        <div class="col-sm-1"> 
    <button type="submit" class="btn btn-sm btn-success">Update</button>
    </div>
    
</div>  
    <!-- {{userData.userData | json}} -->
    </div>
</form>
    


    