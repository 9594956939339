import { Component, OnInit } from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';
import { GlobalConstants } from './common/global_constants';
import {delay} from 'rxjs/operators';
import { LoadingService } from './services/loading.service';
import { ClientRepository } from './Repositories/client.repository';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(public auth: AuthService,  
    private _loading: LoadingService , 
    private clientRepo: ClientRepository,
    private _router: Router) {}
    loading: boolean = false;
    appIsLoaded: boolean = false;

  ngOnInit(): void {
    this.listenToLoading();
    if ( ! this.appIsLoaded) {
      this._router.navigateByUrl("/");
      this.appIsLoaded = true;
    }
    
  }

  ngOnViewInit() {
    // change back to the home page:
    this._router.navigateByUrl("/");
  }

/**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
 listenToLoading(): void {
  this._loading.loadingSub
    .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
    .subscribe((loading) => {
      this.loading = loading;
    });
}

}

