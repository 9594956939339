<div class="spacer"></div>
<div *ngIf="userData.userData; else loading">
    <div class="container-fluid">
        <span class="section-header">Aliases</span>
        <div>
            <ul class="list-group">
                <li *ngFor="let alias of userData.userData.aliases; let j = index; trackBy:trackByFn" class="list-group-item">
                    <div class=" row">

                        <div class="col-sm-4">
                            <input readonly [(ngModel)]="userData.userData.aliases[j]" 
                            class="form form-control form-control-sm"
                                type='text' name="aliases{{j}}" id="aliases{{j}}">
                        </div>
                        <div class="col-sm-1">
                            <button class="btn btn-sm btn-danger" (click)="deleteAlias( j )">Delete</button>

                        </div>

                    </div>

                    <!-- <ng-container *ngIf="checkSendAs(alias)">{{checkSendAs(alias).displayName}}</ng-container> -->
                </li>
                <li *ngFor="let al of newAliases; let k = index; trackBy:trackByFn" class="list-group-item">
                    <div class="row">

                        <div class="col-sm-4">
                            <input [(ngModel)]="newAliases[k]" class="form form-control form-control-sm" type='text'>
                        </div>
                        <div class="col-sm-1">

                            <button class="btn btn-sm btn-success" (click)="saveAlias( k )">Save</button>

                        </div>

                    </div>
                </li>
            </ul>
            <button class="btn btn-sm btn-primary" (click)="addAlias()">New Alias</button>
        </div>
        <div class="spacer"></div>
        <p-divider></p-divider>
        <span class="section-header">Send As</span>
        <div>
            <ul class="list-group">
                <li class="list-group-item listHeader">
                    <div class="row">
                        <div class="col-sm-4">Send As Email</div>
                        <div class="col-sm-4">Reply to Address</div>
                        <div class="col-sm-3">Display Name</div>
                        <div class="col-sm-1"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div>
            <ul class="list-group">

                <li *ngFor="let sendas of userData.sendAs; let i=index" class="list-group-item">
                    <div class="form-group  row">
                        <div class="col-sm-4">
                            <input readonly  [(ngModel)]="userData.sendAs[i].sendAsEmail"
                                class="form form-control form-control-sm" id="sendAs{{i}}" >
                        </div>

                        <div class="col-sm-4">
                            <input [(ngModel)]="userData.sendAs[i].replyToAddress"
                                class="form form-control form-control-sm" id="replyTo{{i}}">
                        </div>

                        <div class="col-sm-3">
                            <input [(ngModel)]="userData.sendAs[i].displayName"
                                class="form form-control form-control-sm">
                        </div>
                        <div class="col-sm-1">
                            <ng-container *ngIf="userData.sendAs[i].sendAsEmail != userData.userData.primaryEmail">
                                <button class="btn btn-sm btn-danger" (click)="deleteSendAs( i )">Delete</button>
                            </ng-container>
                        </div>

                    </div>
                </li>
                <li *ngFor="let sendas of newSendAsArray; let i=index" class="list-group-item">
                    <div class="form-group  row">
                        <div class="col-sm-4">
                            <input [(ngModel)]="newSendAsArray[i].sendAsEmail"
                                class="form form-control form-control-sm" id="sendAs{{i}}"
                                (focusout) ="updateReplyTo(i)">
                        </div>

                        <div class="col-sm-4">
                            <input [(ngModel)]="newSendAsArray[i].replyToAddress"
                                class="form form-control form-control-sm" id="replyTo{{i}}"
                                >
                        </div>

                        <div class="col-sm-3">
                            <input [(ngModel)]="newSendAsArray[i].displayName"
                                class="form form-control form-control-sm">
                        </div>
                        <div class="col-sm-1">
                            
                            <button class="btn btn-sm btn-success" (click)="saveSendAs( i )">Save</button>
                            
                        </div>

                    </div>
                </li>
            </ul>
            <button class="btn btn-sm btn-primary" (click)="addSendAs()">New Send As</button>
        </div>
    </div>
</div>

<!-- {{userData.userData.aliases| json}}
{{userData.sendAs| json}} -->

<!-- display loading  -->
<ng-template #loading let-user>Getting Info... </ng-template>