<p-dialog header="Password Reset" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
[draggable]="false" [resizable]="false">
<form>
  <div class="form-group">
    <label for="passwordResetEmail">Email address</label>
    <input readonly [(ngModel)]="resetPasswordEmail" type="email" class="form-control" id="passwordResetInputEmail" name="passwordResetInputEmail" aria-describedby="emailHelp" placeholder="Enter email">
      
    
  </div>
  <div class="form-group">
    <label for="passwordResetInputPassword">Password</label>
    <input  [(ngModel)]="resetPasswordPassword" class="form-control" id="passwordResetInputPassword"  name="passwordResetInputPassword" 
    minlength="8" 
    required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{1,}"
    #password="ngModel"  
    placeholder="Password">
      <small id="passwordHelp" class="form-text text-muted">Password will be copied to clipboard on reset</small>
  </div>
  <!-- Warning Alert -->
  <div *ngIf="password.invalid && (password.dirty || password.touched)"
   class="alert alert-warning ">
   <div *ngIf="password.errors?.['pattern']">
     Invalid password, at least 1 upper, 1 lower and 1 numeral
   </div>
   <div *ngIf="password.errors?.['minlength']">
     password must be at least 8 characters long.
   </div>
   <div *ngIf="password.errors?.['required']">
     Password is required.
   </div>
 </div>

  
</form>

<ng-template pTemplate="footer">
  <button type="button" class="btn btn-secondary" (click)="closeDisplay()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)='resetPassword()'>Reset</button>
  </ng-template>
</p-dialog>
 
 
