import { Component, OnInit } from '@angular/core';
import { ocUser } from 'src/app/models/ocUser.model';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { ocUserRepository } from 'src/app/Repositories/ocUser.repository';


@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements OnInit {
  

  constructor(
    private _clientRepository: ClientRepository,
    private _ocRepository: ocUserRepository
  ) { }

  ngOnInit(): void {
    
  }

  get loggedInUser() {
    return this._ocRepository.getLoggedInUser();
  }

  get firstLoad() {
    return this._clientRepository.getFirstLoad();
  }
}
