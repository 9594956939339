import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { ClientDataService } from "../dataservices/client-dataservice";
import { ClientRepository } from "../Repositories/client.repository";
import { GoogleUserDataService } from "../dataservices/google-user-dataservice";
import { UserRepository } from "../Repositories/user.repository";
import { JumpcloudDataService } from "../dataservices/jumpcloud-dataservice";
import { googleGroupsDataService } from "../dataservices/google-info.dataservice";
import { googleInfoRepository } from "../Repositories/googleInfo.repository";
import { filtersStaticData } from "../dataservices/filiters.static";
import { staticUserData } from "../dataservices/userData.static";
import { WeatherAPI } from "../dataservices/weather-api.dataservice";
import { WeatherRepository } from "../Repositories/weather.repository";
import { AstronomyDataService } from "../dataservices/astronomy.dataservice";
import { AstronumyRepository } from "./astronomy.repository";
import { GoodNewsDataService } from "../dataservices/goodnews.dataservice";
import { adobeDataService } from "../dataservices/adobe.dataservice";
import { ocUser } from "./ocUser.model";


@NgModule ({
    imports: [HttpClientModule],
    providers: [ 
        ClientDataService,
        ClientRepository,
        GoogleUserDataService,
        UserRepository,
        JumpcloudDataService,
        googleGroupsDataService,
        googleInfoRepository,
        filtersStaticData,
        staticUserData,
        WeatherAPI,
        WeatherRepository,
        AstronomyDataService,
        AstronumyRepository,
        GoodNewsDataService,
        adobeDataService,
        ocUser
        ],
    declarations: [

    ]

})

export class ModelModule { }