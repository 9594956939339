import { group } from "./group.model";

export class DomainsAndGroups {
    domains: clientDomain[];
    groups: group[];

}

export class clientDomain {
    domainName: string;
    isPrimary: boolean;
    verified: boolean;
    domainAliases: clientDomainAlias[];
}

export class clientDomainAlias {
    domainAliasName: string;
    parentDomainName: string;
    verified: boolean;
}