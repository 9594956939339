import { Injectable, Optional, SkipSelf} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global_constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { utilities } from '../utilities/utilities';
import { ClientRepository } from '../Repositories/client.repository';

@Injectable({
  providedIn: 'root'
})
export class JumpcloudUsersService {
  private all$: BehaviorSubject<any> = new BehaviorSubject(null);
  private currentlySelectedName$: BehaviorSubject<any> = new BehaviorSubject(null);
  private currentlySelectedData: BehaviorSubject<any> = new BehaviorSubject(null);
  currentClientData;

  constructor(
   private http: HttpClient,
    private util: utilities,
    private _clientRepository: ClientRepository,
    @Optional() @SkipSelf() sharedService?: JumpcloudUsersService
  ) {

    if (sharedService) {
      throw new Error('JumpcloudUsersService is already loaded');
    }
    this.util.debug('JumpcloudUsersService created');
    this.getAll();
  }

  private getAll(source: string = "Class Constructor") {
    // this._client_list_service.getCurrentlySelectedClientData().subscribe(
    //   data => {
    //     this.currentClientData = data;
    //     if (data != undefined && data != "") {
    //       if (this.currentClientData.usejc) {
    //         // get the jumpcloud users!
    //         this.http.get(GlobalConstants.apiURL + "users/jumpcloud/?client="
    //         + this.currentClientData.client
    //         + '&usejc=false&search=""&skip=""').subscribe( data => {
    //             this.util.debug("Getting Jumpcloud Users");
    //             this.util.debug(data);
    //             this.all$.next(data);
    //         });
    //       }
         
    //     }
    //   }
    // )
    this.util.debug("UsersService.getAll called by " + source);
      // users/google/?client=Dazed&usejc=false&search=""&skip=""')
  }

  getJumpCloudUsers( ) {
    return this.all$.asObservable();
  }
}
