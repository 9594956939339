

<div class="card">

<p-dialog header="Group Members" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <ng-container *ngIf="groupMembers.length > 0; else loadingGroups">
    <ul>
        <li *ngFor="let member of groupMembers">
            {{member.email}}
        </li>
    </ul>
    </ng-container>
    <ng-template #loadingGroups>
         ..... loading
    </ng-template>
        <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displayModal=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
</p-dialog>

<!-- {{clientName}} -->

    <p-table #dt2 [value]="groupsList" rowGroupMode="subheader" 
    selectionMode="multiple" [columns]="cols"
    groupRowsBy="name" sortField="name" sortMode="single"  
    [globalFilterFields]="['name','aliases','description']" 
    [tableStyle]="{'min-width': '75rem', 'font-size':'12px' } "
    styleClass="p-datatable-sm p-datatable-striped" 
    [scrollable]="true" scrollHeight="500px">
    <ng-template pTemplate="caption">
        <div class="row" > 
          <div class="col-sm-8">
            <span class="p-input-icon-right">
  
              <input #searchInput pInputText type="text" 
              (input)="dt2.filterGlobal(searchInput.value, 'contains')"
                placeholder="Search keyword" />
              <i class="pi pi-search"></i>
            </span>
          </div>
          <div class="col-sm-4">
            <button type="button" pButton pRipple icon="pi pi-file" 
              (click)="dt2.exportCSV()" class="button-blue"
              pTooltip="CSV" tooltip Position="bottom" style="float:right"></button>
              <button type="button" pButton pRipple icon="pi pi-file-pdf" 
              (click)="exportPdf()" style="float:right" class="button-orange" 
              pTooltip="PDF" tooltipPosition="bottom"></button>
          </div>
        </div>
      </ng-template>
  
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Email / Aiases</th>
                <th>Direct Members</th>
                <th>Description</th>
               
                
            </tr>
        </ng-template>
        <ng-template pTemplate="groupheader" let-group>
            <tr pRowGroupHeader *ngIf="group.name !='Classroom Teachers' " 
            (click)="editGroup(group.id)">
            
            <td colspan="1">
                    
                    <span class="font-bold ml-2">{{group.name}}</span>
                </td>
                <td>
                    {{group.email}}
                </td>
                <td>
                    <span class="image-text">
                        {{group.directMembersCount}}
                    </span>
                </td>
                <td>
                    {{group.description}}
                </td>
               
                
               
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-group>
            <ng-container *ngIf="group.aliases">
                <ng-container *ngFor="let tg of group.aliases">
                    <tr class="row-highlight">
                
                        <td colspan="1">
                        </td>
                       
                        <td>
                            {{tg}}
                        </td>
                        <td colspan="2">
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            
        </ng-template>
    </p-table>
</div>


