<div class="spacer"></div>
<div *ngIf="userData.userData; else loading">
    <div class="container-fluid">
        <div class="form-group row">
            <div class="col-sm smallText">Add {{userData.userData.name.fullName}} to Group</div>
            <div class="col-sm">
            <app-group-selector [groupsList]="groupsList" (selectedGroup)="groupSelected($event)"></app-group-selector>
            </div>
            <div class="col-sm-1">
            <button class="btn btn-primary btn-sm" (click)="addUserToGroup()" >Add</button>
            </div>
            <div class="col-sm">
                
                </div>
            </div>
    </div>
    <div class="spacer"></div>
    <p-divider></p-divider>
    <div class="container-fluid">
        <div class="row  smallText">
            <p>{{userData.userData.name.fullName}} is a member of the following groups</p>
        </div>
        <app-users-groups-list [userData]="userData" (selectedGroup)="removeUserFromgroup($event)"></app-users-groups-list>

    </div>

</div>


<ng-template #loading>Getting Info... </ng-template>
