// my-loader.component.ts
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-my-loader',
  templateUrl: './my-loader.component.html',
  styleUrls: ['./my-loader.component.css']
})
export class MyLoaderComponent implements OnInit {
  constructor() {
   
  }
  ngOnInit() {
  }

}
