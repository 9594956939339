import { LeadingComment } from "@angular/compiler";
import { Injectable,Optional, SkipSelf} from "@angular/core";
import { googleGroupsDataService } from "../dataservices/google-info.dataservice";
import { Client } from "../models/client.model";
import { DomainsAndGroups } from "../models/domains-and-groups.model";
import { clientDomain } from "../models/domains-and-groups.model";
import { group} from "../models/group.model";
import { groupMember, groupMembers } from "../models/groupMembers.model";
import { UserRepository } from "./user.repository";
import { TreeNode } from "primeng/api";


@Injectable({
    providedIn: 'root'
})
export class googleInfoRepository {
 private groupsAndDomains: DomainsAndGroups = new DomainsAndGroups();
 private _client;
 private _domains: clientDomain[] = [];
 private _groups: group[] = [];
 private _userRepository: UserRepository;
 private _primaryDomainName: string; 
 private _groupsLoaded: boolean = false;
 private _groupInfo: groupMembers = new groupMembers; // initialse with an empty object so we don't get undefined errors whilst waiting for the data!
 private groupMembersTreeMap: TreeNode[];

 constructor (
    private _googleGroupsDataService: googleGroupsDataService
 ) {}

   setClient(client) {
      this._client = client;
   } 

 getGroupsAndDomains() :DomainsAndGroups{
    return this.groupsAndDomains;
 }

 getGroupAliasesFromID(groupID) {
   
   return this.groupsAndDomains.groups.find(group => group.id == groupID).aliases;

 }

 getFromServer(client: string) {
   //this._client = client;
   
    this._googleGroupsDataService.getGroupsAndDomains(this._client).subscribe(data => {
        this.groupsAndDomains = data;
         this._domains = data.domains;
         this._groups = data.groups.sort((a,b) => a.id - b.id );
         let n = this._domains.length;
         for (let i=0 ; i < n ; i++ ) {
            if (this._domains[i].isPrimary)  {
               this._primaryDomainName = this._domains[i].domainName;
            }
         } 
         for (let g=0; g<this._groups.length; g++ ) {
            this._groups[g].id = this._groups[g].email;
         }

         this._groupsLoaded = true;
        console.log(data);
    })
 }

 getDomains()  {
    return this._domains;
 }

 getGroups() {
   //if (this.groupsAndDomains.groups) 
   return this._groups;
 }

getGroupMembersByID(groupID) {
   this._googleGroupsDataService.getGroupMembersByID(this._client, groupID).subscribe(data => {
      this._groupInfo = data.body;
         console.log(data.body);
      // now create the treemap!;

      data.body.members.forEach( member => {
        this.groupMembersTreeMap.push(member);
      });
   });
}

getGroupMemberInfo(member) {
   if (member.type = "USER" ) {
      // we want to map it here
      let name = this._userRepository.getUserByEmail(member.email).name.fullName;
      member.label = name;
     member.name = name;
      member.icon = this._userRepository.getUserByEmail(member.email).thumbnailPhotoUrl;
      return member;
   } else {
      // if it's a group and has children we need to add it and map the children.
     let name = this._groups.find(group => group.email == member.email);
     member.label = name;
     member.name = name;
     member.children.foreach(child => {
         //do nothing yet !
     });
   }
}

getGroupMembers() {
   return this._groupInfo;
}

getGroupMembersTreeMap() {
    /// create mapped heirarchy for label tree display 
    let result = [];
    let level = { result };
   this._groupInfo.members.forEach( member=> {

   })
}

 getPrimaryDomainName() {
   return this._primaryDomainName;
 }

 public get groupsLoaded() {
   return this._groupsLoaded;
 }




}
