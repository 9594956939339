import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global_constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { utilities } from '../utilities/utilities';
import { Client } from '../models/client.model';

@Injectable()
export class googleGroupsDataService{
    constructor( 
        private http: HttpClient,
        private util: utilities
    ) {
        this.util.debug('GoogleGroupsDataService created');
    }

    getGroupsAndDomains(client: Client) {
        let url = GlobalConstants.apiURL + "groups?client=" + client.client;
        url += "&domain=" + client.primary_domain;
        return this.http.get<any>(url);
    }

    getGroupMembersByID(client: Client, groupID:string) {
        // This calls a lambda function to get the google groups.
        let url = GlobalConstants.apiURL + "groups/members/?client=" + client.client + "&groupEmail=" + groupID;
        return this.http.get<any>(url);
    }

    addAlias(client: string, groupID: string, alias: string) {
        let sendObj = {
            client: client,
            id: groupID,
            alias: alias
          }
      
          let url= GlobalConstants.apiURL + "groups/alias";
          return this.http.post<any>(url,sendObj, {responseType: 'json'});
    }

    deleteAlias(client: string, groupID: string, alias: string) {
        let body = {
            client: client,
            id: groupID,
            alias: alias
          }
      
          let url= GlobalConstants.apiURL + "groups/alias";
          return this.http.delete<any>(url,{responseType: 'json', body: body} );
    }
}

