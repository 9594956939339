import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-drop-down-email-selector',
  templateUrl: './drop-down-email-selector.component.html',
  styleUrls: ['./drop-down-email-selector.component.css'],

})
export class DropDownEmailSelectorComponent implements OnInit {
  @Input() userList;
  @Input() id;
  @Input() groupName: FormGroup;
  @Output() selectedUser: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  emitGroup(t){
    this.selectedUser.emit(t);
  }
}


