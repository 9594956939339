import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { HttpClientModule } from '@angular/common/http';
import {HttpClientJsonpModule} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';

// Import the auth0 module from the SDK
import { AuthModule } from '@auth0/auth0-angular';

// Import Components
import { NavBarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthButtonComponent } from './components/auth-button/auth-button.component';
import { LogoutButtonComponent } from './components/logout-button/logout-button.component';
import { AuthNavComponent } from './components/auth-nav/auth-nav.component';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { NavClientSwitchComponent } from './components/nav-client-switch/nav-client-switch.component';
import { MyLoaderComponent } from './components/my-loader/my-loader.component';

// Import pages:
import { HomeComponent } from './pages/home/home.component';
import { UserlistComponent } from './pages/user-list.page/user-list.page';
import { UserComponent } from './pages/user-edit.page/user-edit.page';

//import services:
import { ocUserDataService } from './dataservices/ocUsers.dataservice';
// import { client_list_service } from './services/client_service';
import { GoogleGroupsServiceService } from './services/google-groups-service.service';
import { GroupsComponent } from './pages/groups-list.page/groups.component';
import { UsersService } from './services/google-users.service';
import { DirectorySelectorComponent } from './components/directory-selector/directory-selector.component';
import { UserTableComponent } from './components/user-table/user-table.component';
import { GoogleUserService } from './services/google-user.service';
import { GroupsTableComponent } from './components/groups-table/groups-table.component';
import { LoadingService } from './services/loading.service';
import { HttpRequestInterceptor } from './interceptors/http-request-interceptor.service';
import { UserPageUserComponent } from './components/user-info-tabs/user-info-tab/user-info.tab';
import { SearchDropdown } from './components/dropdown-search-custom-selector/search-dropown';
import { UserDropdownComponent } from './components/dropdown-user-selector/user-dropdown.component';

import { ModelModule } from './models/model.module';
import { AliasesComponent } from './components/user-info-tabs/aliases-tab/aliases.tab';
import { UserCreatePageComponent } from './pages/user-create.page/user-create.page';

// import helpers

import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import { ResetPasswordComponent } from './components/modals/reset-password/reset-password.component';
import { SuspendAcitvateUserComponent } from './components/modals/suspend-acitvate-user/suspend-acitvate-user.component';;

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddUserToGroupComponent } from './components/modals/add-user-to-group/add-user-to-group.component';
import { GroupSelectorComponent } from './components/dropdown-group-selector/group-selector.component';
import { GroupsTabComponent } from './components/user-info-tabs/groups-tab/groups-tab.component';
import { UsersGroupsListComponent } from './components/users-groups-list/users-groups-list.component';
import { DelegatesTabComponent } from './components/user-info-tabs/delegates-tab/delegates-tab.component';
import { SignatureTabComponent } from './components/user-info-tabs/signature-tab/signature-tab.component';
import {DividerModule} from 'primeng/divider';
import {PanelModule} from 'primeng/panel';
import { DropdownSignatureTemplatesComponent } from './components/dropdown-signature-templates/dropdown-signature-templates.component';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Safe } from './utilities/pipe-sanitizer';
import { VacationTabComponent } from './components/user-info-tabs/vacation-tab/vacation-tab.component';
import { ForwardingTabComponent } from './components/user-info-tabs/forwarding-tab/forwarding-tab.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import {CalendarModule} from 'primeng/calendar';
import {EditorModule} from 'primeng/editor';
import { convertUnixToDate } from './utilities/convertUnixTStoDate.pipe';
import { GroupEditComponent } from './pages/group-edit/group-edit.component';
import { LabelsComponent } from './components/user-info-tabs/labels-tab/labels.component';
import { FiltersComponent } from './components/user-info-tabs/filters-tab/filters.component';
import {TreeModule} from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import {TreeNode} from 'primeng/api';
import { FiltersListDisplayComponent } from './components/user-info-tabs/components/filters-list-display/filters-list-display.component';
import { LabelsListDropdownComponent } from './components/user-info-tabs/components/labels-list-dropdown/labels-list-dropdown.component';
import { WeatherComponent } from './components/weather/weather.component';
import { GoodNewsComponent } from './components/good-news/good-news.component';
import { AdobeReassignComponent } from './pages/adobe-reassign/adobe-reassign.component';
import { DropDownEmailSelectorComponent } from './components/drop-down-email-selector/drop-down-email-selector.component';
import { JumpcloudGroupEditPageComponent } from './pages/jumpcloud-group-edit-page/jumpcloud-group-edit-page.component';
import { BulkActionsComponent } from './components/bulk-actions/bulk-actions.component';
import { Toast } from 'bootstrap';
import { DelegateUserComponent } from './components/modals/delegate-user/delegate-user.component';
import { UserDropdownSearchableComponentComponent } from './components/user-dropdown-searchable.component/user-dropdown-searchable.component.component';

import { DropdownModule } from 'primeng/dropdown';
import { SignaturesComponent } from './pages/tools/signatures/signatures.component';
import { OrganisationsComponent } from './pages/tools/organisations/organisations.component';
import { LocationsComponent } from './pages/tools/locations/locations.component';
import { UserPreferencesComponent } from './components/modals/user-preferences/user-preferences.component';




@NgModule({
  exports: [
    
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    UserlistComponent,
    NavBarComponent,
    FooterComponent,
    AuthButtonComponent,
    LogoutButtonComponent,
    AuthNavComponent,
    MainNavComponent, 
    NavClientSwitchComponent,
    GroupsComponent,
    DirectorySelectorComponent,
    UserTableComponent,
    MyLoaderComponent,
    UserComponent,
    GroupsTableComponent,
    UserPageUserComponent,
    SearchDropdown,
    UserDropdownComponent,
    AliasesComponent,
    UserCreatePageComponent,
    ResetPasswordComponent,
    SuspendAcitvateUserComponent,
    AddUserToGroupComponent,
    GroupSelectorComponent,
    GroupsTabComponent,
    UsersGroupsListComponent,
    DelegatesTabComponent,
    SignatureTabComponent,
    DropdownSignatureTemplatesComponent,
    Safe,
    VacationTabComponent,
    ForwardingTabComponent,
    convertUnixToDate,
    GroupEditComponent,
    LabelsComponent,
    FiltersComponent,
    FiltersListDisplayComponent,
    LabelsListDropdownComponent,
    WeatherComponent,
    GoodNewsComponent,
    AdobeReassignComponent,
    DropDownEmailSelectorComponent,
    JumpcloudGroupEditPageComponent,
    BulkActionsComponent,
    DelegateUserComponent,
    UserDropdownSearchableComponentComponent,
    SignaturesComponent,
    OrganisationsComponent,
    LocationsComponent,
    UserPreferencesComponent,
    

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ModelModule,
    DropdownModule,
    ReactiveFormsModule,
    // Import the module into the application, with configuration
    AuthModule.forRoot({
      domain: 'no-it.eu.auth0.com',
      clientId: 'iYFjmEusrvt7SbXnLKXJnI9NBYfNUpIm',
      audience: 'https://no-it-new-auth',
      httpInterceptor: {
        allowedList: [
          'https://kzcslyz16l.execute-api.eu-west-1.amazonaws.com/Dev/*',
          'https://kzcslyz16l.execute-api.eu-west-1.amazonaws.com/prod/*'
        ],
      }
    }),
    HttpClientModule,
    HttpClientJsonpModule,
    ContextMenuModule,
    DialogModule,
    TableModule,
    ButtonModule,
    BrowserAnimationsModule,
    DividerModule,
    PanelModule,
    InputSwitchModule,
    CalendarModule,
    EditorModule,
    TreeModule,
    TreeTableModule,
    FileUploadModule,
    ToastModule,
    EditorModule

  
  ],
  providers: [
    // client_list_service,
    GoogleGroupsServiceService,
    NavClientSwitchComponent,
    UsersService,
    GoogleUserService,
    ocUserDataService,
    // ClientDataService,
    // ClientRepository,
    LoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
      }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
