<div class="spacer"></div>

<h2 class="blueText">Organisations / Departments</h2>

<div class="container-fluid">

    <div class="form-group row">
        <div class="col-sm-5 smallText">
            <p>Add an Organisasion </p>
        </div>

    </div>
    <ul class="list-group">
        <li class="list-group-item">
            <div class="form-group  row mb-1">
                <label for="forwardingEmail" class="col-sm-2 col-form-label  form-control-sm">Organisation Name:
                </label>
                <div class="col-sm-5">

                    <input [(ngModel)]="newOrganisationName" class="form form-control form-control-sm" type='text'
                        id='orgName' name="orgName">
                </div>
                <div class="col-sm-1">
                    <button class="btn btn-primary btn-sm" (click)="addOrganistion()">Add</button>
                </div>

            </div>
        </li>
    </ul>

    <div class="spacer"></div>


    <div class="container-fluid" *ngIf="currentClient.organisations else noforward">
        <div class="row  smallText">
            <p>Organisations</p>
        </div>
        <ul class="list-group">
            <li class="list-group-item" *ngFor="let org of currentClient.organisations | keyvalue" value='{{org.key}}'>
              
                <div class="form-group  row">
                    <div class="col-sm-4">
                        <input readonly value="{{org.key}}" name="org" class="form form-control form-control-sm">
                    </div>
                    <div class="col-sm-1">

                        <button class="btn btn-sm btn-danger" (click)="editOrgName( org.key )">Edit</button>

                    </div>

                    <div class="col-sm-1">

                        <button class="btn btn-sm btn-danger" (click)="deleteOrg( org.key )">Delete</button>

                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

<ng-template #noforward>
    <div class="row  smallText">
        <p>No Organisations exist for this client.</p>
    </div>
</ng-template>