
 
<ul class="navbar-nav " *ngIf="!firstLoad">
   
    <li class="nav-item dropdown me-auto">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Users
        </a>
        <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#" routerLink="usersList" routerLinkActive="acive-link">List</a></li>
            <div class="dropdown-divider"></div>
            <li><a class="dropdown-item" href="#" routerLink="userCreate" routerLinkActive="acive-link" >Create</a></li>
            <div class="dropdown-divider"></div>
            <li><a class="dropdown-item" routerLink="reassignAdobeLicense" routerLinkActive="acive-link">Assign Adobe Licenses</a></li>
        </ul>
    </li>
    <ul class="nav navbar-nav" >
        <li class="dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Groups
            </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item" href="#" routerLink="groupsList" routerLinkActive="acive-link">List Groups</a></li>
          <div class="dropdown-divider"></div>
          <li><a class="dropdown-item disabled" href="#" >Create</a></li>
        </ul>
      </li>
      </ul>
      <ul class="nav navbar-nav" *ngIf="(loggedInUser.admin>=1)">
        <li class="dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Tools
            </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item" href="#" routerLink="tools/signatureTemplates" routerLinkActive="acive-link">Signature Templates</a></li>
          <li><a class="dropdown-item disabled" href="#" routerLink="bulkActions"  routerLinkActive="acive-link">Bulk Actions</a></li>
          <div class="dropdown-divider"></div>
          <li><a class="dropdown-item " href="#" routerLink="tools/orgnaisations" routerLinkActive="acive-link">Organisations</a></li>
            <li><a class="dropdown-item " href="#" routerLink="tools/locations" routerLinkActive="acive-link">Locations</a></li>
          
          <li><a class="dropdown-item" id='devTestsMenu' href="#" routerLinkActive="acive-link">Development Tests</a></li>
        </ul>
      </li>
      </ul>
        
        <ul class="nav navbar-nav"  *ngIf="(loggedInUser.admin>=2)">
          <li class="dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Admin
            </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item disabled" href="#" routerLinkActive="acive-link">Clients</a></li>
            <li><a class="dropdown-item disabled" href="#" routerLinkActive="acive-link">OC Users</a></li>
            
            <li><a class="dropdown-item disabled" href="#" routerLinkActive="acive-link">Glpi Entities</a></li>
            <div class="dropdown-divider"></div>
            <li><a class="dropdown-item disabled" href="#" routerLinkActive="acive-link">Upload Google Info</a></li>
          <li><a class="dropdown-item disabled" href="#" routerLinkActive="acive-link">Upload Adobe Info</a></li>
          <li><a class="dropdown-item disabled" id="uploadJumpcloudMenuOption"  href="#" routerLinkActive="acive-link">Upload Jumpcloud Info</a></li>
   
          </ul>
        </li>
        </ul>
        
</ul>

 