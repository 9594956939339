import { Input, Output, EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ocUserRepository } from 'src/app/Repositories/ocUser.repository';
import { utilities } from 'src/app/utilities/utilities';
import Modal from "bootstrap/js/src/modal"
import { ocUser } from 'src/app/models/ocUser.model';
import { AuthService } from '@auth0/auth0-angular';
import { ocUserDataService } from 'src/app/dataservices/ocUsers.dataservice';


@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.css']
})
export class UserPreferencesComponent implements OnInit {
  @Input() email: string;
  @Input() displayModal: boolean;
  @Output() resetDisplay: EventEmitter<any> = new EventEmitter();

  loggedInUser: ocUser;
  displayMe: boolean;
  userPrefs: Modal;
  profileJson;


  constructor(
    private userRepo: ocUserRepository,
    private util: utilities,
    public auth: AuthService,
    private userDataService: ocUserDataService
  ) { }

  ngOnInit(): void {
    this.loggedInUser = this.userRepo.getLoggedInUser();
  
  }

  patchPreferences() {
    
    this.util.logError(this.loggedInUser);

    // we talk to the DS direct as we are a modal man
   
    this.userDataService.patchUser(this.loggedInUser).subscribe( data => {
      console.log("returned");
      console.log(data);
      if(data.statusCode == 200) {
        this.util.displayMessageToUser( "Your preferenes have been saved",'',true);
        
      } else {
        this.util.displayMessageToUser( "ERROR !!!!",'',true);
      }
      this.resetDisplay.emit(false);
    })
    
    
  }


  closeDisplay() {
    // tells the parent to close this display ... very clever ;)
    this.resetDisplay.emit(false);
  }

}
