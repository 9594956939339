import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { googleGroupsDataService } from 'src/app/dataservices/google-info.dataservice';
import { googleInfoRepository } from 'src/app/Repositories/googleInfo.repository';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { utilities } from 'src/app/utilities/utilities';
import { listUser } from 'src/app/models/googleUser.model';


@Component({
  selector: 'app-add-user-to-group',
  templateUrl: './add-user-to-group.component.html',
  styleUrls: ['./add-user-to-group.component.css']
})
export class AddUserToGroupComponent implements OnInit {
  @Input() displayModal: boolean;
  @Input() userData: listUser;
  @Input() seldirectoryName: string;
  @Output() resetDisplay: EventEmitter<any> = new EventEmitter();

  selectedGroup;


  constructor(
    private _googleGroupsDataService: googleGroupsDataService,
    private _googleInfoRepository: googleInfoRepository,
    private _clientrepository: ClientRepository,
    private _userRepository: UserRepository,
    private util: utilities

  ) { }

  ngOnInit(): void {
    if(this.seldirectoryName == "Google") {
   
        this._googleInfoRepository.getFromServer(this._clientrepository.getCurrentClientName());
    }
      console.log(this._googleInfoRepository.getGroups());
      console.log(this.userData);
  }

  closeDisplay() {
    // tells the parent to close this display ... very clever ;)
    this.resetDisplay.emit(false);
  }

  get groupsList() {
    return this._googleInfoRepository.getGroups();
  
  }

  groupSelected($event) {
    this.util.debug( "group selected: "+ $event.value);
    this.selectedGroup = $event.value;
  }

  addUserToGroup() {
    this._userRepository.addUserToGoogleGroup(this.selectedGroup, this.userData.primaryEmail);
    this.closeDisplay();
  }
}
