import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global_constants';
import { Observable, BehaviorSubject, catchError } from 'rxjs';
import { utilities } from '../utilities/utilities';
import { HttpHeaders } from '@angular/common/http';
import { Buffer  } from "Buffer";

@Injectable()
export class AstronomyDataService {
    private _id = "71b09264-ba2e-4674-90e4-2c9ba810f6e7";
    private _secret = "5d1975414d2a3cd06becc0483bfc5ec627e24ea0452df94b3a2b7ae80a62b9172b3611e310953d0fc7c1d6ae7a1d8c9955295ab606408b0e2d8a323f4fe91b245b71259ade2ec2edeb2055c7a4eeab359bf995b4a283530133098ea8527645471c9ed444c26f63ed53b6637b981ac4f1";
    private _hash = Buffer.from(`${this._id}:${this._secret}`,'base64');
   

    //private _hdash = btoa (`${this._id}:${this._secret}`);
    constructor(
        private http: HttpClient,
        private util: utilities,
    ) {}

    getMoon():Observable<any> {
        let url = "https://api.astronomyapi.com/api/v2/studio/moon-phase";
        let body={
            format: "png",
            style: {
                moonStyle: "default",
                backgroundStyle: "solid",
                backgroundColor: "white",
                headingColor: "black",
                textColor: "black"
            },
            observer: {
                latitude: 51.5072,
                longitude: 0.0,
                date: new Date().toJSON().slice(0, 10)
            },
            view: {
                type: "landscape-simple",
                orientation: "south-up"
            }
        }
        const headers= new HttpHeaders()
            .set('Authorization', 'Basic '+ btoa(`${this._id}:${this._secret}`));
        return this.http.post<any>(url,body, {headers: headers});
    }

    load_moon_phases(obj){
        var gets=[]
        for (var i in obj){
            gets.push(i + "=" +encodeURIComponent(obj[i]))
        }
        // have to divide by 1000 because javascript is millseconds since epoch, unix is seconds.
        let tempDate = Math.floor(new Date(obj.year,obj.month-1,1).getTime() / 1000);
       
        gets.push("LDZ=" +  tempDate )
        
        var url = "https://www.icalendar37.net/lunar/api/?" + gets.join("&")
        return this.http.get<any>(url);
        

        // xmlhttp.onreadystatechange = function() {
        //     if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        //         callback(JSON.parse(xmlhttp.responseText))
        //     }
        // }
        // xmlhttp.open("GET", url, true)
        // xmlhttp.send()
    }
}
