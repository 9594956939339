import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global_constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { utilities } from '../utilities/utilities';
import { Client } from '../models/client.model';
import { tap } from 'rxjs';

@Injectable({
    providedIn: "root"
})
export class ClientDataService {


    constructor(private http: HttpClient,
        private util: utilities,
        @Optional() @SkipSelf() sharedService?: ClientDataService
    ) {
        if (sharedService) {
            throw new Error('ClientDataService is already loaded');
        }
        this.util.debug('ClientDataService created');

    }
    // get the data from the server. Should be called later 
    // if any client info is updated ( signatures, new client , orgs etc ).
    getAllClients(source: string = "Class Constructor"): Observable<Client[]> {
        this.util.debug("ClientDataService.getAllClients called by " + source);
        return this.http.get<Client[]>(GlobalConstants.apiURL + "clients")
       
    } 

    getGoogleGroups() {

    }

    getGoogleOrgUnits(client) {
        this.util.debug("ClientDataService.getGoogleOrgUnits called");
        let url= GlobalConstants.apiURL + 'org-units?client=' + client;
        return this.http.get<any>(url).pipe(
            tap( // Log the result or error
            {
              error: (error) => this.util.logError(error)
            }
            ));
    }

    patchSignatureTemplate(client,organisation,templateText) {
        let data = {
            client : client,
            name :  organisation,
            template : templateText
        }
        this.util.debug("ClientDataService.patchSignatureTemplate called");
        let url= GlobalConstants.apiURL + "signatures/templates";
        return this.http.patch<any>(url,data, {responseType: 'json'});
    }

    addOrganisation(clientName, organisationName ) {
        let data = {
            client: clientName,
            name: organisationName
          }
    
          let url= GlobalConstants.apiURL + "organisations";
          return this.http.post<any>(url,data, {responseType: 'json'});
    }

    deleteOrganisation(clientName, organisationName) {
        let data = {
            client: clientName,
            name: organisationName
          }
    
          let url= GlobalConstants.apiURL + "organisations";
          return this.http.delete<any>(url, {responseType: 'json', body: data});
    }
}