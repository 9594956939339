<div class="spacer"></div>
<h2>Group {{currentGroup.name}}</h2>
<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="Details">
        <button class="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details" type="button"
            role="tab" aria-controls="info" aria-selected="true">Details</button>
    </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link" id="info-tab" data-bs-toggle="tab" data-bs-target="#aliases" type="button" role="tab"
            aria-controls="info" aria-selected="true">Aliases</button>
    </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link" id="groups-tab" data-bs-toggle="tab" data-bs-target="#members" type="button" role="tab"
            aria-controls="groups" aria-selected="false">Members</button>
    </li>
</ul>

<div class="tab-content" id="myTabContent">
    <div class="tab-pane show active" id="details" role="tabpanel" aria-labelledby="details-tab">
        <div class="spacer"></div>
        <div class="container-fluid">

            <div class="form-group  row">
                <label for="description" class="col-sm-2 col-form-label  form-control-sm">Description: </label>
                <div class="col-sm-10">
                    <input type='text' id='description' [(ngModel)]="currentGroup.description"
                        class="form-control form-control-sm " (keyup)="inputChanged('description')">
                </div>
            </div>
            <div class="form-group  row">
                <label for="email" class="col-sm-2 col-form-label  form-control-sm">Group Email: </label>
                <div class="col-sm-10">
                    <input type='text' id='email' [(ngModel)]="currentGroup.email" class="form-control form-control-sm "
                        (keyup)="inputChanged('email')">
                </div>
            </div>

        </div>
    </div>

    <div class="tab-pane" id="aliases" role="tabpanel" aria-labelledby="info-tab">
        <div class="spacer"></div>
        <div class="container-fluid">

            <div>
                <ul class="list-group">
                    <li *ngFor="let alias of groupInfo; let j = index; trackBy:trackByFn" class="list-group-item">
                        <div class=" row">

                            <div class="col-sm-4">
                                <input readonly [(ngModel)]="groupInfo[j]" class="form form-control form-control-sm"
                                    type='text' name="aliases{{j}}" id="aliases{{j}}">
                            </div>
                            <div class="col-sm-1">
                                <button class="btn btn-sm btn-danger" (click)="deleteAlias( j )">Delete</button>

                            </div>

                        </div>

                        <!-- <ng-container *ngIf="checkSendAs(alias)">{{checkSendAs(alias).displayName}}</ng-container> -->
                    </li>
                    <li *ngFor="let al of newAliases; let k = index; trackBy:trackByFn" class="list-group-item">
                        <div class="row">

                            <div class="col-sm-4">
                                <input [(ngModel)]="newAliases[k]" class="form form-control form-control-sm"
                                    type='text'>
                            </div>
                            <div class="col-sm-1">

                                <button class="btn btn-sm btn-success" (click)="saveAlias( k )">Save</button>
                                
                            </div>

                        </div>
                    </li>
                </ul>
                <button class="btn btn-sm btn-primary" (click)="addAlias()">New Alias</button>
            </div>
        </div>
        <div class="spacer"></div>
        <div class="spacer"></div>
    </div>



    <div class="tab-pane" id="members" role="tabpanel" aria-labelledby="groups-tab">
        <div class="spacer"></div>
        <div class="container-fluid">



            <!-- New secction  -->
            <div class="card">
                <p-table #dt3 [value]="groupMembers.members" rowGroupMode="subheader" selectionMode="multiple"
                    [columns]="cols" groupRowsBy="email" sortField="name" sortMode="single"
                    [globalFilterFields]="['name','email']" [tableStyle]="{'min-width': '75rem', 'font-size':'12px' } "
                    styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="500px">
                    <ng-template pTemplate="caption">
                        <div class="row">
                            <div class="col-sm-8">
                                <span class="p-input-icon-right">

                                    <input #searchInput pInputText type="text"
                                        (input)="dt3.filterGlobal(searchInput.value, 'contains')"
                                        placeholder="Search keyword" />
                                    <i class="pi pi-search"></i>
                                </span>
                            </div>
                            <div class="col-sm-4">
                                <button type="button" pButton pRipple icon="pi pi-file" (click)="dt3.exportCSV()"
                                    class="button-blue" pTooltip="CSV" tooltip Position="bottom"
                                    style="float:right"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()"
                                    style="float:right" class="button-orange" pTooltip="PDF"
                                    tooltipPosition="bottom"></button>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Type</th>
                            <th></th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="groupheader" let-member>
                        <tr pRowGroupHeader>
                            <td colspan="2">

                                <div *ngIf="member.type=='USER'">
                                    <img class="rounded-circle" height="25" width="25" referrerpolicy="no-referrer"
                                        src={{getUserThumbnailURL(member.email)}}
                                        onerror="this.src = 'assets/default_user.png'" /> {{
                                    fullNameFromEmail(member.email) }}
                                </div>
                                <div *ngIf="member.type=='GROUP'">
                                    <img class="rounded-circle" height="25" width="25" referrerpolicy="no-referrer"
                                        src="assets/group_thumbnail.png"
                                        onerror="this.src = 'assets/group_thumbnail.png'" /> {{
                                    getGroupNameFromEmail(member.email) }}
                                </div>
                            </td>
                            <td> {{member.email}}</td>
                            <td> <input [(ngModel)]="member.role" class="form form-control form-control-sm"
                                id="role_{{member.email}}"></td>
                            <td>{{member.type}}</td>
                            <td><button *ngIf="!member.indirect" class="btn btn-sm btn-danger"
                                    (click)="deleteMember( member.email )">Delete</button></td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-membr>
                        <ng-container *ngIf="membr.children">
                            <ng-container *ngFor="let mem of membr.children">
                                <tr class="row-highlight">
                                    <td></td>
                                    <td colspan="1">

                                        <div *ngIf="mem.type=='USER'">
                                            <img class="rounded-circle" height="25" width="25"
                                                referrerpolicy="no-referrer" src={{getUserThumbnailURL(mem.email)}}
                                                onerror="this.src = 'assets/default_user.png'" /> {{
                                            fullNameFromEmail(mem.email) }}
                                        </div>
                                        <div *ngIf="mem.type=='GROUP'">
                                            <img class="rounded-circle" height="25" width="25"
                                                referrerpolicy="no-referrer" src="assets/group_thumbnail.png"
                                                onerror="this.src = 'assets/group_thumbnail.png'" /> {{
                                            getGroupNameFromEmail(mem.email) }}
                                        </div>

                                    </td>

                                    <td>
                                        {{mem.email}}
                                    </td>
                                    <td>
                                        {{mem.role}}
                                    </td>
                                    <td>{{mem.type}}</td>
                                    <td></td>
                                </tr>
                            </ng-container>
                        </ng-container>

                    </ng-template>
                </p-table>
            </div>

            <div class="spacer"></div>
            <div class="spacer"></div>
        </div>
    </div>
</div>
