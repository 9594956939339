import { Injectable, Optional, SkipSelf } from "@angular/core";
import { utilities } from "../utilities/utilities";
import { fileCSVRecord } from "../models/filecsv";
import { googleDriveDataService } from 'src/app/dataservices/googleDrive.dataservices';

@Injectable({
    providedIn: 'root'
})

export class googleDriveRepository {
    private bulkCVSrecords: fileCSVRecord[];

    constructor(
        private _driveDataService: googleDriveDataService,
        private _util: utilities
    ) {

    }

    addBulkRecord(record) {
        this.bulkCVSrecords.push(record);
    }
    
    getBulkRecords() {
        return this.bulkCVSrecords;
    }

    clearBulkArray() {
        this.bulkCVSrecords = [];
    }

    removeFilePermission(client, record, userEmail) {
        this._driveDataService.removePermission(client, record, userEmail).subscribe(data => {
            console.log(data.body);
            let DataWeWANT = JSON.parse(data.body);
            const pos = this.bulkCVSrecords.map(e => e.documentID).indexOf(DataWeWANT.data.fileId);
            console.log("position found at: " + pos);
            this.bulkCVSrecords[pos].processed = true;
        })
    }
}
