import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global_constants';
import { Observable, BehaviorSubject, catchError } from 'rxjs';
import { utilities } from '../utilities/utilities';

@Injectable()
export class WeatherAPI {
    apiKey: string = "e4820d667c0746269ea231148222912";
    
    constructor(
        private http: HttpClient,
        private util: utilities,
    ) {
    }

    getWeatherForLondon():Observable<any> {
        let apiURL = "https://api.weatherapi.com/v1/forecast.json?key="+this.apiKey+"&q=London&days=1&aqi=no&alerts=yes";
        return this.http.get<any>(apiURL);
    }
    getWeather(searchItem: string):Observable<any> {
        console.log("Get weather for for: " + searchItem)
        let apiURL = "https://api.weatherapi.com/v1/forecast.json?key="+this.apiKey+"&q="+ searchItem +"&days=1&aqi=no&alerts=yes";
        return this.http.get<any>(apiURL);
    }
}
