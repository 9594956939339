import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global_constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { utilities } from '../utilities/utilities';


import { OnInit } from '@angular/core';

@Injectable()
export class JumpcloudDataService {


    constructor(
        private http: HttpClient,
        private util: utilities,
        
    ) {
        this.util.debug('JumpcloudUserDataService created');
    }
    ngOnInit(): void {
    }

    // need to implement this.
    getUser(_searchTerm: string){
    }

    getAllUsers(caller: string, clientName: string):Observable<any> {
        this.util.debug("JumpcloudUserDataService.getAllUsers called by " + caller + " client name: " + clientName)
        return this.http.get<any>(GlobalConstants.apiURL + "users/jumpcloud?client="
        + clientName
        + '&usejc=true&search=""&skip=""');
    }

    getAllGroups(clientName: string):Observable<any> {
        return this.http.get<any>(GlobalConstants.apiURL + "jcgroups?client="+ clientName);
    }


}