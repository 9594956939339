<ul class="no-bullets smallText">
    <li *ngFor="let sendas of userData.sendAs ; let i = index" class="smallText div-border">

        <div class="form-group row localHeader">
                    <div class='col-sm-3 smallText' >{{sendas.sendAsEmail}}</div>
                    <div class="col-sm-4 smallText">
                        <select id="userOrgName" class="form-select form-select-sm smallText" (change)="selectSignature(i, ($event).target)" name="userOrgName" aria-label="userOrgName">
                        <!-- Need to put org names from client object here -->
                        <option disabled selected value="">Select Template</option>
                        <option *ngFor="let org of currentClient.organisations | keyvalue" [value]='org.key'>{{org.key}}</option>
                        </select>
                    </div>
                    <div class="col-sm-1">
                        <button class="btn btn-primary btn-sm" (click)="setSignature(i)" >Apply</button>
                    </div>
        </div>
        <div class="row form-group sig-box">
            <div [innerHTML]="sendas.signature | safeHtml"></div>
        
        </div>
    </li>
</ul>