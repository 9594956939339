import { Component, OnInit } from '@angular/core';
import { WeatherRepository } from 'src/app/Repositories/weather.repository';
import { AstronumyRepository } from 'src/app/models/astronomy.repository';
import { utilities } from 'src/app/utilities/utilities';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ocUserRepository } from 'src/app/Repositories/ocUser.repository';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.css']
})
export class WeatherComponent implements OnInit {
   today;
   svg:SafeHtml;
  

  constructor(
    private _astroRepository: AstronumyRepository,
    private _ocRepository: ocUserRepository,
    private util: utilities,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    //this._weatherRepository.checkForecastIsCurrent();
    this.today = new Date().getDate();
  }

  get forecast() {
    //return this._weatherRepository.getCurrentForecast();
    return this._ocRepository.getForecast();
  }

  get moon() {
    return this._astroRepository.getCurrentMoon();
  }

  get moon_phases() {
    return this._astroRepository.getMoonPhases();
  }

  get moonPhaseSVG() {
    // get the svg file for today;
    return this._astroRepository.getMoonPhasesSVG();
  }

  get dayLength() {
    return this._ocRepository.getDayLength();
  }
 
}
