<div class="spacer"></div>

<span class="section-header">Assign / Reassign Adobe License</span>

<div class="spacer"></div>
<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
<div class="container list-group">
    <section class="oc-border">
        <div class="oc-formInputDiv" >
            <input class="oc-checkbox" type='checkbox' id='removeLicense' 
            formControlName="removeLicense"
            
            (change)="removeCheckboxClicked($event)">   
            <label class="oc-label"  for="removeLicense"> Remove From User? </label>             
        </div>

        <div class="oc-formInputDiv">
            <app-drop-down-email-selector 
            [id]="removeLicenseUserSelector"
            [userList]="adobeUsersWithLicensesList"
            [groupName]="registerForm"
             (selectedUser)="getUsersCurrentAdodbeLicences($event)">
            </app-drop-down-email-selector>
        </div>

        <div class="oc-formInputDiv">
            <label class="oc-label"  for="userLicenseSelector"> License to Remove </label>
            <select id="userLicenseSelector" class="form-select form-select-sm" 
            
            formControlName="userLicenseSelector" 
            aria-label="userLicenseSelector">   
                <option disabled selected value="">Select License</option>
                <option *ngFor="let licnese of listOfSelectedUsersLicenses" [value]='licnese'>{{licnese}}</option>
            </select>
        </div>
    </section>
   <div class="spacer"></div>
   <!-- hear  -->

    <section class="oc-border">
        <div class="oc-formInputDiv">
            <input class="oc-checkbox" type='checkbox' id='asignLicense' formControlName="asignLicense" 
            [(value)]="_assignLicenseChecked"
            (change)="assignCheckboxClicked($event)">      
            <label class="oc-label"  for="asignLicense"> Assign to User? </label>
                  
        </div>
        <div class="oc-formInputDiv">
            <app-drop-down-email-selector 
            [id]="assignLicenseUserSelector"
            [userList]="adobeUserList" 
            [groupName]="registerForm"
            (selectedUser)="setUserToAssignTo($event)">
        </app-drop-down-email-selector>
        </div>
        <div class="oc-formInputDiv">
            <label class="oc-label"  for="assignLicenseSelector"> License to Assign </label>
            <select id="assignLicenseSelector" class="form-select form-select-sm" 
            formControlName="assignLicenseSelector" 
            aria-label="assignLicenseSelector">   
                <option disabled selected value="">Select License</option>
                <option *ngFor="let product of adobeProducts " [value]='product.productCode'>{{product.name}}</option>
            </select>
        </div>
    </section>

    <section class="list-group">
    <div class="oc-formInputDiv">
        <button class="btn btn-primary btn-sm" type="submit" >Do it</button>
        </div>
    </section>
</div>
</form>