import { Component, OnInit } from '@angular/core';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { utilities } from 'src/app/utilities/utilities';


@Component({
  selector: 'app-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.css']
})
export class OrganisationsComponent implements OnInit {
  newOrganisationName:string = "";


  constructor(
    private _clientRepository: ClientRepository,
    private util: utilities
  ) { }

  ngOnInit(): void {
  }
  get currentClient() {
    return this._clientRepository.getCurrentClient();
  }

  addOrganistion()  {
    this._clientRepository.addOrganisation(this.newOrganisationName);
  }

  editOrgName(i) {

  }

  deleteOrg(i) {
    this._clientRepository.deleteOrganisation(i);
  }
 }
