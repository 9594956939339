/* This repository is the new provider for client info
*/

import { Client } from "../models/client.model";
import { Injectable,Optional, SkipSelf} from "@angular/core";
import { ClientDataService } from "../dataservices/client-dataservice";
import { UserRepository } from "./user.repository";
import { utilities } from '../utilities/utilities';
import { Observable } from "rxjs";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { TRISTATECHECKBOX_VALUE_ACCESSOR } from "primeng/tristatecheckbox";

@Injectable({
    providedIn: 'root'
})

export class ClientRepository {
    private clientNames: String [] = [];
    private clients: Client [] = [];
    private currentClientName: string;
    private currentClient: Client = new Client();
    private firstLoad = true;
    private googleOrgUnits;

    

    constructor(private dataSource: ClientDataService, 
        private util: utilities,
        private _userRepository: UserRepository,
        
        @Optional() @SkipSelf() sharedService?: ClientRepository) {
        if (sharedService) {
            throw new Error('ClientDataService is already loaded');
          }
          
        this.getAllClientsFromServer();
        this.currentClient.usejc = false;
        
    }

    getAllClientsFromServer() {
        this.dataSource.getAllClients("ClientRepository").subscribe(data => {
            this.clients = data;
            // sort into alphabetical order.
            this.clientNames = data.map(p => p.client ?? "(None)")
                .filter((c, index, array) => array.indexOf(c) == index).sort();
            console.log(this.clientNames);
            console.log(this.clients);
        });
    }

    getClients():Client [] {
        return this.clients;
    }

    getClientNames() {
        return this.clientNames;
    }

    setCurrentClient(clientName) {
        this.currentClientName = clientName;
        this.currentClient =  Array.from(this.clients).find((item: any) => item.client === clientName);
        console.log("setCurrentClient: " + clientName);
        console.log(this.currentClient);

        if (!this.firstLoad) {
         this._userRepository.getGoogleListFromServer(clientName);
         if (this.currentClient.usejc) {
            this._userRepository.getJumpcloudListFromServer(clientName);
         }
        }

      

        this.firstLoad = false;
    }

    getCurrentClient(): Client {

        return this.currentClient;
    }

    getCurrentClientName() {
        return this.currentClientName;
    }

    getUseJumpCloud() {
        return this.currentClient.usejc;
    }

    getJumpCloudAllAtaffGroup() {
        return this.currentClient.jcAllStaffGroup;
    }


    getManageAdobe() {
        return this.currentClient.manageAdobe;
    }

    addClient() {

    }

    patchClient() {
        
    }

    getFirstLoad() {
        return this.firstLoad;
    }

    loadGoogleOrgUnits(clientName) {
        // get the organistions from Google.
        this.dataSource.getGoogleOrgUnits(clientName).subscribe(data => {
            this.googleOrgUnits = data;
        })
    }

    getGoogleOrgUnits() {
        return this.googleOrgUnits;
    }

    patchSignature(organisation,templateText) {
        this.util.debug("patch signature Template for " + organisation + " in " + this.currentClientName);
        this.dataSource.patchSignatureTemplate(this.currentClientName,organisation,templateText).subscribe(data=> {
            
            // Update the in Memory Object with the new text.
            // need to do some error checking here though.
            this.currentClient.organisations[organisation].signature=templateText;
        })

    }

    addOrganisation(organisationName) {
        this.util.debug("Add " + organisationName + " to " + this.currentClientName);
        this.dataSource.addOrganisation(this.currentClientName,organisationName).subscribe(data=> {
            //if sucessful, we want to add the organisaation to our current client model:
            if (data.Attributes.organisations) {
                this.currentClient.organisations = data.Attributes.organisations;
                this.util.displayMessageToUser("Organisation " + organisationName + " has been added succesfully");
            } else {
                this.util.displayError("An error occoured, please see the console log and send to David.");
                console.log(data);
            }

        })
    }

    deleteOrganisation(organisationName) {
        this.util.debug("remove " + organisationName + " from " + this.currentClientName);
        this.dataSource.deleteOrganisation(this.currentClientName, organisationName).subscribe(data => {
            if (data.Attributes.organisations) {
                this.currentClient.organisations = data.Attributes.organisations;
                this.util.displayMessageToUser("Organisation " + organisationName + " has been removed succesfully");
            } else {
                this.util.displayError("An error occoured, please see the console log and send to David.");
                console.log(data);
            }
        })
    }
}
