import { Component, Input, OnInit } from '@angular/core';
import { utilities } from 'src/app/utilities/utilities';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { UserRepository } from 'src/app/Repositories/user.repository';


@Component({
  selector: 'app-delegates-tab',
  templateUrl: './delegates-tab.component.html',
  styleUrls: ['./delegates-tab.component.css']
})
export class DelegatesTabComponent implements OnInit {
  @Input() userData;
  selDelegate;
  item;

  constructor(
    private _userRepository: UserRepository,
    private util: utilities) {}

  ngOnInit(): void {
  }

  addDelegate() {
    this._userRepository.addDelegate(this.userData.userData.primaryEmail, this.selDelegate)
  }

  delegateSelected(seleced) {
    // the dropdown returns the full name, so we conver to email.
    // this.selDelegate =this._userRepository.getEmailFromFullName(seleced);
    // this.util.debug(this.selDelegate);

    // The new dropown returns the email :)
    this.selDelegate = seleced;
  }

  removeDelegate(delegateIndex) {
    //we get the index so we can slice it from the aray !
    this._userRepository.removeDelegate(this.userData.userData.primaryEmail,delegateIndex);
  }

  addCalendarShare() {

  }

  removeCalendarShare(delegateIndex) {
    
  }

}
