import { Injectable, Optional, SkipSelf} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global_constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { utilities } from '../utilities/utilities';
import { ClientRepository } from '../Repositories/client.repository';


@Injectable({
  providedIn: 'root'
})

export class UsersService {
  private all$: BehaviorSubject<any> = new BehaviorSubject(null);
  private currentlySelectedName$: BehaviorSubject<any> = new BehaviorSubject(null);
  private currentlySelectedData: BehaviorSubject<any> = new BehaviorSubject(null);
  private jumpCloudUsers: BehaviorSubject<any> = new BehaviorSubject(null);
  currentClientData;

  constructor(
   private http: HttpClient,
    private util: utilities,
    private _clientRepository: ClientRepository,
    @Optional() @SkipSelf() sharedService?: UsersService
  ) {

    if (sharedService) {
      throw new Error('UsersService is already loaded');
    }
    this.util.debug('UsersService created');
    this.getAll();
  }


  // consumers subscribe to this to get the data for all of the clients.
  getGoogleUsers() {
    return this.all$.asObservable();
  }

  

  // get the data from the server. Initally called by the constructor. Should be called later 
  // if any client info is updated ( signatures, new client , orgs etc ).
  getAll(source: string = "Class Constructor") {
    // this._client_list_service.getCurrentlySelectedClientData().subscribe(
    //   data => {
        this.currentClientData = this._clientRepository.getCurrentClient;
        if (this.currentClientData != undefined && this.currentClientData != "") {
          this.http.get(GlobalConstants.apiURL + "users/google/?client="
            + this.currentClientData.client
            + '&usejc=false&search=""&skip=""'
          ).subscribe(data => this.all$.next(data));
        }
      
    
    this.util.debug("UsersService.getAll called by " + source);
      // users/google/?client=Dazed&usejc=false&search=""&skip=""')
  }
}
