import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { UserInfo } from 'src/app/models/fullUserInfo.model';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { Client, clientOrgLocation } from 'src/app/models/client.model';
import { GoogleUserDataService } from 'src/app/dataservices/google-user-dataservice';
import { utilities } from 'src/app/utilities/utilities';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-user-page-user',
  templateUrl: './user-info.html',
  styleUrls: ['./user-info.css']
})


export class UserPageUserComponent implements OnInit{
  @Input () userData: UserInfo;
  locations: clientOrgLocation[] = [];
  constructor(private _userRespository: UserRepository,
    private _clientRepository: ClientRepository,
    private googleUserDataSource: GoogleUserDataService,
    private util: utilities) { }

  ngOnInit(): void {

  }

  get currentClient() {
    return this._clientRepository.getCurrentClient();
  }

  autoFillLocationSelect(orgName) {
    // this will fill the locations select when an org is chosen:
    //console.log(orgName.value);
    if (orgName.value) {
      console.log(this.currentClient.organisations[orgName.value]);
      this.locations = this.currentClient.organisations[orgName.value].locations;
      let sel = document.getElementById('userLocationName') as HTMLSelectElement; 
      sel.selectedIndex = 0;  // first option is selected, or                               // -1 for no option selected
    }
  }
  autoFillAddress(locationNmae) {
    console.log("this.autoFillAddress called")
    console.log(locationNmae.value);
    console.log(this.locations[locationNmae.value]);
    
    
    if (this.locations[locationNmae.value].sourceIsStructured) {
      this.userData.userData.addresses[0].formatted = this.locations[locationNmae.value].formatted;
    } else {
      
      if(this.locations[locationNmae.value].streetAddress) { this.userData.userData.addresses[0].streetAddress = this.locations[locationNmae.value].streetAddress; }
      this.userData.userData.addresses[0].extendedAddress = this.locations[locationNmae.value].extendedAddress;
      this.userData.userData.addresses[0].formatted = this.locations[locationNmae.value].formatted;
      this.userData.userData.addresses[0].formatted = this.locations[locationNmae.value].formatted;
      this.userData.userData.addresses[0].formatted = this.locations[locationNmae.value].formatted;
    }



    let num =  this.userData.userData.phones.length;
    for (let p = 0; p < num; p++) {
      if ( this.userData.userData.phones[p].type == 'work' ) { this.userData.userData.phones[p].value = this.locations[locationNmae.value].telephone }
    }
    this.userData.userData.websites[0].value = this.locations[locationNmae.value].website;
  }

  get orglocations() {
    return this.locations;
  }
  setDefaultPic() {
    console.log("pic error");
  }

  checkSendAs(emailAddress) {
    return this.userData.sendAs.find(sa => sa.sendAsEmail === emailAddress);
  }

  submitForm(form) {
    //console.log(this.userData.userData);
   // this._userRespository.patchGoogleUser();
   let useJC = false;
   // check to see if we are using JC and a jumpcloud ID exists for this user.
   // if it doesn't then it means the user is only in Google !
   if (this._clientRepository.getUseJumpCloud() && this.jumpCloudId) {
      this.userData.userData.jc_user_id = this.jumpCloudId;
      useJC = true;
   }
   if (!this.userData.userData.gender.type) { this.userData.userData.gender.type = "unknown" }
   this.googleUserDataSource.patchUser(
    this.userData.userData,
    this._clientRepository.getCurrentClientName(),
    useJC
    ).subscribe( data => { 
    this.util.debug(data)
    this.util.displayMessageToUser("User has been updated");

});
  }

  get jumpCloudId() {
    if (this._clientRepository.getUseJumpCloud()) {
      if( this._userRespository.getJumpCloudID(this.userData.userData.primaryEmail) ) {
        return this._userRespository.getJumpCloudID(this.userData.userData.primaryEmail); 
      } else {
        return "null";
      }

    } else {
      return "null";
    }
  }
  get googleOrgs() {
    return this._clientRepository.getGoogleOrgUnits();
  }
}
  

