export class userMailLabel {
    id: string;
  name: string;
  messageListVisibility: string;
  labelListVisibility: string;
  type: string;
}

// const MessageListVisibility  {
//     show = "show",
//     hide = "hide"
// }

// const LabelListVisibility {
//     labelShow = "labelShow",
//     labelShowIfUnread = "labelShowIfUnread",
//     labelHide = "labelHide"
// }