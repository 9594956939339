<div class="spacer"></div>
<div *ngIf="userData.userData; else loading">
    <div class="container-fluid">
        <div class="form-group row">
            <div class="col-sm smallText">Give Delegate access to: </div>
            <div class="col-sm">
            <!-- <user-dropdown-component [item]="item" (afterChange)="delegateSelected($event)"></user-dropdown-component> -->
            <app-user-dropdown-searchable  [item]="item" (afterChange)="delegateSelected($event)"></app-user-dropdown-searchable>
            </div>
            <div class="col-sm-1">
            <button class="btn btn-primary btn-sm" (click)="addDelegate()" >Grant</button>
            </div>
            <div class="col-sm">
                
                </div>
            </div>
    </div>
    <div class="spacer"></div>
    
    <div class="container-fluid">
        <div class="row  smallText">
            <p>The following have access to {{userData.userData.name.fullName}} email</p>
        </div>
        <ul class="list-group smallText">
            <li *ngFor="let delegate of userData.delegates.delegates let i = index" class="list-group-item smallText">
                <div class="row">
                    <div class="col-sm-4">{{delegate.delegateEmail}}</div>
                    <div class="col-sm-1"><button class="btn btn-danger btn-sm" (click)="removeDelegate(i)">Remove</button> </div>
                    <div class="col-sm"></div>
                </div>
                
            </li>
        </ul>

    </div>

    <div class="spacer"></div>
    <div class="spacer"></div>

    <div class="container-fluid">
        <div class="form-group row">
            <div class="col-sm smallText">THIS IS NOT WORKING YET : Grant Calendar access to: </div>
            <div class="col-sm">
            <user-dropdown-component [item]="item" (afterChange)="delegateSelected($event)"></user-dropdown-component>
            </div>
            <div class="col-sm-1">
            <button class="btn btn-primary btn-sm" (click)="addCalendarShare()" >Grant</button>
            </div>
            <div class="col-sm">
                
                </div>
            </div>
    </div>
    <div class="spacer"></div>
    
    <div class="container-fluid">
        <div class="row  smallText">
            <p>The following have access to {{userData.userData.name.fullName}} email</p>
        </div>
        <ul class="list-group smallText">
            <li *ngFor="let delegate of userData.delegates.delegates let i = index" class="list-group-item smallText">
                <div class="row">
                    <div class="col-sm-4">{{delegate.delegateEmail}}</div>
                    <div class="col-sm-1"><button class="btn btn-danger btn-sm" (click)="removeCalendarShare(i)">Remove</button> </div>
                    <div class="col-sm"></div>
                </div>
                
            </li>
        </ul>

    </div>


</div>


<ng-template #loading>Getting Info... </ng-template>
