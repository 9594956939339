import { Component, OnInit,OnDestroy } from '@angular/core';
import { GoogleGroupsServiceService } from 'src/app/services/google-groups-service.service';
import { JumpcloudGroupsServiceService } from 'src/app/services/jumpcloud-groups-service.service';
import { jumpcloudRepository } from 'src/app/Repositories/jumpcloud.repository';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { utilities } from 'src/app/utilities/utilities';
import { Subscription } from 'rxjs';
import { FormControl} from '@angular/forms';
import { googleInfoRepository } from 'src/app/Repositories/googleInfo.repository';


@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css'],
  
})
export class GroupsComponent implements OnInit,OnDestroy {
  
  //googleGroupsList;
  //jumpcloudGroupsList;
  useJumpcloud: boolean = false;
  selectedDirectory: string = "Google";
  clientName;
  googleGroupsList_subscription: Subscription | undefined;
  jumpcloudGroupsList_subscription: Subscription | undefined;
  useJumpcloud_subscription: Subscription | undefined;
  clientName_subscription: Subscription | undefined;
  searchControl = new FormControl();

  //jumpcloudGroupsList;

  constructor(
    // private _googleGroupsService: GoogleGroupsServiceService,
    // private _jumpCloudGroupService: JumpcloudGroupsServiceService,
    private _clientRepository: ClientRepository,
    private _googleInfoRepository: googleInfoRepository,
    private _jumpcloudRepository:  jumpcloudRepository,
    private util: utilities
  ) { }

  ngOnInit(): void {
    // this. googleGroupsList_subscription = this._googleGroupsService.getAllData().subscribe(data => {
    //   this.googleGroupsList = data;
    //   this.util.debug("Google Groups");
    //   this.util.debug(data);
    // });
      
      this.clientName = this._clientRepository.getCurrentClientName();
      this._googleInfoRepository.getFromServer(this.clientName);
      this._jumpcloudRepository.getGroupsFromServer(this.clientName);
      //this.useJumpcloud = data;
      this.setUseJC(this._clientRepository.getUseJumpCloud());
    
      

    this.searchControl.valueChanges
      .subscribe(value => {
        console.log(value);
        let filt = value.toUpperCase();
        let table = document.getElementById("groupListTable");
        let tr = table!.getElementsByTagName("tr");

        // we need to loop through the rows then cells, so that we can then break and move to the next row if we find the search string.
        for (let i = 0; i < tr.length; i++) {
          let td = tr[i]!.getElementsByTagName("td");
          for (let i = 0; i < td.length; i++) {
            var txtValue = td[i].textContent || td[i].innerText;
            //console.log(txtValue)
            if (td[i]) {
              if (txtValue.toUpperCase().indexOf(filt) > -1) {
                td[i].closest('tr')!.style.display = "";
                //console.log("found: " + txtValue);
                break; // if we've found it on a cell in this rouw, break out of this loop so we don't check any more cells.
              } else {
                td[i].closest('tr')!.style.display = "none";
              }
            }
          }
        }
      });
  }

  get googleGroupsList() {
    return this._googleInfoRepository.getGroups();
  }

  get jumpcloudGroupsList() {
   return this._jumpcloudRepository.getGroups();
  }

  setUseJC(usejc) {
    // console.log("useJumpcloud changed to: "+ usejc);
    // this.useJumpcloud_subscription = this.useJumpcloud = usejc;
    // // if we're using JC then setup the jumpcloud groups subscription.
    // if (this.useJumpcloud) {
    //   this.jumpcloudGroupsList_subscription = this._jumpCloudGroupService.getAllData().subscribe( data => {
    //     this.jumpcloudGroupsList = data;
    //     this.util.debug("Jumpcloud Groups");
    //     this.util.debug(data);
    //   } ); 
    // } else {
    //   /// make sure we reset to google if we are not using jumpcloud, and kill the groups subscrition.
    //   this.selectedDirectory = "Google";
    // }
  }
  onDirectoryChange(value: any) {
    this.util.debug("Group Directory Changed to: "+ value);
    this.selectedDirectory = value;
  }

 
  ngOnDestroy() {
    // this.googleGroupsList_subscription?.unsubscribe;
    // this.jumpcloudGroupsList_subscription?.unsubscribe;
    // this.useJumpcloud_subscription?.unsubscribe;
}
}
