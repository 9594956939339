import { Component, Input, OnInit,EventEmitter, Output } from '@angular/core';
import { utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-users-groups-list',
  templateUrl: './users-groups-list.component.html',
  styleUrls: ['./users-groups-list.component.css']
})
export class UsersGroupsListComponent implements OnInit {
  @Input() userData;
  @Output() selectedGroup: EventEmitter<any> = new EventEmitter();

  constructor(
    private _util: utilities
  ) { }

  ngOnInit(): void {
    console.log(this.userData);
  }

  deleteUserFromGroup(groupEmail) {
    
    this.selectedGroup.emit(groupEmail);
  }



}
