export class User{
    addresses: userAddress[] = [];
    agreedToTerms: boolean;
    aliases: string[] = [];
    archived: boolean;
    changePasswordAtNextLogin: boolean;
    creationTime: string;
    customSchemas: {};
    customerId: string;
    emails: emailAddress[]= [];
    etag: string; // version of the entry.. can be used to make sure changes aren't overwritten by someone else.
    externalIds: externalIds[] = [];
    gender: {
        addressMeAs: string,
        type: string,
        customGender?:string;
    };
    id: string;
    includeInGlobalAddressList: boolean;
    ipWhitelisted: boolean;
    isAdmin: boolean;
    isDelegatedAdmin: boolean;
    isEnforcedIn2Sv: boolean;
    isEnrolledIn2Sv: boolean;
    isMailboxSetup: boolean;
    jc_user_id: string;
    kind: string;
    languages: userLanguage[] = [];
    lastLoginTime: string;
    name: usersName;
    nonEditableAliases: string[] = [];
    orgUnitPath: string;
    organizations: userOrganizations[] = [];
    phones: userPhone[] = [];
    primaryEmail: string;
    primary_domain: string;
    recoveryEmail: string;
    recoveryPhone: string; // The phone number must be in the E.164 format, starting with the plus sign (+). Example: +16506661212.
    suspended: boolean; 
    thumbnailPhotoUrl: string;
    websites: userWebsite[] = [];
}

export class usersName  {
    givenName: string;
    familyName: string;
    fullName: string;
};

export class emailAddress  {
    address: string;
    primary?: boolean;
    customType?: string;
    type?: string;
}

export class userAddress {
    type: string;
    formatted: string;
    sourceIsStructured: boolean;
    streetAddress: string;
    extendedAddress: string;
    locality: string;
    region: string;
    postalCode: string;
    country: string;
}

export class externalIds {
    type: string;
    value: string;
    customType?: string;
}

export class userLanguage {
    languageCode: string;
    preference?: string;
    customLanguage?: string;
}

export class userOrganizations {
    name: string;
    primary?: boolean;
    symbol?: string;
    title?: string;
    type?: string;
    location?: string;
    fullTimeEquivalent?: BigInteger;
    domain?: string;
    description?: string;
    department?: string;
    customType?: string;
    costCenter?: string;
}

export class userPhone {
    type: string;
    value: string;
    primary?: boolean;
    customType?: string; 
}

export class userWebsite {
    type: string;
    value: string;
    primary?: boolean;
    customType?: string; 
}

export class listUser {
    primaryEmail: string;
    fullName: string;
    thumbnailPhotoUrl: string;
    orgDescription: string;
    orgTitle: string;
    orgName: string;
    suspended: boolean;
    creationTime: string;
  }

export class usersObjectForSearchDropdown {
    name: string;
    email: string;
}