<ng-container *ngIf="forecast">
    <h2>Todays Weather in {{forecast.location.name}}</h2>
    <!-- <p>Location: {{forecast.location.name}}</p> -->

    <div class="container  ">
        <div class="row  align-items-center ">
            <div class="col-sm border rounded">
                <div class="row smallText align-items-center">
                    <div class="col-sm-3">
                        <img src="{{forecast.forecast.forecastday[0].day.condition.icon}}" />
                    </div>
                    <div class="col-sm-7">
                        <span class="smallText">{{forecast.forecast.forecastday[0].day.condition.text}}</span><br>
                        <span class="smallText">Chance of Rain:
                            {{forecast.forecast.forecastday[0].day.daily_chance_of_rain}} %</span><br>
                        <span class="smallText">UV Index: {{forecast.forecast.forecastday[0].day.uv}}</span><br>
                    </div>
                </div>
            </div>

            <div class="col-sm  border rounded">
                <div class="row smallText align-items-center">
                    <div class="col-sm-3">
                        <ng-container *ngIf="forecast.forecast.forecastday[0].day.maxtemp_c >= 20; else tempcold">
                            <img src="assets/hot.png" />
                        </ng-container>
                        <ng-template #tempcold>
                            <img src="assets/cold.png" />
                        </ng-template>
                    </div>
                    <div class="col-sm-7">
                        <span class="smallText">Min Temp: {{forecast.forecast.forecastday[0].day.mintemp_c}}
                            &#8451;</span><br>
                        <span class="smallText">Max Temp: {{forecast.forecast.forecastday[0].day.maxtemp_c}}
                            &#8451;</span><br>
                        <span class="smallText">Humidity: {{forecast.forecast.forecastday[0].day.avghumidity}}
                            %;</span><br>
                    </div>
                </div>
            </div>
            <div class="col-sm border rounded">
                <div class="row smallText align-items-center">
                    <div class="col-sm-3">
                        <img src="assets/sun2.png" />
                    </div>
                    <div class="col-sm-7">
                        <span class="smallText">Rise: {{forecast.forecast.forecastday[0].astro.sunrise}}</span><br>
                        <span class="smallText">Set: {{forecast.forecast.forecastday[0].astro.sunset}}</span><br>
                        <span class="smallText">Day Length: {{dayLength}}</span>
                    </div>
                </div>
            </div>
            <div class="col-sm border rounded">
                <div class="row smallText align-items-center">
                    <div class="col-sm-3">
                        <ng-container *ngIf="moonPhaseSVG">

                            <div shadow [innerHTML]="moonPhaseSVG"></div>

                        </ng-container>

                    </div>
                    <div class="col-sm-7">
                        <span class="smallText">Rise: {{forecast.forecast.forecastday[0].astro.moonrise}}</span><br>
                        <span class="smallText">Set: {{forecast.forecast.forecastday[0].astro.moonset}}</span><br>
                        <span class="smallText">Phase: {{forecast.forecast.forecastday[0].astro.moon_phase}}</span>
                    </div>
                </div>
            </div>


        </div>

    </div>

</ng-container>