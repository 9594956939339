import { Injectable,Optional, SkipSelf} from "@angular/core";
import { group } from "../models/group.model";
import { JumpcloudDataService } from "../dataservices/jumpcloud-dataservice";

@Injectable({
    providedIn: 'root'
})


export class jumpcloudRepository {
    private _client;
    private _groups: group[] = []
    private _groupsLoaded: boolean = false;

    constructor( 
        private _jumpcloudDataService: JumpcloudDataService
    ) {}

    setClient(client) {
        this._client = client;
     } 


     getGroupsFromServer(clientName) {
        this._jumpcloudDataService.getAllGroups(clientName).subscribe(data => {
            console.log("jumpcloud groups:", JSON.parse(data.body));
            // we need to massage the data to match the google groups format:
            let gr = JSON.parse(data.body);
            let groups = gr.groups;
            // console.log(groups);
            for (let g=0; g < groups.length; g ++) {
                console.log(groups[g].name);
                let gn = new group;
                gn.name = groups[g].name;
                gn.description = groups[g].description;
                gn.directMembersCount = 0;
                gn.email = groups[g].email;
                gn.id = groups[g].id;
                this._groups.push(gn);
            }
            
            // console.log(this._groups);
        })
     }

     getGroups() {
        //if (this.groupsAndDomains.groups) 
        return this._groups;
      }

      getGroupMembers(groupID) {

      }

      getGroupInfo(groupID) {
        console.log(this._groups);
        // var picked = arr.find(o => o.city === 'Amsterdam');
        // var output = arr.filter(function(value){ return value.city=="Amsterdam";})
        console.log(this._groups.filter(function(grp){ return grp.id===groupID}));
        return this._groups.find(grp => grp.id == groupID);

      }

      public get groupsLoaded() {
        return this._groupsLoaded;
      }

}