import { Component, OnInit, Input } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { GoogleUserDataService } from 'src/app/dataservices/google-user-dataservice';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { userMailLabel } from 'src/app/models/mailLabel.model';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.css']
})
export class LabelsComponent implements OnInit {
  @Input() userData;
  @Input() clientName;
  labels: TreeNode[];
  selectedLabel: TreeNode;

  newLabelName;
  fatrash = faTrash;

  editMode:boolean;
  labelToEdit: userMailLabel;
  addLabelButtonText: string;

  constructor(
    private _userRepsitory: UserRepository,
    private _googleUserDataService: GoogleUserDataService,
    private util: utilities
  ) { }

  ngOnInit(): void {
    this.labels = this.userData.labels;
    this.editMode = false;
    this.addLabelButtonText = "Add";
  }

  get treeMap() {
    return this._userRepsitory.getLabelTreeMap();
  }

  nodeSelect($event) {
    //console.log($event);
    if ($event.node.children.length > 0) {
      console.log("this is a parent node : " +  $event.node.data.name.substring(0, $event.node.data.name.lastIndexOf("/")));
    } else {
      console.log($event.node.data);
      this.addLabelButtonText = "Update";
      this.newLabelName = $event.node.data.name;
      this.labelToEdit = $event.node.data;
      this.editMode = true;
    }
  }

  addNewLabel() {
    // map to a label object:
    if (this.editMode) {
      this.labelToEdit.name = this.newLabelName;
      this._googleUserDataService.patchMailLabel(this.labelToEdit, this.userData.userData.primaryEmail, this.clientName).subscribe( data => {
     
        // replace the label.
        for (let i=0; i< this.userData.labels.length; i++) {
          if (this.userData.labels[i].id == this.labelToEdit.id) {
            this.userData.labels[i] = this.labelToEdit;
          }
        } 
        this._userRepsitory.buildLabelsListForDisplay();
        this.util.displayMessageToUser("Label Updated");
        this.clearForm();

      });
    } else {

    let newLabel = new userMailLabel();
    newLabel.name = this.newLabelName;
    newLabel.type = "user";
    newLabel.labelListVisibility = "labelShow";
    newLabel.messageListVisibility = "show";

    this._googleUserDataService.addMaillabel(newLabel, this.userData.userData.primaryEmail, this.clientName).subscribe( data => {
      /// check for success!
      console.log(data);
      this.userData.labels.push(JSON.parse( data.body));
      this._userRepsitory.buildLabelsListForDisplay();
      this.clearForm();
      this.util.displayMessageToUser("Label Added");
    })

  }
  }

  deleteLabel() {
    this._googleUserDataService.deleteMailLabel(this.labelToEdit, this.userData.userData.primaryEmail, this.clientName).subscribe ( data=> {
      this.util.displayMessageToUser("Label Deleted","danger");
      // remove the label form the array.
      let newList = [];
      for (let i=0; i < this.userData.labels.length; i++) {
         if ( this.userData.labels[i].id != this.labelToEdit.id ) {
          newList.push(this.userData.labels[i])
         }
        }
      this.userData.labels = newList;
      this._userRepsitory.buildLabelsListForDisplay();
      this.clearForm();
    })
    
  
  }

  cancelEdit() {
    this.clearForm();
  }

  clearForm() {
    this.addLabelButtonText = "Add";
    this.newLabelName = "";
    this.editMode = false;
  }

}

