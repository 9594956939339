import { Injectable } from "@angular/core";
import { WeatherAPI } from "../dataservices/weather-api.dataservice";
import { utilities } from "../utilities/utilities";
import { ocUserRepository } from "./ocUser.repository";


@Injectable()
export class WeatherRepository {
    private _currentForecastDate: string;
    private _currentForecast;
    private _dayLength;

    constructor(
        private _weatherDataService: WeatherAPI,
        private util: utilities,
        private _ocRepository: ocUserRepository

    ) { }

    getNewForecast(searchItem) {
        this._weatherDataService.getWeather(searchItem).subscribe(data => {
            this._currentForecast = data;
            this._currentForecastDate = new Date().toISOString().slice(0, 10);
            /// calculate daylength
            let sunrise24 = this.util.convertTime12to24(data.forecast.forecastday[0].astro.sunrise);
            let sunset24 = this.util.convertTime12to24(data.forecast.forecastday[0].astro.sunset);
            let startTime = new Date();
            let endTime = new Date();
            let startArr = sunrise24.split(":");
            let endArr = sunset24.split(":");
            startTime.setHours(parseInt(startArr[0]), parseInt(startArr[1]), 0, 0);
            endTime.setHours(parseInt(endArr[0]), parseInt(endArr[1]), 0, 0);
            let length = endTime.getTime() - startTime.getTime();


            this._dayLength = (this.util.convertMsToTime(length));

            this.util.debug(data);
        });

    }

    getNewForecastLondon() {
        this._weatherDataService.getWeatherForLondon().subscribe(data => {
            this._currentForecast = data;
            this._currentForecastDate = new Date().toISOString().slice(0, 10);
            /// calculate daylength
            this.calculateDaylength(data);

            this.util.debug(data);
        });

    }



    checkForecastIsCurrent() {

        var searchItem;
        var currentUser = this._ocRepository.getLoggedInUser();
        console.log(currentUser);
        // check if the current user as a preference set, if not then default to London.
        if (currentUser.postcode != "") { searchItem = currentUser.postcode }
        else if (currentUser.cityName != "") {
            { searchItem = currentUser.cityName }
        }
        else { searchItem = "London" }

        // overirde for testing;
        this.getNewForecast(searchItem);

        // call this to check if the stored forecast is for today. If it isn't 
        // then todays forecast is requested from the server.
        // this minimises the server calls.
        if (!this._currentForecast) {
            this.util.debug("forecast not loaded yet, getting new forecast");
            this.getNewForecast(searchItem);
            return;
        }
        if (this._currentForecast.forecast.forecastday[0].date != this._currentForecastDate) {
            this.util.debug("dates do not match, getting new forecast");
            this.util.debug("this._currentForecastDate " + this._currentForecastDate);
            this.util.debug("forecastday[0].date " + this._currentForecast.forecast.forecastday[0].date);
            this.getNewForecast(searchItem);
            return;
        }

    }

    getCurrentForecast() {
        //return this._currentForecast;
        return this._ocRepository.getForecast();
    }

    getDayLength() {
        return this._dayLength;
    }

    calculateDaylength(data) {
        let sunrise24 = this.util.convertTime12to24(data.forecast.forecastday[0].astro.sunrise);
        let sunset24 = this.util.convertTime12to24(data.forecast.forecastday[0].astro.sunset);
        let startTime = new Date();
        let endTime = new Date();
        let startArr = sunrise24.split(":");
        let endArr = sunset24.split(":");
        startTime.setHours(parseInt(startArr[0]), parseInt(startArr[1]), 0, 0);
        endTime.setHours(parseInt(endArr[0]), parseInt(endArr[1]), 0, 0);
        let length = endTime.getTime() - startTime.getTime();


        this._dayLength = (this.util.convertMsToTime(length));
    }


}