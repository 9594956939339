import { Injectable } from "@angular/core";
import { utilities } from "../utilities/utilities";
import { AstronomyDataService } from "../dataservices/astronomy.dataservice";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable()
export class AstronumyRepository {
    private _currentMoon;
    private _todaysDate;
    private _loaded: boolean
    private _moonPhasesloaded: boolean
    private _moonPhases;
    private _moonPhaseSVG: SafeHtml;
    private today;
    private configMoon = {
        lang  		:'en', 
        month 		:new Date().getMonth() + 1,
        year  		:new Date().getFullYear(),
        size		:52, 
        lightColor	:"rgb(200,200,210)", 
        shadeColor	:"black", 
        texturize	:true
    }

    constructor(
        private _astronomyDataService: AstronomyDataService,
        private util: utilities,
        private sanitizer: DomSanitizer
    ) {
        this._loaded = false;
        this._moonPhasesloaded = false;
        this.today = new Date().getDate();
    }

  

    getMoonData() {
        this._astronomyDataService.getMoon().subscribe( data =>
            {
                this._currentMoon = data;
                
            })
    }

    getCurrentMoon() {
        if (!this._loaded) {
            this._loaded = true;
            this.getMoonData();
        }
        //console.log(this._currentMoon.data.imageUrl);
        return this._currentMoon;
    }

    load_moon_phases() {
        this.today = new Date().getDate();
        this._astronomyDataService.load_moon_phases(this.configMoon).subscribe( data => {
            this._moonPhases = data;
            //this.svg = this.sanitizer.bypassSecurityTrustHtml(this._astroRepository.getMoonPhases().phase[this.today].svg);
            this._moonPhaseSVG = this.sanitizer.bypassSecurityTrustHtml(data.phase[this.today].svg);
            });
    }

    getMoonPhases() {
        if (!this._moonPhasesloaded) {
            this._moonPhasesloaded = true;
            this.load_moon_phases();
        }
        console.log(this._moonPhases);
        return this._moonPhases;
    }

    getMoonPhasesSVG() {
        if (!this._moonPhasesloaded) {
            this._moonPhasesloaded = true;
            this.load_moon_phases();
        }
        
        return this._moonPhaseSVG;
    }   
}