import { Pipe } from "@angular/core";

@Pipe({name: 'convertUnixToDate'})
export class convertUnixToDate {
  constructor(){}

  transform(unixTS) {
    let ldate = new Date(Number(unixTS));
    let dateFmt = ldate.toISOString();
    return dateFmt.substring(0,10);
   
  }
}