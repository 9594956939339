import { Injectable } from "@angular/core";
import { utilities } from "../utilities/utilities";
import { ocUserDataService } from "../dataservices/ocUsers.dataservice";
import { ocUser } from "../models/ocUser.model";
import { WeatherAPI } from "../dataservices/weather-api.dataservice";

@Injectable({
    providedIn: 'root'
})

export class ocUserRepository {
    loggedInUser: ocUser = new ocUser;
    private _currentForecastDate: string;
    private _currentForecast;
    private _dayLength;
    private searchItem: string;

    constructor (
        private dataService: ocUserDataService,
        private _weatherDataService: WeatherAPI,
        private util: utilities) {

    }

    getLoggedInUser(): ocUser{
        
        return this.loggedInUser;
    }

    getuser(email) {
        this.dataService.getUser(email).subscribe( data => {
            console.log(data);
            this.loggedInUser.cityName = data.cityName;
            this.loggedInUser.postcode = data.postcode;
            this.loggedInUser.admin = data.admin;
            this.loggedInUser.email = data.email;
            // now we need to get the weather!
            // check if we already have a forecast today?

            if (data.postcode != "") 
                { this.searchItem = data.postcode }
            else if ( data.cityName != "" ) {
                { this.searchItem = data.cityName }
            }
            else { this.searchItem = "London"}

           

            this._weatherDataService.getWeather(this.searchItem).subscribe( data => 
                {
                    this._currentForecast = data;
                    this._currentForecastDate = new Date().toISOString().slice(0, 10);
                    /// calculate daylength
                    this.calculateDaylength(data);
    
                    this.util.debug(data);
                });

                return this.loggedInUser;
        })
        
    }

    getForecast() {
        return this._currentForecast;
    }

    createUser(user: ocUser) {

    }

    patchUser(user: ocUser) {

    }


    calculateDaylength(data) {
        let sunrise24 = this.util.convertTime12to24( data.forecast.forecastday[0].astro.sunrise );
                let sunset24 = this.util.convertTime12to24( data.forecast.forecastday[0].astro.sunset );
                let startTime = new Date();
                let endTime = new Date();
                let startArr = sunrise24.split(":"); 
                let endArr = sunset24.split(":"); 
                startTime.setHours(parseInt( startArr[0] ), parseInt( startArr[1]),0,0);
                endTime.setHours(parseInt( endArr[0] ), parseInt( endArr[1]),0,0);
                let length = endTime.getTime() - startTime.getTime();
               
                
                this._dayLength = (this.util.convertMsToTime(length));
    }

    getDayLength() {
        return this._dayLength;
    }

}