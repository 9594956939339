import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global_constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { utilities } from '../utilities/utilities';
import { Client } from '../models/client.model';

@Injectable() export class adobeDataService {
    constructor( 
        private http: HttpClient,
        private util: utilities
    ) {
        this.util.debug('adobeDataService created');
    }

    getAdobeUsers(clientName: string, userEmail: string = "") {
    
        let url = GlobalConstants.apiURL + "/adobe/user?client=" + clientName;
        // if called with a user we get that users info, if not we get all users 
        if (userEmail != "") { url += "&userEmail=" +userEmail };
        return this.http.get<any>(url);
    }

   postAdobeActions(data) {
    let url = GlobalConstants.apiURL + "/adobe/actions";
    return this.http.post<any>(url,data, {responseType: 'json'});
   }


}