import { Injectable, Optional, SkipSelf } from "@angular/core";
import { adobeDataService } from "../dataservices/adobe.dataservice";
import { utilities } from "../utilities/utilities";

@Injectable({
    providedIn: 'root'
})
export class adobeReopsitory {

    private _adobeUsersList;
    private _adobeUsersWithLicensesList;
    private _adobeUser;
    private _userLicensesList;
    private _adobeGroupNamesMap: {
        "Creative Cloud": "CCLE Configuration",
        "Premiere Pro": "Premiere Pro Configuration",
        "Photoshop": "Photoshop Configuration",
        "Illustrator": "Illustrator Configuration"
    };


    private _adobeProducts =
        [
            {
                name: "Creative Cloud",
                productCode: "CCLE Configuration"
            },
            {
                name: "Premiere Pro",
                productCode: "Premiere Pro Configuration"
            },
            {
                name: "Photoshop",
                productCode: "Photoshop Configuration"
            },
            {
                name: "Illustrator",
                productCode: "Illustrator Configuration"
            },
            {
                name: "Acrobat Pro",
                productCode: "Acrobat Pro Configuration"
            }
        ];


    constructor(
        private _dataService: adobeDataService,
        private _util: utilities
    ) {

    }

    getAdobeLicensesList() {
        return this._adobeGroupNamesMap;
    }

    getAdobeProducts() {
        return this._adobeProducts;
    }

    getAdobeUsersFromServer(clientName: string = "", userEmail: string = "") {
        this._dataService.getAdobeUsers(clientName, userEmail).subscribe(data => {
            this._userLicensesList = []; // clear the list.
            this._adobeUsersWithLicensesList = [];
            this._util.debug("getAdobeUsersFromServer returned");
            this._util.debug(data);
            if (data.status) {
                if (userEmail != "") {
                    this._adobeUser = data;
                } else {
                    let parsedData = JSON.parse(data.response)
                    this._adobeUsersList = parsedData.users;
                    this._adobeUsersList.sort(this._util.dynamicSort("email"));
                    this._util.debug(this._adobeUsersList);
                }

                // now we need to create the license map:
                let totalUsers = this._adobeUsersList.length;
                let loopUser;
                for (let i = 0; i < totalUsers; i++) {

                    loopUser = this._adobeUsersList[i];

                    // for each user we will log the list of licenses they have to make our lives easier later!
                    if ("groups" in loopUser) {

                        this._userLicensesList[loopUser.email] = [];
                        loopUser.groups.forEach(groupName => {
                            if (groupName != "CCMTeamDelegates") {
                                this._userLicensesList[loopUser.email].push(groupName);
                                this._adobeUsersWithLicensesList.push(loopUser);
                            };
                        });
                    }
                }
                console.log("users license map");
                console.log(this._userLicensesList);
            } else {
                // we got an error 
                this._util.displayMessageToUser("Please let support know it went wrong, and tell them what you tried to do", "error")
            }
        })

    }

    getAdobeUsers() {
        return this._adobeUsersList;
    }

    getAdobeUsersWithLicensesList() {
        return this._adobeUsersWithLicensesList;
    }

    getAdobeUser() {
        return this._adobeUser;
    }


    getAdobeUsersLicences(userEmail) {
        // this returns the licenses that have been assigned to the user
        this._util.debug("getAdobeUsersLicences(" + userEmail + ")")

        let groupFreindlyName = "";
        let userLicencesArray = [];
        let productCode = "";

        let noOfLicenses = this._userLicensesList[userEmail].length;
        console.log(noOfLicenses);
        console.log(this._userLicensesList[userEmail]);
        for (let i = 0; i < noOfLicenses; i++) {

            productCode = this._userLicensesList[userEmail][i];

            for (let p = 0; p < this._adobeProducts.length; p++) {
                if (this._adobeProducts[p].productCode == productCode) {
                    userLicencesArray.push(this._adobeProducts[p].name);
                }
            }
        }
        console.log(userLicencesArray);
        return userLicencesArray;

    }

    getAdobeProductNameFromCode(productCode) {
        for (let p = 0; p < this._adobeProducts.length; p++) {
            if (this._adobeProducts[p].productCode == productCode) {
                return this._adobeProducts[p].name;
            }
        }
        return "";
    }

    getAdobeProductCodeFromName(productName) {
        for (let p = 0; p < this._adobeProducts.length; p++) {
            if (this._adobeProducts[p].name == productName) {
                return this._adobeProducts[p].productCode;
            }
        }
        return "";
    }

    createAdobeID(clientName: string, userEmail: string, firstName, lastName) {
        if (clientName == "") { console.error("no client name"); return; }
        if (userEmail == "") { console.error("no userEmail"); return; }


        let actionObject = {
            actionName: "addAdobeID",
            params: {
                email: userEmail,
                country: "UK",
                firstname: firstName,
                lastname: lastName,
                option: "ignoreIfAlreadyExists"
            }
        }

        let sendToAdobeObject = {
            client: clientName,
            userEmail: userEmail,
            actions: [
                actionObject
            ]
        };
        console.log(sendToAdobeObject);
        this._dataService.postAdobeActions(sendToAdobeObject).subscribe( data => {
            console.log(data);
            return data;
        })
    }


    postAdobeAction(clientName: string, userEmail: string, license: string, actionType: string, userObj = {}) {
        if (clientName == "") { console.error("no client name"); return; }
        if (userEmail == "") { console.error("no userEmail"); return; }
        if (license == "") { console.error("no license name"); return; }
        if (actionType == "") { console.error("no actionType"); return; }
        /*{
      "actionName": "remove",
      "params": {
        "group": [
          "CCLE Configuration"
        ]
      }
    }*/

        let actionObject = {
            actionName: actionType,
            params: {
                 group: [
                 ]
            }
        }

        if (actionType == "remove") {
            actionObject.params = {
                group: [
                    this.getAdobeProductCodeFromName(license)
                ]
            }
        }

        if (actionType == "add") {
            actionObject.params = {
                group: [
                    license
                ]
            }
        }

        let sendToAdobeObject = {
            client: clientName,
            userEmail: userEmail,
            actions: [
                actionObject
            ]
        };
        console.log(sendToAdobeObject);

        this._dataService.postAdobeActions(sendToAdobeObject).subscribe( data => {
            console.log(data);
            if (data.status) {
                let msg = this.getAdobeProductNameFromCode(actionObject.params.group[0]);

            }
            return data;
            
        })
    }


    assignLicenseToUser(clientName: string, userEmail: string, license: string) {
        if (clientName == "") { console.error("no client name") }
        if (userEmail == "") { console.error("no userEmail name") }
        if (license == "") { console.error("no license name") }

        return;
    }

}