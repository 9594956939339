<div class="spacer"></div>

<div class="welcome">Welcome Home<ng-container *ngIf="clientName == null">, please select a client.</ng-container></div>

<!-- <app-locations></app-locations> -->
<app-weather></app-weather>

<div class="spacer"></div>
<div class="spacer"></div>

<!-- <app-good-news></app-good-news> -->

<div class="spacer"></div>






