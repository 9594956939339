export class Client{
    constructor (
    public client?: string,
    public organisations?: clientOrganisation[],
    public domains?: string[],
    public primary_domain?: string,
    public usejc?: boolean,
    public manageAdobe?: boolean,
    public domainsToGlpiEntities?: any,
    public jcAllStaffGroup?: string
    ){}
}

export class clientOrganisation {
    name: string;
    signature: string;
    locations: clientOrgLocation[];
}

export class clientOrgLocation {
    formatted?: string;
    sourceIsStructured: boolean;
    telephone?: string;
    website?: string;
    streetAddress?: string;
    extendedAddress?: string;
    type?: string;          // Acceptable values: custom, home, other, work.  If set to custom, customType must also be set.
    customType?: string;
    locality?: string;      // The town or city of the address.
    region?: string;        // The abbreviated province or state.
    postalCode?: string;
    country?: string;
    name?: string;
    
}