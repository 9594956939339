import { Component, OnInit, Input, Output } from '@angular/core';
//import Modal from "bootstrap/js/src/modal";
import { EventEmitter } from '@angular/core';
import { utilities } from 'src/app/utilities/utilities';
import { GoogleUserDataService } from 'src/app/dataservices/google-user-dataservice';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { ClientRepository } from 'src/app/Repositories/client.repository';

@Component({
  selector: 'app-suspend-acitvate-user',
  templateUrl: './suspend-acitvate-user.component.html',
  styleUrls: ['./suspend-acitvate-user.component.css']
})
export class SuspendAcitvateUserComponent implements OnInit {
  @Input() suspendMode: boolean;
  @Input() userFullName: string;
  @Input() userEmail: string;
  // @Input()   public set display(_display: boolean) {
  //   if (_display) {
  //     this._display = true;
  //     this.displayMe();
  //   }
  // }  
  //dialog: Modal;
  _display: boolean;
  modeText: string;

  @Input() displayModal: boolean;
  @Output() resetDisplay: EventEmitter<any> = new EventEmitter();


  
  
  constructor(private util: utilities,
    private _googleDataService: GoogleUserDataService,
    private _userRepo: UserRepository,
    private _clientRepo: ClientRepository ) { }

  ngOnInit(): void {
    //this.dialog = new Modal(document.getElementById("thisDialog"));
  }

  ngOnChanges() {
    if (this.suspendMode) {
      this.modeText = "SUSPEND";
    } else {
      this.modeText = "ACTIVATE";
        }
  }

  closeDisplay() {
    // tells the parent to close this display ... very clever ;)
    this.resetDisplay.emit(false);
  }
  

  

  performAction() {
    console.log(this.modeText + " " + this.userFullName);
    let userInfoObj = this._userRepo.findUserInDirectories(this.userEmail);
    console.log(userInfoObj);

    let patchMode = "SUSPENDED";
    if (this.modeText == "ACTIVATE") { patchMode = "ACTIVATED"}

    let userData = {
      email: this.userEmail,
      jcid: userInfoObj.id,
      state: patchMode
    }

    this._googleDataService.patchUserStatus(
      userInfoObj.client,
      userData,
      userInfoObj.existsInJC
    ).subscribe( data => 
      {
        //console.log(data.body);
   
        this.resetDisplay.emit(false); 
   
      })

    
  }

}
