import { Component, OnInit, Input } from '@angular/core';
import {MenuItem} from 'primeng/api';  
import Modal from "bootstrap/js/src/modal"
import { Router } from '@angular/router';
import { UserRepository } from 'src/app/Repositories/user.repository';
import * as FileSaver from 'file-saver';
import { utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-groups-table',
  templateUrl: './groups-table.component.html',
  styleUrls: ['./groups-table.component.css']
})
export class GroupsTableComponent implements OnInit {
  @Input() groupsList: any | undefined;
  @Input() clientName: string;
  @Input() selectedDirectory: string;
  exportColumns: any[];
 
  // dialog dispaly
  displayModal;
  showModalDialog(groupEmail) {
    
    this.userRepository.getGoogleGroupMembersFromServer(this.clientName, groupEmail);
    this.displayModal = true;
  }

  get groupMembers() {
    return this.userRepository.getGoogleGroupMembers();
  }

  cols: any[];
  constructor(
    private userRepository: UserRepository,
    private util: utilities,
    private router: Router
  ) { }

  ngOnInit(): void {
       // columns for file export.
       this.cols = [
        { field: 'name', header: 'Name' },
        { field: 'email', header: 'Email' },
        { field: 'description', header: 'Description' },
        { field: 'aliases', header: 'Aliases' }
      ];
      this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l');
            (doc as any).autoTable(this.exportColumns, this.groupsList);
          
            doc.save('products.pdf');
        })
    }) 
  }

  editGroup(groupID) {
    // groupEmail
    if (this.selectedDirectory == "Google") {
      this.router.navigateByUrl("/groupsList/googlegroupedit/" + groupID)
    } else {
      this.router.navigateByUrl("/groupsList/jumpcloudgroupedit/" + groupID)
    }
    }
}
