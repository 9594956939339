import { userMailFilter } from "../models/user.mail.filter.model";

export class filtersStaticData {
    private _filters: userMailFilter[] = [
        {
            id: "ANe1BmiaBe0d_vdzMQHF4-CEXE4NkmnIkL8czw",
            criteria: {
                from: "root@ip-10-0-3-83.eu-west-1.compute.internal"
            },
            action: { 
                removeLabelIds: ["SPAM"] 
            }
        },
        {
            id: "ANe1Bmjf67ty2-4zsBgql1r5DMcCboi_nLnuE9HmtOL5A9Tq2cJF_J93dcUq_JwEZGHahgKhdw",
            criteria: {
                query: "Monthly Disk Health Report",
                negatedQuery: "Normal"
            },
            action: {
                forward: "help@no-it.co.uk"
            }
        },
        {
            id: "ANe1Bmg6JEOxYArBGO2T1Y3KVMHswIXmC1zJ63Vo31N4Zb4HXpWFrkhGUp-Kg11ISPs9JD5NiQ",
            criteria: {
                from: "expiry@letsencrypt.org",
                query: "Domain"
            },
            action: {
                addLabelIds: ["IMPORTANT"],
                forward: "help@no-it.co.uk"
            }
        }
    ]

    constructor() {
    }


    getFilters() {
        return this._filters;
    }
}