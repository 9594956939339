<div class="spacer"></div>
<h2 class="blueText">Signature Templates</h2>
<div class="container">
  
      

    <div class="row form-group">
        <label for="userOrgName" class="col-sm-2 col-form-label  form-control-sm">Organisation / Department: </label>
        <div class="col-sm-3">
            <select id="userOrgName" class="form-select form-select-sm" [(ngModel)]="selectedOrganisation"
                (change)="getSignature(($event).target)" name="userOrgName" aria-label="userOrgName">
                <!-- Need to put org names from client object here -->
                <option disabled selected value="">Select Organisation</option>
                <option *ngFor="let org of currentClient.organisations | keyvalue" value='{{org.key}}'>{{org.key}}
                </option>
            </select>
        </div>
        <div class="col-sm-7"></div>
    </div>

    <div class="spacer"></div>

    <div class="row form-group">
        <label for="templateText" class="col-sm-2 col-form-label  form-control-sm">Template </label>
        <div class="col-sm-10">
            <textarea [(ngModel)]="templateText" name="templateText" mdInput placeholder="HTML" rows="10" class="form-control "></textarea>
        </div>
    </div>

    <!-- <div class="spacer"></div> -->

    <div class="row form-group">
        <div class="col-sm-2 col-form-label  form-control-sm">Signature Preview</div>
        <div class=" col-sm-10">
        <div [innerHTML]=" templateText| safeHtml" class="sig-box"></div>
    </div>
    </div>

    <div class="row">
        <div class="col-sm-1">
            <button class="btn btn-sm btn-success" (click)="saveSignature()">Save</button>
        </div>
    </div>
</div>