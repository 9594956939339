import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs';
import { catchError } from 'rxjs';
import { utilities } from '../utilities/utilities';

@Injectable({
    providedIn: 'root'
  })
  
  export class GoodNewsDataService {
      //private url: string = "https://www.goodnewsnetwork.org/category/news/world/feed/";
      private url: string = "category/news/world/feed/";

    constructor( 
      private http: HttpClient,
      private util: utilities
  ) {
      this.util.debug('GoodNewsDataService created');
  }


    
  getLatestHeadlines():Observable<any>  {
    let HTTPOptions = {
      headers: new HttpHeaders({
         'Accept':'application/text',
         'Access-Control-Allow-Origin': '*'
      }),
      responseType: 'text' as 'json'
   }

  //  return this.http.jsonp(this.url, 'callback')
  // //  .pipe(
  // //    map(result => this.jsonpResultToHeroes(result)),
  // //    catchError(this.handleError('searchHeroes', []))
  // //  )
  // ;

    return this.http.get<any>(this.url, HTTPOptions);
  }

    
  }


