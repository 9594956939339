<div class="spacer"></div>

<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
        <button class="nav-link active" id="info-tab" data-bs-toggle="tab" data-bs-target="#info" type="button"
            role="tab" aria-controls="info" aria-selected="true">Info</button>
    </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link" id="groups-tab" data-bs-toggle="tab" data-bs-target="#groups" type="button" role="tab"
            aria-controls="groups" aria-selected="false">Groups</button>
    </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link" id="signatures-tab" data-bs-toggle="tab" data-bs-target="#signatures" type="button"
            role="tab" aria-controls="signatures" aria-selected="false">Signatures</button>
    </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link" id="aliases-tab" data-bs-toggle="tab" data-bs-target="#aliases" type="button" role="tab"
            aria-controls="aliases" aria-selected="false">Aliases</button>
    </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link" id="delegation-tab" data-bs-toggle="tab" data-bs-target="#delegation" type="button"
            role="tab" aria-controls="delegation" aria-selected="false">Delegation</button>
    </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link" id="vacation-tab" data-bs-toggle="tab" data-bs-target="#vacation" type="button"
            role="tab" aria-controls="vacation" aria-selected="false">Vacation</button>
    </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link" id="forwarding-tab" data-bs-toggle="tab" data-bs-target="#forwarding" type="button"
            role="tab" aria-controls="forwarding" aria-selected="false">Forwarding</button>
    </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link" id="labels-tab" data-bs-toggle="tab" data-bs-target="#labels" type="button"
            role="tab" aria-controls="labels" aria-selected="false">Labels</button>
    </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link" id="filters-tab" data-bs-toggle="tab" data-bs-target="#filters" type="button"
            role="tab" aria-controls="filters" aria-selected="false">Filters</button>
    </li>
</ul>
<div class="tab-content" id="myTabContent">
    <div class="tab-pane  show active" id="info" role="tabpanel" aria-labelledby="info-tab">
        <app-user-page-user [userData]="userData"></app-user-page-user>
    </div>   
    
    <div class="tab-pane " id="groups" role="tabpanel" aria-labelledby="groups-tab">
        <app-groups-tab [userData]="userData" ></app-groups-tab>
    </div>
   
    <div class="tab-pane " id="signatures" role="tabpanel" aria-labelledby="signatures-tab">
        <app-signature-tab  [userData]="userData"></app-signature-tab>
    </div>
    <div class="tab-pane " id="aliases" role="tabpanel" aria-labelledby="aliases-tab">
       
        <aliases-tab  [userData]="userData"></aliases-tab>
        
    </div>
    
    <div class="tab-pane " id="delegation" role="tabpanel" aria-labelledby="delegation-tab">
        <app-delegates-tab [userData]="userData"></app-delegates-tab>
    </div>
    
    <div class="tab-pane " id="vacation" role="tabpanel" aria-labelledby="vacation-tab">
        <app-vacation-tab [userData]="userData"></app-vacation-tab>
    </div>
    <div class="tab-pane " id="forwarding" role="tabpanel" aria-labelledby="forwarding-tab">
        <app-forwarding-tab [userData]="userData" [clientName]="clientName"></app-forwarding-tab>
    </div>
    <div class="tab-pane " id="labels" role="tabpanel" aria-labelledby="labels-tab">
        <app-labels [userData]="userData" [clientName]="clientName"></app-labels>
    </div>
    <div class="tab-pane " id="filters" role="tabpanel" aria-labelledby="filters-tab">
        <app-filters [userData]="userData" [clientName]="clientName"></app-filters>
    </div>

</div>


 


<br>
<br>
<br>
<br>