import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { fileCSVRecord } from 'src/app/models/filecsv';
import { googleDriveRepository } from 'src/app/Repositories/googleDrive.repository';


@Component({
  selector: 'app-bulk-actions',
  templateUrl: './bulk-actions.component.html',
  styleUrls: ['./bulk-actions.component.css']
})
export class BulkActionsComponent implements OnInit {
  selectedAction = 0;
  permissionEmailAddress = "";
  newAddress = "";
  newTelephone = "";
  newWebsite = "";
  newSignature = "";
  selectedTemplatesArray = [];
  fileIsValid = false;

  constructor(
    private _clientRepository: ClientRepository,
    private _driveRepository: googleDriveRepository
  ) {
   }

  ngOnInit(): void {
    this._driveRepository.clearBulkArray();
  }

  get currentClient() {
    return this._clientRepository.getCurrentClient();
  }

  //public records: any[] = [];
  get records() {
    return this._driveRepository.getBulkRecords();
  }
  
  
  @ViewChild('csvReader') csvReader: any;
  uploadListener($event: any): void {
    let text = [];
    let files = $event.srcElement.files;
    if (this.isValidCSVFile(files[0])) {
      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);
      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        let headersRow = this.getHeaderArray(csvRecordsArray);
       //this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
       this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };
      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };

    } else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];
    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord: fileCSVRecord = new fileCSVRecord();
        csvRecord.documentID = curruntRecord[1].trim();
        csvRecord.title = curruntRecord[2].trim();
        csvRecord.processed = false;
   
        //csvArr.push(csvRecord);
        this._driveRepository.addBulkRecord(csvRecord);
      }
    }
    return csvArr;
  }
  isValidCSVFile(file: any) {
    if (file.name.endsWith(".csv")) {
      this.fileIsValid = true;
    }
    return this.fileIsValid;
  }
  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }
  fileReset() {
    this.csvReader.nativeElement.value = "";
    this._driveRepository.clearBulkArray();
  }

  actionSelected(target) {
    console.log(target.value);

    // here we need to select the options to display..
    switch (target.value) {
      case "signature": 
            this.selectedAction = 1;
            break;
      case "removePermissions": 
            this.selectedAction = 2;
            break;
      default: 
            this.selectedAction = 0;
      
    }
  }

  // deleteAlias(i) {
  //   this.util.debug("delete alias"+ this.userData.userData.aliases[i]);
  //   // go off and delete it,... when we get back we remove it:
  //   this._userDataService.deleteAlias( 
  //     this.userData.userData.primaryEmail,
  //     this.userData.userData.aliases[i],
  //     this._clientRepository.getCurrentClientName()
  //   ).subscribe ( data => { 
  //     // delete it from the model.
  //     this.util.displayMessageToUser("Alias " + this.userData.userData.aliases[i] + " has been deleted", "danger");
  //     this.userData.userData.aliases.splice(i,1);
  //   })
  // }



process() {
    // cycle through the records and retien te 
    let totalRecords = this.records.length;
     for (let i=0; i<totalRecords; i++) {
      this._driveRepository.removeFilePermission(this._clientRepository.getCurrentClientName(), this.records[i],this.permissionEmailAddress);
       
    }

    const pos = this.records.map(e => e.documentID).indexOf('1VVT9c9N4vdbsf8OuBIUVxOCIWl6tIc6c');
    console.log("position found at: " + pos);
    this.records[pos].processed = true;
}

}
